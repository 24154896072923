import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import EditorUtils from 'draft-js-plugins-utils';
import LabelIcon from '@material-ui/icons/Label';

export default class LinkButton extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    store: PropTypes.object.isRequired,
    ownTheme: PropTypes.object.isRequired,
    onRemoveLinkAtSelection: PropTypes.func.isRequired,
    linkForm: PropTypes.element,
  };

  onMouseDown = (event:any) => {
    event.preventDefault();
  };

  onAddLinkClick = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    //@ts-ignore
    const { ownTheme, placeholder, onOverrideContent, linkForm, lang } = this.props;
    const CustomAddLinkForm = linkForm;
    const content = (props:any) => (
      <CustomAddLinkForm
        {...props}
        placeholder={placeholder}
        theme={ownTheme}
        lang={lang}
      />
    );
    onOverrideContent(content);
  };

  render() {
    //@ts-ignore
    const { theme, onRemoveLinkAtSelection } = this.props;
    const hasLinkSelected = EditorUtils.hasEntity(
    //@ts-ignore
      this.props.store.getEditorState(),
      'LINK'
    );
    const className = hasLinkSelected
      ? clsx(theme.button, theme.active)
      : theme.button;

    return (
      <div className={theme.buttonWrapper} onMouseDown={this.onMouseDown}>
        <button
          className={className}
          onClick={
            hasLinkSelected ? onRemoveLinkAtSelection : this.onAddLinkClick
          }
          type="button"
        >
          <LabelIcon />
        </button>
      </div>
    );
  }
}
