import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AlarmAdd from '@material-ui/icons/AlarmAdd';


const styles = {
  menuItem:{
    color: "#4b8bcc",
    fontSize: "95%",
    margin: "0px",
    paddingTop: 8,
    paddingBottom: 8,
  },
  menuHeader:{
    color: "rgb(149, 165, 177)",
    fontSize: "90%",
    margin: 0,
    padding: "10px 17px 4px",
    fontWeight: 400,
  },
  menuSection:{
    borderBottom: "1px solid #eaeaea",
  },
  emptyIcon:{
    display: "inline-block",
    width:  24,
  }
}

const CustomMenu = (props:any) => {
  const classes = props.classes;
  const resources = props.resources;
  const onMenuClick = props.onMenuClick;
  const logout = props.logout;


  return(
    <div>
      <section className={ classes.menuSection }>
        {/* <h6 className={ classes.menuHeader }>قسم المحتوى</h6> */}
        <MenuItemLink
          key="1"
          to=''
          primaryText="قسم المحتوى"
          className={ classes.menuHeader }
          leftIcon = {<><span className={classes.emptyIcon}></span></>}
        />

        {resources.filter((el:any) => el.options.section === 'Content').map((resource:any) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options && resource.options.label || resource.name}
            onClick={onMenuClick}
            className={ classes.menuItem }
            leftIcon ={resource.options.Icon}
          />
        ))}
      </section>


      <section className={ classes.menuSection }>
        {/* <h6 className={ classes.menuHeader }>قسم الإحصائيات</h6> */}
        <MenuItemLink
          key="2"
          to=''
          primaryText="قسم الإحصائيات"
          className={ classes.menuHeader }
          leftIcon = {<><span className={classes.emptyIcon}></span></>}
        />
        {resources.filter((el:any) => el.options.section === 'Analytics').map((resource:any) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options && resource.options.label || resource.name}
            onClick={onMenuClick}
            className={ classes.menuItem }
            leftIcon ={resource.options.Icon}
          />
        ))}
      </section>


      <section className={ classes.menuSection }>
        {/* <h6 className={ classes.menuHeader }>قسم التواصل الإجتماعي</h6> */}
        <MenuItemLink
          key="3"
          to=''
          primaryText="قسم التواصل الإجتماعي"
          className={ classes.menuHeader }
          leftIcon = {<><span className={classes.emptyIcon}></span></>}
        />
        {resources.filter((el:any) => el.options.section === 'Social').map((resource:any) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options && resource.options.label || resource.name}
            onClick={onMenuClick}
            className={ classes.menuItem }
            leftIcon ={resource.options.Icon}
          />
          ))}
      </section>

      <section className={ classes.menuSection }>
        {/* <h6 className={ classes.menuHeader }>قسم التواصل الإجتماعي</h6> */}
        <MenuItemLink
          key="4"
          to=''
          primaryText="Scheduler"
          className={ classes.menuHeader }
          leftIcon = {<><span className={classes.emptyIcon}></span></>}
        />
        {resources.filter((el:any) => el.options.section === 'Scheduler').map((resource:any) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options && resource.options.label || resource.name}
            onClick={onMenuClick}
            className={ classes.menuItem }
            leftIcon ={resource.options.Icon}
          />
          ))}
      </section>

      <Responsive
        small={logout}
        medium={null}
      />
    </div>
)};

const mapStateToProps = (state:any) => ({
    resources: getResources(state),
});

const StyledCustomMenu = withStyles(styles)(CustomMenu)

export default withRouter(connect(mapStateToProps)(StyledCustomMenu));