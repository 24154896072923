import React from 'react';
import {
    Edit, SimpleForm, TextInput, DisabledInput,
    required, SelectInput, BooleanInput, Button
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import JobModel from '../../../models/Job';
import { RunJobBotton } from '../../../components/SchedulerActionButtons';



const styles = {
    inputLabel: {
        width: '50%',
        '& label': {
            transformOrigin: "top right",
            right: 0,
            fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
            fontSize: ".74rem",
        },
        '& input': {
            fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
            fontSize: ".84rem",
        },
        '& textarea': {
            fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
            fontSize: ".9rem",
        },
    },
    isLTR: {
        direction: 'ltr' as 'ltr',
    }
}


const JobEdit = (props: any) => {
    const classes = props.classes;

    return (
        <Edit {...props}>
            <SimpleForm handleSubmit={(Submit: any) => {
                if (props.dirtyFields && props.form) {
                    const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, n: any) => {
                        let key = n
                        let value = props.form["record-form"].values[n]
                        return { ...p, [key]: value }
                    }, {})
                    return Submit(dirtyValues)
                }
                alert('ok')
                return false
            }}>
                <BooleanInput source="enabled" />
                <RunJobBotton />
                <DisabledInput source="id" className={classes.inputLabel} validate={required()} />
                <DisabledInput source="project.project_name" className={classNames(classes.inputLabel, classes.isLTR)} validate={required()} />
                <DisabledInput source="spider.spider_name" className={classNames(classes.inputLabel, classes.isLTR)} />
                <DisabledInput source="run_type" className={classNames(classes.inputLabel, classes.isLTR)} />
                <TextInput source="spider_arguments" className={classNames(classes.inputLabel, classes.isLTR)} />
                <SelectInput
                    source="priority"
                    choices={Object.keys(JobModel.PRIORITY).map((key) => ({ id: JobModel.PRIORITY[key], name: key }))}
                />
                <SelectInput source="trigger_type" choices={[
                    { id: 'cron', name: 'Cron' },
                    // { id: 'interval', name: 'Interval' },
                ]} />
                <TextInput source="cron_seconds" className={classNames(classes.inputLabel, classes.isLTR)} />
                <TextInput source="cron_minutes" className={classNames(classes.inputLabel, classes.isLTR)} />
                <TextInput source="cron_hour" className={classNames(classes.inputLabel, classes.isLTR)} />
                <TextInput source="cron_day_of_week" className={classNames(classes.inputLabel, classes.isLTR)} />
                <TextInput source="cron_day_of_month" className={classNames(classes.inputLabel, classes.isLTR)} />
                <TextInput source="cron_month" className={classNames(classes.inputLabel, classes.isLTR)} />
                <TextInput source="cron_exp" label={"Cron Expressions (s m h dom mon dow)"} className={classNames(classes.inputLabel, classes.isLTR)} />

            </SimpleForm>
        </Edit>
    )
};

const JobEditForm = withStyles(styles)(JobEdit)


export default connect(
    (state: any, props: any) => {
        if (state && state.form && state.form['record-form'] && state.form['record-form'].anyTouched) {
            return { ...state, dirtyFields: state.form['record-form'].fields }
        }
        return state
    }, {})(JobEditForm)