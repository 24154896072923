import React, { Component } from 'react';
import { EditorState, Modifier, ContentBlock, SelectionState } from 'draft-js';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import IconButton from "@material-ui/core/IconButton";

export const SPELLCHECKERROR = "SPELLCHECKERROR"
export const SPELLCHECKERROR_STYLE = {
  SPELLCHECKERROR: { backgroundColor: "#fee481" }
}

function fetchSpellCheckAPI(text:string) : Promise<object>{
  const url = "https://languagetool.org/api/v2/check"
  const data : Record<string, any> = {
    text,
    language: 'ar',
    enableOnly: true,
    enabledCategories: 'TYPOS'
  };
  return fetch(url, {
    method: 'POST',
    body: new URLSearchParams(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then(response => response.json());
};

type ButtonProps  = {
  getEditorState: () => EditorState;
  setEditorState: (arg: EditorState) => {};
  onSpellingError: (hasError: boolean) => {};
}

class InlineStyleButton extends Component<ButtonProps> {
  preventBubblingUp = (event:any) => {
    event.preventDefault();
  };
  removeSpellCheckInlineStyle = () => {
    const { getEditorState, setEditorState } = this.props
    const editorState = getEditorState();
    const currentContent = editorState.getCurrentContent();
    currentContent.getBlocksAsArray()
    .forEach((b:ContentBlock) => {
      const blockKey = b.getKey()
      const selectedText = b.getText()
      const selectionState = SelectionState.createEmpty(blockKey)
      const updatedSelection = selectionState.merge({
        anchorKey: blockKey,
        focusOffset: selectedText.length,
        anchorOffset: 0,
      });
      const currentContent = getEditorState().getCurrentContent();
      //@ts-ignore
      const modifiedConent = Modifier.removeInlineStyle(currentContent, updatedSelection, SPELLCHECKERROR)
      const editorStateWithModifiedContent = EditorState.createWithContent(modifiedConent)
      setEditorState(editorStateWithModifiedContent)
    })
  }
  spellCheck = () => {
    let hasErrors = false
    const { getEditorState, setEditorState } = this.props
    const editorState = getEditorState();
    const currentContent = editorState.getCurrentContent();
    setTimeout(() => {
      this.removeSpellCheckInlineStyle()
    })
    
    currentContent.getBlocksAsArray()
      .forEach((b:ContentBlock) => {
        const blockKey = b.getKey()
        const selectedText = b.getText()
        if(selectedText.trim().length === 0) {
          return
        }
        setTimeout(() => {
          fetchSpellCheckAPI(selectedText)
          .then((response:any) => {
            if(response.matches.length > 0){
              this.props.onSpellingError(true)
            }
            response.matches
              .filter((m:any, i:number) => m.rule.id === "HUNSPELL_RULE_AR")
              .map((m:any) => {
                const selectionState = SelectionState.createEmpty(blockKey)
                const updatedSelection = selectionState.merge({
                  anchorKey: blockKey,
                  focusOffset: m.offset + m.length,
                  anchorOffset: m.offset,
                });
                const currentContent = getEditorState().getCurrentContent();
                //@ts-ignore
                const modifiedConent = Modifier.applyInlineStyle(currentContent, updatedSelection, SPELLCHECKERROR)
                const editorStateWithModifiedContent = EditorState.createWithContent(modifiedConent)
                setEditorState(editorStateWithModifiedContent)
              })
          }).then(() => {

          })
        })
      })
  }
  render() {
    return (
      <div
        onMouseDown={this.preventBubblingUp}
      >
          <IconButton color="primary" component="span" onClick={this.spellCheck}>
            <SpellcheckIcon />
          </IconButton>
      </div>
    );
  }
};

export default InlineStyleButton
