import React, { useEffect, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';



const styles = {
  lineupsList: {
    width: "100%",
    '& p': {
      display: "none",
    },
    '& li': {
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  selectInput: {
    width: "90%"
  },
  confirmBtnContainer: {
    textAlign: "center" as "center",
    padding: "24px 0px"
  },
  confirmButton: {
    backgroundColor: "#4caf50",
    color: "#fff",
    width: "40%",
    fontWeight: 777, 
    '&:hover': {
      backgroundColor: "#4caf50",
      color: "#fff",
    }
  }
}

const MatchLineupsList = (props: any) => {
  const { classes, input = {} } = props;
  const [squad, setSquad] = useState([])

  const positionsSide = [
    { id: 'Right', name: 'اليمين' },
    { id: 'Centre/Right', name: 'الوسط / اليمين' },
    { id: 'Centre', name: 'الوسط' },
    { id: 'Left/Centre', name: 'يسار / وسط' },
    { id: 'Left', name: 'يسار' },
    { id: undefined, name: 'Undefined' },
  ]
  const positions = [
    { id: 'Goalkeeper', name: 'حارس مرمى' },
    { id: 'Defender', name: 'مدافع' },
    { id: 'Wing Back', name: 'Wing Back' },
    { id: 'Defensive Midfielder', name: 'وسط دفاعي' },
    { id: 'Midfielder', name: 'لاعب وسط' },
    { id: 'Attacking Midfielder', name: 'مهاجم خط الوسط' },
    { id: 'Striker', name: 'مهاجم' },
    { id: 'Substitute', name: 'لاعب بديل' },
    { id: undefined, name: 'Undefined' },
  ]

  const getName = (p: any) => (
    p.match_name ? p.match_name : `${p.first_name} ${p.last_name}`
  )

  useEffect(() => {
    if (input.value) {
      setSquad(input.value)
    }
  }, [input.value])

  const onChange = (player: any, key: any, value: any) => {
    let altSquad: any = squad.map((p: any) => {
      if (p.player_id != player.player_id) {
        return p
      }
      return { ...p, [key]: value }
    })
    setSquad(altSquad)
  }
  const invokeFieldEvents = () => {
    input.onFocus(squad)
    input.onChange(squad)
    input.onBlur(squad)
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>اللاعب</TableCell>
            <TableCell>مشارك</TableCell>
            <TableCell>رقم القميص</TableCell>
            <TableCell>المركز</TableCell>
            <TableCell>المركز الجانبي</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            squad.map((p: any) => (
              <TableRow>
                <TableCell>
                  <img src={`https://images.elbotola.com/stats/players/${p.player_id}.png`} width="50" />
                </TableCell>
                <TableCell>
                  {getName(p)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={p.participant}
                    onChange={(e) => { onChange(p, 'participant', Boolean(e.target.checked)) }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    className={classes.textField}
                    value={p.shirt_number}
                    onChange={(e) => { onChange(p, 'shirt_number', e.target.value == "" ? null : e.target.value) }}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={p.position}
                    name="position"
                    className={classes.selectInput}
                    onChange={(e) => { onChange(p, 'position', e.target.value) }}
                  >
                    {
                      positions.map(p => <MenuItem value={p.id}>{p.name}</MenuItem>)
                    }
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={p.position_side}
                    name="positionSide"
                    className={classes.selectInput}
                    onChange={(e) => { onChange(p, 'position_side', e.target.value) }}
                  >
                    {
                      positionsSide.map(p => <MenuItem value={p.id}>{p.name}</MenuItem>)
                    }
                  </Select>
                </TableCell>
              </TableRow>
            ))
          }

        </TableBody>
      </Table>
      <div className={classes.confirmBtnContainer}>
      <Button variant="contained" onClick={invokeFieldEvents} className={classes.confirmButton}>
        Confirm
      </Button>
      </div>
    </Paper>
  );
};



function MatchLineupsInput(props: any) {
  const { index, squad = [] } = props

  const format = (lineups: any) => {
    let lineups_by_id: any = {}
    if (lineups) {
      lineups_by_id = lineups.reduce((cum: any, curr: any) => {
        cum[curr['player_id']] = curr
        return cum
      }, {})
    }
    const formattedSquad = squad.filter((p: any) => p.type != "coach").map((p: any) => {
      if (lineups_by_id[p.id]) {
        let participant = lineups_by_id[p.id].participant === false ? false : true
        return { ...lineups_by_id[p.id], participant }
      }
      return {
        player_id: p.id,
        first_name: p.first_name,
        last_name: p.last_name,
        match_name: p.match_name,

        first_name_fr: p.first_name_fr,
        last_name_fr: p.last_name_fr,
        match_name_fr: p.match_name_fr,

        first_name_en: p.first_name_en,
        last_name_en: p.last_name_en,
        match_name_en: p.match_name_en,

        participant: false
      }
    }
    ).sort((x: any, y: any) => y.participant - x.participant)
      .sort((x: any, y: any) => (x.position > y.position) ? 1 : ((y.position > x.position) ? -1 : 0))

    return formattedSquad
  }

  return (
    <Field
      name={`line_up[${index}].player`}
      component={(componentProps: any) => (<MatchLineupsList  {...componentProps} />)}
      {...props}
      format={format}
    />
  )
};

export default withStyles(styles)(MatchLineupsInput)
