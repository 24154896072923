import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

type ImageAddProps = {
  editorState: any;
  onChange: any;
  modifier: any;
};
export default class ImageAdd extends Component<ImageAddProps> {
  // Start the popover closed
  state = {
    url: "",
    open: false
  };
  private preventNextClose = true;

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener("click", this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true;
  };

  openPopover = () => {
    if (!this.state.open) {
      this.preventNextClose = true;
      this.setState({
        open: true
      });
    }
  };

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false
      });
    }

    this.preventNextClose = false;
  };

  addImage = () => {
    const { editorState, onChange } = this.props;
    onChange(this.props.modifier(editorState, this.state.url));
  };

  changeUrl = (evt: any) => {
    this.setState({ url: evt.target.value });
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let target = e.target as HTMLInputElement;
    if (target.files) {
      const file = target.files[0];
      if (file && file.type.indexOf("image/") === 0) {
        const formData = new FormData();
        formData.append("image", file);
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/watermark/`, {
          method: "POST",
          body: formData
        }).then(response => {
          if (response.status === 200) {
            return response.json().then(({ data }) => {
              if (data.link) {
                this.setState({ url: data.link });
                this.addImage();
              }
            });
          }
        });
      }
    }
    // this.props.close();
  };
  render() {
    return (
      <>
        <input
          accept="image/*"
          onChange={this.onChange}
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
        />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" component="span">
            <PhotoCamera />
          </IconButton>
        </label>
      </>
    );
  }
}
