import React from 'react';
import { List, Datagrid, TextField, ImageField, EditButton, FunctionField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import SearchComponent from '../../filters/SearchComponent';
import Avatar from '@material-ui/core/Avatar';

const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  teamLogo:{
    width:25,
    '& img':{
      width:"100%",
    }
  },
  editBtn:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem",
    color: "#fff",
    backgroundColor: "#25A9FE",
    '& svg': { 
      marginLeft: 7,
      fontSize: 16,
      color: "#fff",
    },
    '&:hover': {
      color: "#25A9FE",
      backgroundColor: "#f1f1f1",
      '& svg': { 
        color: "#25A9FE",
      }
    }
  },
}


const TeamList = (props: any) => {
  const classes = props.classes;
  const filters = (
    <SearchComponent
      source="contestant_id"
      reference="analytics/teams"
      itemToString={(choice:any) => (`${choice.club_name}`)}
    /> 
  )
  return(
  <List {...props} title="قائمة الفرق" filters={filters} exporter={false} actions={<></>}>
      <Datagrid rowClick="edit">
        <ImageField source="logo" className={classNames(classes.tabField,classes.alignRight,classes.teamLogo)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        <TextField source="name" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        <TextField source="short_name" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        <TextField source="country.name" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>

        <EditButton
          resource="squad"
          basePath={"squad"}
          identifier="squad_id"
          label="تعديل التشكيلة"
          className={classes.editBtn}
          headerClassName={classNames(classes.tableHeader,classes.alignRight)}
        />
        <PreviewButtonComponent />
        
      </Datagrid>
  </List>
  )
};

const TeamListView = withStyles(styles)(TeamList)

export default TeamListView