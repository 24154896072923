import React from "react";
import { List, TextField, Datagrid } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import dayjs from "dayjs";
import PreviewButtonComponent from "../../components/PreviewButtonComponent";
import StyledButton from "../../components/StyledButton";
import ContentFilter from '../../filters/ContentFilter';

const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize: ".74rem",
  },
  alignRight: {
    textAlign: "right" as "right",
  },
  tableHeader: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize: ".8rem",
    fontWeight: 700,
  },
  allBlue: {
    direction: "ltr" as "ltr",
  },
};


const PubDate = (props: any) => {
  var datetime = dayjs(props.record.pub_date * 1000)
    .local()
    .format("HH:mm - DD/MM/YYYY");
  return <span>{datetime}</span>;
};

const fetchImage = async (imageUrl: string) => {
  try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], 'image.jpg', { type: 'image/jpeg' });
  } catch (error) {
      console.error(error);
      return null;
  }
};


const FeedList = (props: any) => {
  const classes = props.classes;

  

  const onGenerateClick = async (record:any) => {
    const response = await fetch(`${process.env.REACT_APP_AI_API_ENDPOINT}/generate/from_feed/?link=${record.link}`)
    let results = await response.json()
    results['w_image'] = results['image']
    let rawFile = await fetchImage(results['w_image']['default_path'])
    results['w_image']['rawFile'] = rawFile
    props.history.push(`#/articles/create`, { state: {results} })
    window.history.pushState({defaultValue: results}, '', `#/articles/create`)
  }

  return (
    <List {...props} exporter={false} filters={<ContentFilter sources={["source"]}/>}>
      <Datagrid>
        <PubDate source="pub_date" label="تاريخ النشر" headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>  
        <TextField
          source="title"
          className={classNames(classes.tabField, classes.alignRight)}
          headerClassName={classNames(classes.tableHeader, classes.alignRight)}
        />
        <TextField
          source="source"
          className={classNames(classes.tabField, classes.alignRight)}
          headerClassName={classNames(classes.tableHeader, classes.alignRight)}
        />
        <TextField
          source="tags"
          className={classNames(classes.tabField, classes.alignRight)}
          headerClassName={classNames(classes.tableHeader, classes.alignRight)}
        />
        <PreviewButtonComponent />
        <StyledButton label="Generate" onClick={onGenerateClick}/>
      </Datagrid>
    </List>
  );
};

const FeedListComponent = withStyles(styles)(FeedList);

export default FeedListComponent;
