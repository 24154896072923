import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ar-ma'

dayjs.locale('ar-ma') // use loaded locale globally
dayjs.extend(customParseFormat)
dayjs.extend(utc)


export const createMediaUrl = function createMediaUrl(object_id:string, content_type:string){
  return `${process.env.REACT_APP_MEDIA_URL}/stats/${content_type}/${object_id}.png`
}

const EVENTS_CODE: any = { G: 'هدف', OG: 'هدف بالخطأ', PG: 'هدف من ضربة جزاء' }

class Match {
  private model: { [x: string]: any }
  private PLAYING    = 'Playing'
  private FIXTURE    = 'Fixture'
  private POSTPONED  = 'Postponed'
  private PLAYED     = 'Played'
  private CANCELLED  = 'Cancelled'
  private SUSPENDED  = 'Suspended'
  private MATCH_PERIODS_HT = 'HT'
  private PR_FIRST_HALF = 1
  private PR_SECOND_HALF = 2
  private PR_EX_FIRST_HALF = 3 
  private PR_EX_SECOND_HALF = 4
  private PR_PENALTY_SHOOTOUT = 5 
  private PR_HALFTIME = 10
  private PR_FULLTIME = 14
  private PR_PREMATCH = 16
  constructor(match: { [x: string]: any }) {
    this.model = match
  }
  public getInstance() {
    return {
      time: this.getDatetime('HH:mm:ss'),
      date: this.getDatetime('YYYY-MM-DD'),
      goal: this.mapGoals(),
      card: this.mapCards(),
      var: this.mapVar(),
      substitute: this.mapSubstitutes(),
      line_up: this.mapLineups(),
      status: this.getStatus(),
      getTeamA: this.getTeamA,
      getTeamB: this.getTeamB,
      updated_keys: this.model.updated_keys,
      match_details: {
        match_status: this.model.match_details.match_status,
        winner: this.model.match_details.winner,
        period_id: this.model.match_details.period_id,
        match_time: this.model.match_details.match_time,
        scores: this.model.match_details.scores
      },
      periods: this.getPeriods()
    }
  }
  getPeriods(){
    return [
      { id: this.PR_PREMATCH,name: "ماقبل المباراة"  },
      { id: this.PR_FIRST_HALF, name: "شوط الأول" },
      { id: this.PR_SECOND_HALF, name: "شوط الثاني"  },
      { id: this.PR_HALFTIME,name: "إستراحة"  },
      { id: this.PR_EX_FIRST_HALF,name: "شوط الإضافي الأول"  },
      { id: this.PR_EX_SECOND_HALF,name: "شوط الإضافي الثاني"  },
      { id: this.PR_PENALTY_SHOOTOUT,name: "ضربات ترجيحية"  },
      { id: this.PR_FULLTIME,name: "نهاية المبارة"  }
    ]
  }
  hasScore = (period: string  = 'total') => {
    return this.model.match_details.scores && this.model.match_details.scores[period]
  }
  getScore = (period : string = 'total') => {
    if(this.hasScore(period)){
      return this.model.match_details.scores[period]
    }
    return { home: null, away: null }
  }
  mapGoals() {
    const { goal = [] } = this.model
    return goal.map((g: any) => {
      const scorer = {
        match_name: g.scorer_name,
        match_name_fr: g.scorer_name_fr,
        match_name_en: g.scorer_name_en,
        id: g.scorer_id
      }
      return { ...g, scorer }
    })
  }
  mapCards() {
    const { card = [] } = this.model
    return card.map((c: any) => {
      const player = {
        match_name: c.player_name,
        match_name_fr: c.player_name_fr,
        match_name_en: c.player_name_en,
        id: c.player_id
      }
      return { ...c, player }
    })
  }
  mapVar() {
    const { var : vars = [] } = this.model
    return vars.map((c: any) => {
      const player = {
        match_name: c.player_name,
        match_name_fr: c.player_name_fr,
        match_name_en: c.player_name_en,
        id: c.player_id
      }
      return { ...c, player }
    })
  }
  mapSubstitutes() {
    const { substitute = [] } = this.model
    return substitute.map((s:any) => {
      const player_on = {
        match_name: s.player_on_name,
        match_name_fr: s.player_on_name_fr,
        match_name_en: s.player_on_name_en,
        id: s.player_on_id
      }
      const player_off = {
        match_name: s.player_off_name,
        match_name_fr: s.player_off_name_fr,
        match_name_en: s.player_off_name_en,
        id: s.player_off_id
      }
      return { ...s, player_on, player_off }
    })
  }
  mapLineups() {
    let { line_up = [] } = this.model
    if (line_up.length == 0) {
      line_up = [
        {
          contestant_id: this.getTeamA().id,
          player: [],
          // team_official: {}
        },
        {
          contestant_id: this.getTeamB().id,
          player: [],
          // team_official: {}
        }
      ]
    }
    return line_up
  }
  mapTimeUtc(time_utc: string) {
    return dayjs.utc(time_utc, "HH:mm:ss").local().format("HH:mm:ss")
  }
  getDatetime(format: string = ''){
    const time = this.model.time
    const date = this.model.date
    return dayjs(`${date}${time}`).format(format)
  }
  getStatus(){
    return this.model.match_details.match_status
  }
  getTeam(position : string){
    if(! position){
      throw new Error('position arg is required')
    }
    const team = this.model.contestant
      .filter((t:any) => t.position === position)
      .filter(Boolean)
      .map((t:any) => ({
        url: `/analytics/team/${t.id}`,
        position: position,
        logo: createMediaUrl(t.id, 'logos'),
        name: t.name,
        id: t.id,
        resourceURL: `#/${process.env.REACT_APP_TEAM_RESOURCE}/${t.id}`
      }))
    if (team.length === 0) {
      return {
        url: `/analytics/team/`,
        position: position,
        logo: createMediaUrl('0', 'logos'),
        name: `غير معروف`,
        id: 0
      }
    }
    return team[0]
  }
  getTeamA = () => {
    return this.getTeam('home')
  }
  getTeamB = () => {
    return this.getTeam('away')
  }
  transformFields() {
    Object.keys(this.model).map((key:string) => {
      switch (key) {
        //DATE
        case 'date':
          this.model['date'] = dayjs(this.model['date']).format('YYYY-MM-DD[Z]')
          break;
        //TIME
        case 'time':
          this.model['time'] =  dayjs(this.model['time'], 'HH:mm:ssZ').utc().format('HH:mm:ss[Z]')
          break;
        //MATCH_DETAILS
        case 'match_details':
          if (this.model['match_details']['scores']) {
            Object.keys(this.model['match_details']['scores']).map((key:any) => {
              let value = this.model['match_details']['scores'][key]
              if(!Number.isInteger(value.home) || !Number.isInteger(value.away)) {
                delete this.model['match_details']['scores'][key]
              }
            })
            let { ft = undefined, et = undefined, pen = undefined, ht = undefined }  = this.model['match_details']['scores']
            let total:any = {}
            if (et) {
              total = { ...et }
            }
            else if (ft) {
              total = { ...ft }
            }
            else if (ht) {
              total = { ...ht }
              this.model['match_details']['scores']['ft'] = ht
            }
            if (pen) {
              total.home += pen.home
              total.away += pen.away
            }
            if (Number.isInteger(total.home) && Number.isInteger(total.away)) {
              this.model['match_details']['scores']['total'] = total
            }
          }
          break;
        //GOALS
        case 'goal':
          this.model['goal'] = this.model['goal'].map((g:any) => {
            const { scorer } = g
            g['scorer_name'] = scorer.match_name ? scorer.match_name : `${scorer.first_name} ${scorer.last_name}`
            g['scorer_name_fr'] = scorer.match_name_fr ? scorer.match_name_fr : `${scorer.first_name_fr} ${scorer.last_name_fr}`
            g['scorer_name_en'] = scorer.match_name_en ? scorer.match_name_en : `${scorer.first_name_en} ${scorer.last_name_en}`
            
            g = { ...g, scorer_id: scorer.id }
            delete g['scorer']
            return g
          })
          break;
        //CARDS
        case 'card':
          this.model['card'] = this.model['card'].map((c:any) => {
            const { player } = c
            c['player_name'] = player.match_name ? player.match_name :`${player.first_name} ${player.last_name}`
            c['player_name_fr'] = player.match_name_fr ? player.match_name_fr :`${player.first_name_fr} ${player.last_name_fr}`
            c['player_name_en'] = player.match_name_en ? player.match_name_en :`${player.first_name_en} ${player.last_name_en}`

            c = { ...c, player_id: player.id }
            delete c['player']
            return c
          })
          break;
        // SUBS 
        case 'substitute':
          this.model['substitute'] = this.model['substitute'].map((c:any) => {
            const { player_on, player_off } = c
            c['player_on_name'] = player_on.match_name ? player_on.match_name : `${player_on.first_name} ${player_on.last_name}`
            c['player_on_name_fr'] = player_on.match_name_fr ? player_on.match_name_fr : `${player_on.first_name_fr} ${player_on.last_name_fr}`
            c['player_on_name_en'] = player_on.match_name_en ? player_on.match_name_en : `${player_on.first_name_en} ${player_on.last_name_en}`

            c = { ...c, player_on_id: player_on.id }
            delete c['player_on']
    
            c['player_off_name'] = player_off.match_name ? player_off.match_name : `${player_off.first_name} ${player_off.last_name}`
            c['player_off_name_fr'] = player_off.match_name_fr ? player_off.match_name_fr : `${player_off.first_name_fr} ${player_off.last_name_fr}`
            c['player_off_name_en'] = player_off.match_name_en ? player_off.match_name_en : `${player_off.first_name_en} ${player_off.last_name_en}`
            
            c = { ...c, player_off_id: player_off.id }
            delete c['player_off']
            return c
          })
          break;
        // LINEUPS
        case 'line_up':
          this.model['line_up'] = this.model['line_up'].map((line_up:any) => {
            if (line_up['player']) {
              line_up['player'] = line_up['player'].filter((p:any) => Boolean(p.participant !== false))
              .map((p:any) => {
                delete p['participant']                
                return p
              })
            }
            else {
              line_up['player'] = []
            }

            if (line_up['team_official']) {
              line_up['team_official'] = {
                "id": line_up['team_official']["id"],
                "first_name": line_up['team_official']["first_name"],
                "last_name": line_up['team_official']["last_name"],
                "type": "manager"
              }
            }
            return line_up
          })
          break;
        // VAR
        case 'var':
          this.model['var'] = this.model['var'].map((c:any) => {
            const { player } = c
            c['player_name'] = player.match_name ? player.match_name :`${player.first_name} ${player.last_name}`
            c['player_name_fr'] = player.match_name_fr ? player.match_name_fr :`${player.first_name_fr} ${player.last_name_fr}`
            c['player_name_en'] = player.match_name_en ? player.match_name_en :`${player.first_name_en} ${player.last_name_en}`

            c = { ...c, player_id: player.id }
            delete c['player']
            return c
          })
          break;

      }
    })
    return { ...this.model }
  }
}

export default Match