import React, { Component, useState, useRef, MutableRefObject } from "react";
import styles from "./Button.module.css";
import IconButton from "@material-ui/core/IconButton";
import CodeIcon from "@material-ui/icons/Code";
import TextField from "@material-ui/core/TextField";
import { Button, List, ListItem, ListSubheader } from "@material-ui/core";

type ButtonProps = {
  editorState: any;
  onChange: any;
  modifier: any;
  openModal: any;
  closeModal: any;
};

const ModalComponent = ({ addEmbed }: { [x: string]: any }) => {
  const [src, setSrc] = useState("");
  const [width, setWidth] = useState(250);
  const [height, setHeight] = useState(250);

  const srcRef = useRef<MutableRefObject<HTMLDivElement>>(null);
  const widthRef = useRef<MutableRefObject<HTMLDivElement>>(null);
  const heightRef = useRef<MutableRefObject<HTMLDivElement>>(null);
  const onClick = (ref: any) => {
    if (ref) {
      ref.current.focus();
    }
  };
  return (
    <>
      <List
        component="nav"
        subheader={<ListSubheader component="div">أضف رابط مادة</ListSubheader>}
      >
        <ListItem>
          <TextField
            tabIndex={1}
            inputRef={srcRef}
            onClick={onClick.bind(null, srcRef)}
            id="src"
            onChange={e => setSrc(e.currentTarget.value)}
            label="رابط"
            value={src}
            margin="normal"
          />
        </ListItem>
        
        <ListItem>
          <Button color="primary" onClick={() => addEmbed(src, width, height)}>
            أضف
          </Button>
        </ListItem>
      </List>
    </>
  );
};

export default class EmbedButton extends Component<ButtonProps> {
  // Start the popover closed
  state = {
    url: "",
    open: false,
    dialogOpen: false
  };
  private preventNextClose = true;

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener("click", this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true;
  };

  openPopover = (event: any) => {
    event.preventDefault();
    if (!this.state.open) {
      this.preventNextClose = true;
      this.setState({
        open: true
      });
    }
  };

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false
      });
    }

    this.preventNextClose = false;
  };

  addEmbed = (src: string, width: Number, height: Number) => {
    const { editorState, onChange } = this.props;
    onChange(this.props.modifier(editorState, { src, width, height }));
    this.props.closeModal();
  };

  onChange = (event: React.ChangeEventHandler<HTMLInputElement>) => {
    //@ts-ignore
    this.setState({ url: event.currentTarget.value });
  };
  onDialogOpen = () => {
    this.setState({ dialogOpen: true });
    this.closePopover();
  };
  renderModal(addEmbed: any) {
    return <ModalComponent addEmbed={addEmbed} />;
  }
  render() {
    const buttonClassName = this.state.open
      ? styles.addImagePressedButton
      : styles.addImageButton;
    const { openModal } = this.props;
    return (
      <>
        <IconButton
          className={buttonClassName}
          color="primary"
          component="span"
          onClick={() => openModal(this.renderModal(this.addEmbed))}
        >
          <CodeIcon />
        </IconButton>
      </>
    );
  }
}
