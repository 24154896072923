import React from 'react';
import { List, Datagrid, NumberField, BooleanField, TextField, DateField, SelectField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import dayjs from "dayjs";
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import ContentFilter, { getDefaultFilterLang } from '../../filters/ContentFilter';


const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  allBlue:{
    direction: 'ltr' as 'ltr'
  }
}


const PubDate = (props: any) => {
  var datetime = dayjs(props.record.created_at, "YYYY-MM-d HH:mm:ss").format("HH:mm - DD/MM/YYYY");
  return (<span>{datetime}</span>)
}


const TagList = (props: any) => {
  const classes = props.classes;
  const filterDefaultValues = {
    lang: getDefaultFilterLang()
  }
  
  return (
  <List {...props} exporter={false} 
    filters={<ContentFilter sources={['lang']}/>} filterDefaultValues={filterDefaultValues}
  >
      <Datagrid rowClick="edit" >
        <PubDate source="created_at" label="تاريخ النشر" headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>  
        <TextField source="name" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        <TextField source="name_fr" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        <TextField source="created_by.username" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        {/* <TextField source="custom_name" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/> */}
        <SelectField source="status" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
            choices={[
              { id: 1, name: 'في إنتظار الموافقة' },
              { id: 2, name: 'منشور' },
              { id: 3, name: 'سينشر لاحقا' },
            ]} />
        {/* <TextField source="slug" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/> */}
        <SelectField source="is_trending" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
            choices={[
              { id: false, name: 'لا' },
              { id: true, name: 'نعم' }
            ]} />
        <PreviewButtonComponent />
      </Datagrid>
  </List>
)};

const TagListComponent = withStyles(styles)(TagList)

export default TagListComponent