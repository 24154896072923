import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';


const JobExecutionLog = (props: any) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    const fetchLogs = (jobExecId: any) => {
        setLoading(true)
        setError(null)
        fetch(
            `${process.env.REACT_APP_SCHEDULER_API_ENDPOINT}/${process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE}/${jobExecId}/log/`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken') || ""
                }
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw response.statusText
                }
                return response.json()
            })
            .then((response) => {
                setData(response)
            })
            .catch((e) => {
                setError(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchLogs(props.match.params.id)
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        return <p>{error}</p>
    }

    return (
        <div style={{ direction: "ltr" }}>
            {
                data.map(line => <p>{line}</p>)
            }
        </div>
    )
};

export default JobExecutionLog