import React, { useEffect, useState } from "react";
import {
  Edit,
  BooleanInput,
  DisabledInput,
  SelectInput,
  NumberInput,
  TextInput,
  SimpleFormIterator,
  translate,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  SelectArrayInput,
  Title
} from "react-admin";
import { TabbedForm, FormTab } from "react-admin";
import { DateInput, TimeInput } from "react-admin-date-inputs";
import dayjs from "dayjs";
import Grid from "@material-ui/core/Grid";
import { Avatar, Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ar-ma'
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DayJSUtils from '@date-io/dayjs';
import StreamingInput from '../../inputs/StreamingInput';
import _ from 'lodash';
import NewsfeedQuickCreationButton from './NewsfeedQuickCreationButton';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import MatchGoalsInput from '../../inputs/MatchGoalsInput';
import MatchCardsInput from '../../inputs/MatchCardsInput';
import MatchSubsInput from '../../inputs/MatchSubsInput';
import MatchLineupsInput from '../../inputs/MatchLineupsInputALT';
import MatchVarInput from '../../inputs/MatchVarInput';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AuthProvider from '../../services/AuthProvider';
import { fetchContestantSquad } from '../../services/DataProvider'
import SquadPlayerSearchInput from '../../inputs/SquadPlayerSearchInput';


dayjs.extend(customParseFormat)
dayjs.extend(utc)

const AvatarInput = (props: any) => {
  const classes = props.classes;
  return (
    <Avatar
      style={{}}
      className={classNames(classes.root, classes.bg)}
      key={4}
      src={props.record[props.source]}
    />
  );
};

const styles = {

  inputLabel: {
    '& label': {
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".74rem",
      
    },
    '& input': {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".84rem",
      width: '100%'
    },
    '& textarea': {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".84rem",
    }
  },
  midSizedInput: {
    width: "50%",
  },
  largeSizedInput: {
    width: "100%",
  },
  dateInput: {
    width: 82,
  },
  coloredLabel: {
    '& label': {
      fontWeight: 700,
    }
  },
  boldText: {
    fontWeight: 700,
    color: "#687c8c",
    '& input': {
      color: "#687c8c",
    }
  },
  txtColor: {
    color: "red",
  },
  radioBtn: {
    '& label': {
      flexDirection: "row-reverse" as 'row-reverse',
      justifyContent: "flex-end",
      margin: 0,
      '&>span:first-child': {
        direction: "ltr" as 'ltr',
      },
      '&>span:last-child': {
        width: 100,
        display: "inline-block",
      }
    },
    '& span': {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    }
  },

  isLTR: {
    direction: 'ltr' as 'ltr',
  },
  tabWrapper: {
    width: "100%",
  },
  formTab: {
    fontFamily: "'Roboto','Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize: "2.84rem",
    backgroundColor: "red"
  },
  rightSpacing: {
    marginRight: 24
  },
  verticalSpacing: {
    margin: "24px 0",
  },
  paper: {
    padding: 16
  },
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    width:"100%",
    backgroundColor: "#ececec",
    margin: "24px 0",
    borderRadius: 4
  },
  teamOfficial: {
    width: "50%",
    marginTop: 32
  }
}

const avatarStyles = {
  root: {
    display: 'inline-block',
  },
}
// @ts-ignore
DayJSUtils.prototype.getStartOfMonth = DayJSUtils.prototype.startOfMonth

const TeamLogo = withStyles(avatarStyles)(AvatarInput)

const GoalsVideosHOC = (FormIterator: any) => {
  return class extends React.Component<any, any>{
    shouldComponentUpdate = FormIterator.shouldComponentUpdate
    render() {
      const fields = Object.keys(this.props.fields).reduce((p: any, n: any) => {
        return [...p, this.props.fields[n]]
      }, [])
      return <FormIterator {...{ ...this.props, fields: fields }} />
    }
  }
}
const SetFormIterator = translate(GoalsVideosHOC(SimpleFormIterator))

const MatchTitle = ({ record }: { [x: string]: any }) => {
  return <span>{record ? `${record.getTeamA().name} ضد ${record.getTeamB().name}` : ''}</span>;
};


const MyCustomToolbar = (props: any) => (
  <Toolbar {...props} >
    <SaveButton label="تحديث" />
    <PreviewButtonComponent />
  </Toolbar>
);

const CustomScoreInput = (props: any) => {
  const customLabel = props.customLabel;
  const classes = props.classes;
  const source = props.source
  console.log("PROPS SCORE: ",props)
  return (
    <div key={source}>
      <span style={{ display: "block", fontSize: ".74rem", color: "rgba(0, 0, 0, 0.54)", marginTop: "10px", }}>{customLabel}</span>
      <Avatar
        style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "16px" }}
        className={classNames(classes.root, classes.bg)}
        // key={4}
        src={props.record.getTeamA().logo}
      />
      <NumberInput variant="outlined" label={''} source={`${source}.home`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
      <span style={{ fontWeight: 700, display: "inline-block", verticalAlign: 'middle', margin: '0 16px' }}></span>
      <NumberInput label={''} source={`${source}.away`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
      <Avatar
        style={{ display: "inline-block", verticalAlign: "middle", marginRight: "16px" }}
        className={classNames(classes.root, classes.bg)}
        // key={4}
        src={props.record.getTeamB().logo}
      />
    </div>
  );
}

const CustomScoreInputComponent = withStyles(styles)(CustomScoreInput)

const CustomScoreTable = (props: any) => {
  console.log("PROPS: ",props)
  const customLabel = props.customLabel;
  const classes = props.classes;
  const source = props.source
  return (
    <Paper>
      <Table >
        <TableHead style={{ background: "#ececec"}}>
          <TableRow>
            <TableCell></TableCell>
            {/* <TableCell align="right">
              <Avatar
                style={{ display: "inline-block", verticalAlign: "middle", marginRight: "16px" }}
                // className={classNames(classes.root, classes.bg)}
                // key={4}
                src={props.record.contestant[0]}
              />
            </TableCell>
            <TableCell align="right">
              <Avatar
                style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "16px" }}
                // className={classNames(classes.root, classes.bg)}
                // key={4}
                src={props.record.getTeamB().logo}
              />
            </TableCell> */}
            
            <FormDataConsumer>
                    {
                      (dataProps: any) => {
                        return (
                          <>
                            <TableCell align="center">{dataProps.formData.getTeamA().name}</TableCell>
                            <TableCell align="center">{dataProps.formData.getTeamB().name}</TableCell>
                          </>
                        )
                      }
                    }
                  </FormDataConsumer>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">نتيجة الشوط الأول</TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.ht.home`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.ht.away`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">نتيجة الوقت الأصلي</TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.ft.home`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.ft.away`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">نتيجة الوقت الإضافي</TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.et.home`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.et.away`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">نتيجة الضربات الترجيحية</TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.pen.home`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.pen.away`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">مجموع الذهاب والإياب </TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.aggregate.home`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
              <TableCell align="center">
                <NumberInput label={''} source={`match_details.scores.aggregate.away`} min={0} step={1} style={{ marginTop: 5, overflow: "hidden", lineHeight: "128%", width: 35, whiteSpace: "nowrap", textOverflow: "ellipsis" }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextInput source="match_details.related_match_id" label='مفتاح مباراة الذهاب' fullWidth />
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

const CustomScoreTableComponent = withStyles(styles)(CustomScoreTable)


const MatchEdit = (props: any) => {
  const classes = props.classes;
  const record = _.get(props, 'form.record-form.values')
  const date = _.get(props, 'form.record-form.values.datetime')

  let redirectUrl = `/${process.env.REACT_APP_MATCHES_RESOURCE}`
  if (date) {
    redirectUrl = `${redirectUrl}/${dayjs.unix(date).format("YYYY-MM-DD")}`
  }
  const [ user, setUser ] = useState()
  const [ squads, setSquads] = useState<{[key: string]: Array<any>}>({})
  useEffect(() => {
    const getUser = async () => {
      const u = await AuthProvider.getIdentity()
      setUser(u)
    }
    getUser()

    
  }, [])

  const fetchSquads = async () => {
    const { contestant } = record
    const homeId:string = contestant[0].id
    const awayId:string = contestant[1].id

    let altSquad:any = []
    
    if(!squads[homeId]) {
      const homeSquad = await fetchContestantSquad(homeId)
      altSquad[homeId] = homeSquad
    }
    if(!squads[awayId]) {
      const awaySquad = await fetchContestantSquad(awayId)
      altSquad[awayId] = awaySquad
    }
    setSquads({...squads, ...altSquad})
  }

  useEffect(() => {
    if(!record || !record.contestant) {
      return
    }
    fetchSquads()
    
  }, [record])
  return (
    <div>
      <div>

      </div>
      <Edit undoable={false} title={<MatchTitle />} {...props}>
        <TabbedForm redirect={false} toolbar={<MyCustomToolbar />} handleSubmit={(Submit: any) => {
          let { syncErrors } = props.form['record-form']
          if (syncErrors && Object.keys(syncErrors).length > 0) {
            return
          }
          if (props.dirtyFields && props.form) {
            const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, key: any) => {
              const rec = props.form["record-form"].values
              let value = rec[key]
              if (key == 'line_up') {
                for (let index = 0; index < value.length; index++) {
                  if(!value[index]) {
                    value[index] = {}
                  }
                  value[index] = {
                    ...value[index], contestant_id: rec["contestant"][index]["id"]
                  }
                }
              }
              return { ...p, [key]: value }
            }, {})
            return Submit(dirtyValues)
          }
        }}>
          <FormTab label="معلومات عن المباراة" className={classes.formTab} >
            <div className={classes.root} style={{ width: "100%", padding: "0 24px" }}>
              <Grid container spacing={40} style={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={6}>
                  <DisabledInput 
                    label={'الدوري'}
                    source="competition.name" 
                    className={classNames(classes.inputLabel)}
                    fullwidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DisabledInput 
                    label={'الجولة'} 
                    source="week" 
                    className={classNames(classes.inputLabel)}
                    fullwidth
                  />
                </Grid>
              </Grid> 
            </div>



          <div className={classes.root} style={{ width: "100%" }} >
            <h4 style={{ paddingBottom: 16 }}>تفاصيل مجريات المباراة</h4>
            <Paper style={{ width: "100%", padding: "0 24px", boxSizing: "border-box" }}>
              <Grid container spacing={40} >
                <Grid item xs={4}>
                  <SelectInput 
                    className={classNames(classes.inputLabel, classes.largeSizedInput)} 
                    label="حالة المباراة" 
                    source="match_details.match_status" 
                    choices={[
                      { id: 'Fixture', name: 'لم تبدأ بعد' },
                      { id: 'Playing', name: 'جارية حالياً' },
                      { id: 'Played', name: 'انتهت' },
                      { id: 'Postponed', name: 'مؤجلة' },
                      { id: 'Cancelled', name: 'ملغاة' },
                    ]} 
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormDataConsumer>
                    {
                      (dataProps: any) => {
                        return (
                          <SelectInput
                            label="شوط المباراة"
                            source={"match_details.period_id"}
                            choices={dataProps.formData.periods}
                            className={classNames(classes.inputLabel, classes.largeSizedInput)}
                          />
                        )
                      }
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={4}>
                  <FormDataConsumer>
                    {
                      (dataProps: any) => {
                        return (
                          <SelectInput
                            label="الفائز"
                            source={"match_details.winner"}
                            className={classNames(classes.inputLabel, classes.largeSizedInput)}
                            choices={[{ id: 'draw', name: 'تعادل' }].concat(dataProps.formData.contestant.map((c:any) => ({ id: c.position, name: c.name })))}
                          />
                        )
                      }
                    }
                  </FormDataConsumer>
                </Grid>
              </Grid>
              <Grid container spacing={40} >
                <Grid item xs={4}>
                  <DateInput
                    ampm={false}
                    source="date"
                    label="تاريخ المباراة"
                    providerOptions={{
                      utils: DayJSUtils
                    }}
                    className={classNames(classes.inputLabel, classes.boldText, classes.largeSizedInput)}
                    style={{ width: "100%" }}
                    format={(dt: any) => {
                      if (typeof (dt) === 'number') {
                        return dayjs.unix(dt).format("YYYY-MM-DD")
                      }
                      return dayjs(dt).format("YYYY-MM-DD")
                    }}
                    options={{
                      format: "YYYY-MM-DD"
                    }}
                    formClassName={classes.wrapper}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TimeInput
                    source="time"
                    label="توقيت المباراة"
                    className={classNames(classes.inputLabel, classes.boldText, classes.largeSizedInput)}
                    style={{ width: "100%", backgroundColor: "red" }}
                    providerOptions={{
                      utils: DayJSUtils
                    }}
                    format={(dt: any) => {
                      return dayjs(dt, "HH:mm")
                    }}
                    options={{
                      format: "HH:mm",
                      ampm: false
                    }}
                    formClassName={classes.wrapper}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberInput 
                    label={'الدقيقة'} 
                    source={'match_details.match_time'} 
                    min={0}  
                    className={classNames(classes.inputLabel, classes.largeSizedInput)}
                    variant="outlined"
                  />
                </Grid>
                
              </Grid>
              <Grid container spacing={40} >
                <Grid item xs={4}>
                  <TextInput
                    source="stage.name"
                    label="دور المباراة"
                    className={classNames(classes.inputLabel, classes.boldText, classes.largeSizedInput)}
                    style={{ width: "100%" }}
                    formClassName={classes.wrapper}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source="venue.short_name"
                    label="ملعب المباراة"
                    className={classNames(classes.inputLabel, classes.boldText, classes.largeSizedInput)}
                    style={{ width: "100%" }}
                    formClassName={classes.wrapper}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>




            <FormDataConsumer>
              {
                (dataProps: any) => {
                  if(! user || ! user.is_staff){
                    return null
                  }
                  if(! dataProps.record.updated_keys ){
                    return null
                  }
                  return (
                    <SelectArrayInput
                      label="updated_keys"
                      source={"updated_keys"}
                      choices={dataProps.record.updated_keys.map((k:string) => ({ id: k, name: k}))}
                    />
                  )
                }
              }
            </FormDataConsumer>

            <Grid container spacing={40} style={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={6}>
                <h4>تفاصيل نتيجة المباراة</h4>
                <CustomScoreTableComponent />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h4>تفاصيل أخرى</h4>
                <Paper style={{ padding: "16px 24px"}}>
                <Grid container spacing={40} style={{ flexGrow: 1 }}>
                  <Grid item xs={6}>
                    <BooleanInput source="is_important" label="مباراة مهمة" />
                  </Grid>
                  <Grid item xs={6}>
                    <BooleanInput source="competition.show_calendar" label="عرض في المباريات" />
                  </Grid>
                  <Grid item xs={6}>
                    <BooleanInput source="with_betting" label="ضمن التوقعات" />
                  </Grid>
                </Grid>
                
                </Paper>
              </Grid>
            </Grid>
            



            {/* <CustomScoreInputComponent source="match_details.scores.ht" customLabel='نتيجة الشوط الأول' />
            <CustomScoreInputComponent source="match_details.scores.ft" customLabel='نتيجة الوقت الأصلي' />
            <CustomScoreInputComponent source="match_details.scores.et" customLabel='نتيجة الوقت إضافي' />
            <CustomScoreInputComponent source="match_details.scores.pen" customLabel='نتيجة ضربات الترجيحية' />
            <CustomScoreInputComponent source="match_details.scores.aggregate" customLabel='نتيجة مباراة الدهاب' /> */}
            

            {/* <CustomScoreInputComponent source="match_details.scores.total" customLabel='النتيجة الإجمالية' /> */}
          </FormTab>
          {/* <FormTab label="ميلتيميديا" className={classes.formTab}>
            <TextInput rows={4} source="highlight_embed" className={classNames(classes.inputLabel, classes.isLTR, classes.largeSizedInput)} multiline />
            <StreamingInput matchId={props.matchId} source="streaming_embed" className={classNames(classes.inputLabel, classes.isLTR, classes.largeSizedInput)} />
            <NewsfeedQuickCreationButton />
          </FormTab> */}

          <FormTab label="أهداف المباراة" className={classes.formTab} contentClassName={classes.tabWrapper} >
            <MatchGoalsInput squads={squads}/>
          </FormTab>

          <FormTab label="بطاقات" className={classes.formTab} contentClassName={classes.tabWrapper} >
            <MatchCardsInput {...props} squads={squads}/>
          </FormTab>

          <FormTab label="تغييرات" className={classes.formTab} contentClassName={classes.tabWrapper} >
            <MatchSubsInput squads={squads}/>
          </FormTab>

          <FormTab label={`تشكيلة الفريق ${record ? record.getTeamA().name: 'A'}`} className={classes.formTab} contentClassName={classes.tabWrapper} >
            <FormDataConsumer>
                  {
                    (dataProps: any) => {
                      let cst = dataProps.formData.getTeamA()
                      return (
                        <>
                          <MatchLineupsInput
                            contestant={cst}
                            squad={squads[cst.id]}
                            index={0}
                          />
                          <div className={classes.teamOfficial}>
                            <SquadPlayerSearchInput
                              name={"line_up[0].team_official"}
                              squad={squads[cst.id] ? squads[cst.id].filter(s => s.type != "player") : []}
                              label="المدرب"
                            />
                          </div>
                           <TextInput source="line_up[0].formation_used" label="التشكيلة المستخدمة" />
                        </>
                      )
                    }

                  }
            </FormDataConsumer>
          </FormTab>

          <FormTab label={`تشكيلة الفريق ${record ? record.getTeamB().name: 'B'}`} className={classes.formTab} contentClassName={classes.tabWrapper} >
            <FormDataConsumer>
                  {
                    (dataProps: any) => {
                      let cst = dataProps.formData.getTeamB()
                      return (
                        <>
                          <MatchLineupsInput
                            contestant={cst}
                            squad={squads[cst.id]}
                            index={1}
                          />
                          <div className={classes.teamOfficial}>
                            <SquadPlayerSearchInput
                              name={"line_up[1].team_official"}
                              squad={squads[cst.id] ? squads[cst.id].filter(s => s.type != "player") : []}
                              label="المدرب"
                            />
                          </div>
                          <TextInput source="line_up[1].formation_used" label="التشكيلة المستخدمة" />
                        </>
                      )
                    }

                  }
            </FormDataConsumer>
          </FormTab>
          <FormTab label="VAR" className={classes.formTab} contentClassName={classes.tabWrapper} >
            <MatchVarInput squads={squads}/>
          </FormTab>
          
        </TabbedForm>
      </Edit>
    </div>
  );
};

const MatchEditView = withStyles(styles)(MatchEdit)


export default connect(
  (state: any, props: any) => {
    const matchId = _.get(state, 'form.record-form.values.match_id')
    if (matchId) {
      state = { ...state, matchId }
    }
    if (state && state.form && state.form['record-form']) {
      let { values, initial, anyTouched, fields, syncErrors } = state.form['record-form']
      let dirtyFields:any = null
      if (anyTouched) {
        dirtyFields = fields
      }

      const arrayFields = ['goal', 'card', 'substitute']
      arrayFields.map(field => {
        let removed_count = initial[field].length - values[field].length
        if (removed_count > 0) {
          dirtyFields = dirtyFields ? {  ...dirtyFields, [field]: 1 } : { [field]: 1 }
          if (syncErrors && syncErrors[field]) {
            delete state.form['record-form']['syncErrors'][field]
          }
        }
      })
      return { ...state, dirtyFields }
    }

    return state
  },
  {}
)(translate(MatchEditView));
