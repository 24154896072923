

class Squad {
  private model : { [x:string]: any }
  constructor(squad : { [x:string]: any }){
    this.model = squad
  }
  public getInstance(){
    return {
      logo: this.getLogo(),
    }
  }
  getLogo(){
    return `${process.env.REACT_APP_MEDIA_URL}/stats/logos/${this.model.contestant_id}.png`
  }

}

export default Squad
