import React from 'react'
import { AtomicBlockUtils} from 'draft-js'

const VIDEOTYPE = 'draft-js-video-plugin-video';
const ATOMIC = 'atomic';

type EmbedProps = {
  blockProps: any
  theme: any
  contentState: any
}
const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';
const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
const utils = {
  isYoutube: (url:string) => YOUTUBEMATCH_URL.test(url),
  isVimeo: (url:string) => VIMEOMATCH_URL.test(url),
  getYoutubeSrc: (url:string) => {
    //@ts-ignore
    const id = url && url.match(YOUTUBEMATCH_URL)[1];
    return {
      srcID: id,
      srcType: 'youtube',
      url,
    };
  },
  getVimeoSrc: (url:string) => {
    //@ts-ignore
    const id = url.match(VIMEOMATCH_URL)[3];
    return {
      srcID: id,
      srcType: 'vimeo',
      url,
    };
  },
};

const getSrc = ({ src }: { [x:string]: string }) => {
  const { isYoutube, getYoutubeSrc, isVimeo, getVimeoSrc } = utils;
  if (isYoutube(src)) {
    const { srcID } = getYoutubeSrc(src);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (isVimeo(src)) {
    const { srcID } = getVimeoSrc(src);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  return undefined;
};

const Video = (props:EmbedProps) => {
  const { blockProps, theme } = props
  const src = getSrc(blockProps);
  if (src) {
    return (
      <div style={{}}>
        <div >
          <iframe
            src={src}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    );
  }

  return <div className={theme.invalidVideoSrc}>invalid video source</div>;
};

const addEmbed = (editorState:any, data:object) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    VIDEOTYPE,
    'IMMUTABLE',
    data
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    ' '
  );
  return newEditorState
}

export default () => {
  //@ts-ignore
  const ThemedVideo = props => <Video {...props}  />;
  return {
    blockRendererFn: (block:any, { getEditorState } : {[x:string]: any}) => {
        if (block.getType() === ATOMIC) {
          // TODO subject to change for draft-js next release
        const contentState = getEditorState().getCurrentContent();
          const entity = contentState.getEntity(block.getEntityAt(0));
          const type = entity.getType();
          const { src, width, height  } = entity.getData();
          if (type === VIDEOTYPE) {
            return {
              component: ThemedVideo,
              editable: false,
              props: {
                src,
                width,
                height 
              },
            };
          }
        }
  
        return null;
      },
    addEmbed
  }
}