import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { List, Datagrid, TextField, SelectField, EditButton } from 'react-admin';
import ExpandableMenu from '../../components/SocialSharing';
import PubDateField from '../../fields/CustomDateField';
import ContentFilter, { getDefaultFilterLang } from '../../filters/ContentFilter';



const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  editBtn:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem",
    color: "#fff",
    backgroundColor: "#25A9FE",
    '& svg': { 
      marginLeft: 7,
      fontSize: 16,
      color: "#fff",
    },
    '&:hover': {
      color: "#25A9FE",
      backgroundColor: "#f1f1f1",
      '& svg': { 
        color: "#25A9FE",
      }
    }
  },
}


const ArticleList = (props: any) => {
  const classes = props.classes;
  const filterDefaultValues = {
    lang: getDefaultFilterLang()
  }


  const historyPush = (path:string, data:any) => {
    props.history.push(path, { state: { data } });
    window.history.pushState(
      { defaultValue: data },
      "",
      path
    );
    
  }
  
  return(
    <div>
      <List
        {...props} title="قائمة المقالات" perPage={15}
        sort={{ field: 'pub_date', order: 'DESC' }} filters={<ContentFilter />}
        exporter={false} filterDefaultValues={filterDefaultValues}
      >
        <Datagrid>
          <PubDateField source='pub_date' label="تاريخ النشر" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} />
          <TextField source="title" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} />
          <TextField source="author.username" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} /> 
          <SelectField source="is_important" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}
            choices={[
              { id: true, name: 'نعم' },
              { id: false, name: 'لا' },
            ]} 
          />
          <TextField source="country.name_ar" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} />
          <SelectField source="status" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}
            choices={[
              { id: 1, name: 'في انتظار الموافقة' },
              { id: 2, name: 'منشور' },
              { id: 3, name: 'ينشر لاحقا' },
            ]} 
          />
          <EditButton
            resource="articles"
            basePath={"articles"}
            identifier="article_id"
            label="تعديل"
            className={classes.editBtn}
            headerClassName={classNames(classes.tableHeader,classes.alignRight)}
          />
          <ExpandableMenu historyPush={historyPush}/>
          
        </Datagrid>
        
      </List>
      
    </div>
)};

const ArticleListComponent = withStyles(styles)(ArticleList)

export default ArticleListComponent