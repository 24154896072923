import React from 'react';
import { connect } from 'react-redux';
import { Edit, SimpleForm, FormDataConsumer, ImageField, SelectArrayInput } from 'react-admin';


const CustomTitle = (props:any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>تعديل جدول ترتيب {props.record.competition.name}</span>
  )
}

const StandingsEdit = (props: any) => {
  const { classes } = props
  
  return <Edit  {...props} title={<CustomTitle/>}>
      <SimpleForm handleSubmit={(Submit: any) => {
        if (props.dirtyFields && props.form) {
          const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, n: any) => {
            let key = n
            let value = props.form["record-form"].values[n]
            return { ...p, [key]: value }
          }, {})
          return Submit(dirtyValues)
        }
        return Submit
      }}>
          <ImageField source='logo' customLabel='شعار المسابقة'/>
          <FormDataConsumer>
              {
                (dataProps: any) => {
                  if(! dataProps.record.updated_keys ){
                    return null
                  }
                  return (
                    <SelectArrayInput
                      label="updated_keys"
                      source={"updated_keys"}
                      choices={dataProps.record.updated_keys.map((k:string) => ({ id: k, name: k}))}
                    />
                  )
                }
              }
            </FormDataConsumer>
      </SimpleForm>
  </Edit>
};

export default connect(
  (state: any, props: any) => {
    

    if (state && state.form && state.form['record-form']) {
      const recordForm = state.form['record-form']
      const { initial: { person: initialPerson = [] } = {} } = recordForm
      const { values: { person: valuesPerson = [] } = {} } = recordForm
      let dirtyFields = {}
      if(initialPerson != valuesPerson) {
        dirtyFields = { person: {visited: true, touched: true} }
      }
      
      if(recordForm.anyTouched) {
        dirtyFields = { ...dirtyFields, ...recordForm.fields }
      }
      return { ...state, dirtyFields }
      
    }
    
    return state
  }, {} )(StandingsEdit)