import React from 'react';
import { List, Datagrid, TextField, Filter, FunctionField, TextInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import PubDateField from '../../fields/CustomDateField';

const styles = {
    tabField: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".74rem",
    },
    alignRight: {
        textAlign: 'right' as 'right'
    },
    tableHeader: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".8rem",
        fontWeight: 700,
    }
}

const Filters = (props:any) => {
    return (
        <Filter {...props}>
            <TextInput
                label="انتقل من"
                source="from"
                alwaysOn
            />
            <TextInput
                label="انتقل الى"
                source="to"
                alwaysOn
            />
            <TextInput
                label="اللاعب"
                source="player"
                alwaysOn
            />
        </Filter>
    )

}


const Transfer = (props: any) => {
    const classes = props.classes;
    return (
        <List {...props} title="انتقالات اللاعبين" filters={<Filters />} exporter={false}>
            <Datagrid rowClick="edit">
                <FunctionField
                    label=""
                    render={
                        (record:any) => <Avatar src={`https://images.elbotola.com/stats/players/${record.person.id}.png`} />
                    }
                    />
                <TextField source="person.match_name" label="اسم اللاعب" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="from_contestant_name" label="انتقل من" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="contestant_name" label="انتقل الى" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="transfer_type" label="نوع الانتقال" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <PubDateField source="announced_date" label="التاريخ المعلن" format="DD/MM/YYYY" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
            </Datagrid>
        </List>
    )
};

const TransferView = withStyles(styles)(Transfer)

export default TransferView