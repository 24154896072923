import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from "react-admin";
import { DateInput } from "react-admin-date-inputs";
import { withStyles } from "@material-ui/core/styles";
import AnalyticsCountrySearchInput from '../../inputs/AnalyticsCountrySearchInput'

const styles = {
  inputLabel: {
    "& label": {
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".74rem",
    },
    "& input": {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".84rem",
    },
  },
  saveBtn: {
    "& svg": {
      marginLeft: "5px",
    },
  },
};

const PlayerCreate = (props: any) => {
  const classes = props.classes;

  return (
    <Create {...props}>
      <SimpleForm redirect='list' defaultValue={{ status: "active", gender: "Male"}} >
        <TextInput source="first_name" className={classes.inputLabel} validate={required()}/>
        <TextInput source="last_name" className={classes.inputLabel} validate={required()}/>
        <TextInput source="match_name" className={classes.inputLabel} validate={required()}/>

        <TextInput source="first_name_fr" className={classes.inputLabel} validate={required()}/>
        <TextInput source="last_name_fr" className={classes.inputLabel} validate={required()}/>
        <TextInput source="match_name_fr" className={classes.inputLabel} validate={required()}/>

        <TextInput source="first_name_en" className={classes.inputLabel} />
        <TextInput source="last_name_en" className={classes.inputLabel} />
        <TextInput source="match_name_en" className={classes.inputLabel} />

        <TextInput source="height" className={classes.inputLabel} />
        <TextInput source="weight" className={classes.inputLabel} />
        <SelectInput
          source="position"
          choices={[
            { id: "Defender", name: "مدافع" },
            { id: "Attacker", name: "مهاجم" },
            { id: "Midfielder", name: "لاعب وسط" },
            { id: "Goalkeeper", name: "حارس مرمى" },
            { id: "Unknown", name: "غير معروف" },
          ]}
        />
        <DateInput
          source="date_of_birth"
          options={{
            format: "YYYY-MM-dd",
          }}
        />
        <SelectInput
          source="foot"
          choices={[
            { id: "left", name: "القدم اليسرى" },
            { id: "right", name: " القدم اليمنى" },
            { id: "both", name: "كلتا القدمين" },
          ]}
        />
        <SelectInput
          source="gender"
          choices={[
            { id: "Male", name: "ذكر" },
            { id: "Female", name: "انثى" },
          ]}
        />
        <SelectInput
          source="status"
          choices={[{ id: "active", name: "نشيط" }]}
        />
        <AnalyticsCountrySearchInput label="بلد الميلاد" name="country" />
        <AnalyticsCountrySearchInput label="الجنسية" name="nationality" />
      </SimpleForm>
    </Create>
  );
};

const PlayerCreateForm = withStyles(styles)(PlayerCreate);

export default PlayerCreateForm;
