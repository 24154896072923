import React from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    avatar: {
        width: 25,
        height: 25,
    },
};

const AvatarView = ({ user, classes } : {[ x:string]: any }) => (
    <MuiAvatar
        className={classes.avatar}
        src={
            user
                ? `https://www.gravatar.com/avatar/blaaah?d=retro`
                : `https://www.gravatar.com/avatar/?d=mp`
        }
    />
);

const Avatar = withStyles(styles)(AvatarView);

export default Avatar;