import { CRUD_CREATE_FAILURE } from "react-admin";
import { stopSubmit } from 'redux-form';
import { put, takeEvery } from "redux-saga/effects";

type Action = {
  payload: any;
}
export function* errorSagas() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudCreateFailure);
}

export function* crudCreateFailure(action: Action) {
  var json = action.payload;
  // json structure looks like this:
  // {
  //     username: "This username is already taken",
  //     age: "Your age must be above 18 years old"
  // }
  console.log('json', json)
  yield put(stopSubmit('record-form', json));
}