import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import Public from '@material-ui/icons/Public';

const styles = {
  link:{
    textDecoration: "none",
    display:"block",
    margin: "8px 15px",
  },
  roundBtn:{
    borderRadius:5,
  },
  button:{
    boxShadow: "none",
  },
  icon:{
    fontSize:18,
  }
}



const PreviewButton = (props: any) => {
  const classes = props.classes;
  const stopPropagation = (e:any) => {
    e.stopPropagation();
  }

  const getHref = () => {
    return props.record.absolute_url || props.record.link
  }

  return(
    <a href={ getHref() } target="_blank" className={ classes.link } onClick={stopPropagation}>
      <Button size="small" variant="contained" color="primary" className={classes.button}>
        <Public className={ classes.icon }/>
      </Button>
    </a>
  )
}


const PreviewButtonComponent = withStyles(styles)(PreviewButton)

export default PreviewButtonComponent;
