import React from 'react';
import { Create, SimpleForm, NumberInput, TextInput, ImageInput, ImageField, SelectInput, required  } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ColorInput } from 'react-admin-color-input';
import TagsInput from '../../inputs/TagsInputs';


  const styles = {
    tabLabel:{
      background:"blue",
    },
    inputLabel: {
      '& label':{
        transformOrigin: "top right",
        right: 0,
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".74rem",
      },
      '& input':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".84rem",
      }
    },
    coloredLabel: {
      '& label':{
        fontWeight:700,
      }
    },
    boldText:{
      fontWeight: 700,
      color: "#687c8c",
      '& input':{
        color: "#687c8c",
      }
    },
    txtColor:{
      color:"red",
    },
    radioBtn:{
      '& label':{
        flexDirection: "row-reverse" as 'row-reverse',
        justifyContent: "flex-end",
        margin: 0,
        '&>span:first-child':{
          direction:"ltr" as 'ltr',
        },
        '&>span:last-child':{
          width: 100,
          display: "inline-block",
        }
      },
      '& span':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      }
    },
  
    tagsList:{
      '& p':{
        display:"none",
      },
      '& li':{
        marginBottom: 30,
        borderBottom: "none",
      }
    },
    test:{
      background:"red",
    }
  }
const ChatroomsCreate = (props: any) => {
  const [defaultValues, setDefaultValues] = React.useState({});
  const classes = props.classes;
  return(
    <Create {...props} title="إنشاء صالة دردشة">
      <SimpleForm
        defaultValue={defaultValues} 
        onChange={(e:any) => {
          if(e.target && e.target.name == "match_id" && e.target.value){
            const id = e.target.value
            const request = new Request(`
              ${process.env.REACT_APP_AUTH_ENDPOINT}/${process.env.REACT_APP_MATCHES_RESOURCE}/${id}`, 
              {
                method: 'GET',
                credentials: 'include'
              }
            )
            fetch(request).then(response => (
              response.ok ? response.json() : null
            ))
            .then(response => {
              if(!response) { return }
              setDefaultValues({
                title: `دردشة مباراة ${response.team_A_name} Vs ${response.team_B_name}`,
                type: 2,
                w_image_url: `http:${response.cover.default_path}`,
                ordering: 0,
                team_A_id: response.team_A_id,
                team_A_name: response.team_A_name,
                team_B_id: response.team_B_id,
                team_B_name: response.team_B_name,
              })
            })
          }
        }}
      >
        <TextInput source="title" className={ classes.inputLabel } validate={required()}/>
        <TextInput source="opening_message" />
        <TextInput source="match_id" className={ classes.inputLabel }/>
        <SelectInput source="type" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
              choices={[
                { id: 1, name: 'كلاسيكي' },
                { id: 2, name: 'مباراة' },
              ]} 
            />
        <ImageInput source="w_image">
          <ImageField source="default_path" title="title" />
        </ImageInput>
        <TextInput source="w_image_url" className={ classes.inputLabel }/>
        <TagsInput label="Tags" className={ classes.inputLabel }/>
        <NumberInput source="ordering" className={ classes.inputLabel }/>
        <TextInput source="team_A_id" className={ classes.inputLabel }/>
        <TextInput source="team_A_name" className={ classes.inputLabel }/>
        <ColorInput source="team_A_color"   className={ classes.inputLabel }/>
        <TextInput source="team_B_id" className={ classes.inputLabel }/>
        <TextInput source="team_B_name" className={ classes.inputLabel }/>
        <ColorInput source="team_B_color"   className={ classes.inputLabel }/>
      </SimpleForm>
    </Create>
  )
};

const ChatroomsCreateForm = withStyles(styles)(ChatroomsCreate)

export default ChatroomsCreateForm