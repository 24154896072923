import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux';
import { Title, crudGetList, crudGetOne, EditButton, Responsive } from 'react-admin';
// import "./styles.css";
import { Query } from 'react-admin';
import dayjs from "dayjs";
import classNames from 'classnames';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import StyledButton from '../../components/StyledButton';
import StarIcon from '@material-ui/icons/Star';
import { withRouter } from 'react-router-dom';


const faces = [
  "http://i.pravatar.cc/300?img=1",
  "http://i.pravatar.cc/300?img=2",
  "http://i.pravatar.cc/300?img=3",
  "http://i.pravatar.cc/300?img=4"
];

const MatchStatus = (props: {[x:string]: any}) => {
  switch(props.match.status){
    case 'Fixture':
      return (<div style={styles.matchStatusText}>على الساعة</div>)
    case 'Playing':
      switch(props.match.match_details.period_id){
        case 10:
          return <div style={styles.matchStatusText}>إستراحة</div>
        default:
          return (
            <div>
              <div style={styles.matchMinuteIcon}>
                <svg version="1.1" width="16" height="16" viewBox="0 0 24 24" fill="#a1a2a5">
                  <path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                </svg>
              </div>
              <span style={styles.matchMinute}>'{props.match.match_details.match_time}</span>
            </div>
          )
    }
    case 'Played':
      return (<div style={styles.matchStatusText}>نهاية المباراة</div>)
    case 'Postponed':
      return (<div style={styles.matchStatusText}>مؤجل</div>)
    case 'Cancelled':
      return (<div style={styles.matchStatusText}>ألغيت</div>)
    case 'Suspended':
      return (<div style={styles.matchStatusText}>موقوفة</div>)
    default:
      return (<div>------</div>)
    
  }
}

const MatchScore = (props: {[x:string]: any}) => {
  return(<div style={styles.matchScoreWrapper}>
    <TeamScore match={props.match} team="B" winner={props.winner} />
    -
    <TeamScore match={props.match} team="A" winner={props.winner} />
  </div>)
}

const TeamScore = (props: {[x:string]: any}) => {
  return (
    <span style={{...styles.matchScoreInput, ...winnerScoreStyle(props.team, props.match.winner)}} >
      {props.match[`ets_${props.team}`] | props.match[`fs_${props.team}`] | 0}
    </span>
  )
}

const winnerScoreStyle = (team: any, winner: any) => {
  if(`team_${team}` === winner){
    return styles.matchWinner
  }
}

const MatchDate = (props: {[x:string]: any}) => {
  return (<div style={{...styles.matchScoreWrapper, fontWeight: 700 }}>
    {props.time}
  </div>
  )
}
const getCoverageLabel = (coverage:any) => {
  let labels = []
  if (coverage && coverage.mlive === 1) {
    labels.push(
      <div style={{ fontWeight: 'bold', color: "green" }}>
        معطيات آنية
      </div>
    )
  }
  else {
    labels.push(
      <div style={{ fontWeight: 'bold', color: "red" }}>
         معطيات غير آنية
      </div>
    )
  }

  if (coverage && coverage.lineup === 1) {
    labels.push(
      <div style={{ fontWeight: 'bold', color: "green" }}>
        مع التشكيلة
      </div>
    )
  }
  else {
    labels.push(
      <div style={{ fontWeight: 'bold', color: "red" }}>
        بدون التشكيلة
      </div>
    )
  }
  return labels

}

function EventItemView(props:any) {
  const { classes } = props
  const onGenerateClick = async () => {
    const { match_id }  = props.event
    const response = await fetch(`${process.env.REACT_APP_AI_API_ENDPOINT}/generate/from_match/?match_id=${match_id}`)
    let results = await response.json()
    props.history.push(`#/articles/create`, { state: {results} })
    window.history.pushState({defaultValue: results}, '', `#/articles/create`)
  }

  const showGenerate = () => {
    return false
    // const {
    //   match_details: { match_status },
    //   coverage_level,
    //   competition: { competition_format }
    // } = props.event
    // return  match_status === "Played" && coverage_level >= 8 && competition_format === "Domestic league"
  }

  return (
      <Card elevation={0} className={classes.card}>
        <CardContent className={classes.matchWrapper}>

            <div style={styles.middleSection}>
                <Typography
              variant={"subheading"}>درجة التغطية : {
                getCoverageLabel(props.event.coverage)
              }</Typography>
            </div>

            <Typography
              className={classNames(classes.teamAName)}
              gutterBottom
              variant={"subheading"}
            >
              <a href={props.event.getTeamA().resourceURL} className={classes.teamLink}>
              {props.event.getTeamA().name}
              </a>
            </Typography>


            <div style={styles.teamLogo} >
              <a href={props.event.getTeamA().resourceURL}>
                <img style={styles.teamImg} src={props.event.getTeamA().logo} />
              </a>
            </div>
            

            <div style={styles.middleSection}>
              <a href={`#/${process.env.REACT_APP_MATCH_RESOURCE}/${props.event.match_id}`} className={classes.matchLink}>
                <MatchStatus match={props.event} />
                {( ['Playing', 'Played', 'Suspended'].indexOf(props.event.status) > -1) && (
                  <MatchScore match={props.event} />
                )}
                {props.event.status === "Fixture" && (
                    <MatchDate time={props.event.time} />
                  )
                }
              </a>
            </div>


            <div style={styles.teamLogo} >
              <a href={props.event.getTeamB().resourceURL}>
                <img style={styles.teamImg} src={props.event.getTeamB().logo} />
              </a>
            </div>


            <Typography
              className={classNames(classes.teamBName)}
              gutterBottom
              variant={"subheading"}
            >
              <a href={props.event.getTeamB().resourceURL} className={classes.teamLink}>
              {props.event.getTeamB().name}
              </a>
            </Typography>



            <Responsive
              small={null}
              medium={null}
              large={
                <Fragment>
                  {
                    showGenerate() && (
                      <div style={{position: "absolute" as 'absolute', left: 5, margin: 0}}>
                        <StyledButton label="generate" onClick={onGenerateClick}/>
                      </div>
                    )
                  }
                   
                  <EditButton
                    resource={process.env.REACT_APP_MATCH_RESOURCE}
                    basePath={`/${process.env.REACT_APP_MATCH_RESOURCE}`}
                    identifier="match_id"
                    record={props.event}
                    label="تعديل"
                    className={classes.editBtn}
                  />
                  <div style={{position: "absolute" as 'absolute', left: 75, margin: 0,}}>
                    <PreviewButtonComponent style={{ marginRight: "15px"}} record={props.event} /> 
                  </div>

                 
                  
                </Fragment>
              }
            />
            

            {/* <Grid item xs={1}>
              <Grid container justify="center">
                <Typography
                  className={classes.text}
                  gutterBottom
                  variant={"subheading"}
                >
                  {props.event.fs_A}
                </Typography>
                <Typography
                  gutterBottom
                  variant={"subheading"}
                >
                  {"  -  "}
                </Typography>
                <Typography
                  gutterBottom
                  variant={"subheading"}
                >
                  {props.event.fs_B}                  
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container justify="flex-start">
                <Avatar className={classes.avatar} key={4} src={props.event.team_B_logo} />
                <Typography
                  gutterBottom
                  variant={"subheading"}
                >
                  {props.event.team_B_name}                  
                </Typography>
              </Grid>
            </Grid>
          <EditButton
            resource="analytics/matches"
            basePath={"/analytics/matches"}
            identifier="match_id"
            record={props.event} /> */}
        </CardContent>
      </Card>
  );
}

const styles= {
  teamWrapper:{
    display:"flex",
    alignItems: "center",
  },
  firstChild: {
    textAlign:"left",
  },
  teamLogo:{
    maxWidth:"30px",
    margin: "0 .6rem",
  },
  middleSection:{
  },
  teamImg:{
    width:"100%",
  },
  matchMinute:{
    display:"inline-block",
    marginRight:"3px",
    verticalAlign: "top",
    fontSize: ".9rem",
    color: "rgb(81, 212, 104)",
    fontWeight: 700,
  },
  matchMinuteIcon:{
    color: "rgb(81, 212, 104)",
    display:"inline-block",
    verticalAlign:"top",
  },
  matchScoreWrapper:{
    fontSize: "1.25rem",
    color: "rgb(161, 162, 165)",
    textAlign: "center" as 'center',
  },
  matchScoreInput:{
    margin: '0 6px',
    fontWeight: 700,
  },
  matchWinner:{
    color: "rgb(81, 212, 104)"
  },
  matchStatusText:{
    verticalAlign: "top",
    fontSize: "0.65rem",
    color: "rgb(161, 162, 165)",
    lineHeight: "125%",
    textAlign: 'center' as 'center',
  }
}

const EventItem = withStyles(
  (muiBaseTheme: {[x:string]: any}) => ({
    teamAName:{
      width:"22%",
      overflow: "hidden",
      whiteSpace: 'nowrap' as 'nowrap',
      textOverflow: "ellipsis",
      fontWeight: 700,
      lineHeight: "180%",
      fontSize: ".8rem",
      color: "rgb(77, 92, 105)",
      textAlign: 'right' as 'right',
    },
    teamBName:{
      flexBasis:"22%",
      overflow: "hidden",
      whiteSpace: 'nowrap' as 'nowrap',
      textOverflow: "ellipsis",
      fontWeight: 700,
      lineHeight: "180%",
      fontSize: ".8rem",
      color: "rgb(77, 92, 105)",
    },
    teamLink:{
      textDecoration: "none",
      color: "rgb(77, 92, 105)",
    },
    matchLink:{
      textDecoration: "none",
    },
    card: {
      margin: "auto",
      borderRadius:0,
      // transition: "0.3s",
      // boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
      // "&:hover": {
      //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
      // }
    },
    media: {
      // paddingTop: "56.25%"
    },
    matchWrapper: {
      // textAlign: "left",
      display:"flex",
      padding :"12px 0 !important",
      alignItems: "center",
      justifyContent: "center",
      borderTop: 'solid 1px #f1f1f1',
    },
    divider: {
      margin: `${muiBaseTheme.spacing.unit * 3}px 0`
    },
    heading: {
      fontWeight: "bold"
    },
    subheading: {
      lineHeight: 1.8
    },
    avatar: {
      display: "inline-block",
      border: "2px solid gray",
      "&:not(:first-of-type)": {
        marginLeft: -muiBaseTheme.spacing.unit
      }
    },
    matchWinner:{
      color: "rgb(81, 212, 104)"
    },
    editBtn:{
      position: "absolute", 
      right:"160px",
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
      color: "#fff",
      backgroundColor: "#25A9FE",
      '& svg': { 
        marginLeft: 7,
        fontSize: 16,
        color: "#fff",
      },
      '&:hover': {
        color: "#25A9FE",
        backgroundColor: "#f1f1f1",
        '& svg': { 
          color: "#25A9FE",
        }
      }
    },
    viewBtn:{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
      color: "#fff",
      backgroundColor: "#25A9FE",
      boxShadow: "none",
      '& svg': { 
        marginRight: 7,
        fontSize: 16,
        color: "#fff",
      },
      '&:hover': {
        color: "#25A9FE",
        backgroundColor: "#f1f1f1",
        '& svg': { 
          color: "#25A9FE",
        }
      }
    },
    link:{
      position: "absolute", 
      left:"100px",
      textDecoration: "none",
    }
  })
)(withRouter(EventItemView));

// export default connect(
//   state => state,
//   { crudGetOne }
// )(EventItem);

export default EventItem;