import React, { useState, useEffect } from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import MUIList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import { List, TextField, SelectField, ReferenceField, FunctionField, DateField } from "react-admin";
import JobModel from '../../../models/Job'
import { StopJobExecutionBotton } from '../../../components/SchedulerActionButtons'
import dayjs from 'dayjs';


const formattedTimeDelta = (startTime: any, endTime: any) => {
    let diff = dayjs(endTime).diff(startTime, 'second')
    var h = Math.floor(diff / 3600);
    var m = Math.floor(diff % 3600 / 60);
    var s = Math.floor(diff % 3600 % 60);

    var hDisplay = h > 0 ? h + "h " : "";
    var mDisplay = m > 0 ? m + "m " : "";
    var sDisplay = s > 0 ? s + "s " : "";
    return hDisplay + mDisplay + sDisplay;
}



const JobExcutionsTableWrapper = (props: any) => {
    return (
        <Paper>
            <Table>
                <TableHead>
                    {props.head}
                </TableHead>
                <TableBody>
                    {props.body}
                </TableBody>
            </Table>
        </Paper>
    )
}

const PendingJobExecutionsTableBody = (props: any) => {
    const runtimeRender = (record: any) => formattedTimeDelta(record.create_time, new Date())
    const { records, basePath } = props
    return records.map((record: any) => (
        <TableRow key={record.id}>
            <TableCell>
                <TextField source="id" record={record} />
            </TableCell>
            <TableCell>
                <ReferenceField source="job_instance.job_instance_id" resource="job_executions" reference="jobs" record={record} basePath={basePath}>
                    <TextField source="spider.spider_name" />
                </ReferenceField>
            </TableCell>
            <TableCell>
                <TextField source="project.project_name" record={record} />
            </TableCell>
            <TableCell>
                <TextField source="job_instance.spider_arguments" record={record} />
            </TableCell>
            <TableCell>
                <SelectField
                    source="job_instance.priority"
                    record={record}
                    choices={Object.keys(JobModel.PRIORITY).map((key) => ({ id: JobModel.PRIORITY[key], name: key }))}
                />
            </TableCell>
            <TableCell>
                <FunctionField render={runtimeRender} record={record} />
            </TableCell>
            <TableCell>
                <StopJobExecutionBotton record={record} />
            </TableCell>
        </TableRow>
    ))
}

const RunningJobExecutionsTableBody = (props: any) => {
    const runtimeRender = (record: any) => formattedTimeDelta(record.start_time, new Date())
    const { records, basePath } = props
    return records.map((record: any) => (
        <TableRow key={record.id}>
            <TableCell>
                <TextField source="id" record={record} />
            </TableCell>
            <TableCell>
                <ReferenceField source="job_instance.job_instance_id" resource="job_executions" reference="jobs" record={record} basePath={basePath}>
                    <TextField source="spider.spider_name" />
                </ReferenceField>
            </TableCell>
            <TableCell>
                <TextField source="project.project_name" record={record} />
            </TableCell>
            <TableCell>
                <TextField source="job_instance.spider_arguments" record={record} />
            </TableCell>
            <TableCell>
                <SelectField
                    source="job_instance.priority"
                    record={record}
                    choices={Object.keys(JobModel.PRIORITY).map((key) => ({ id: JobModel.PRIORITY[key], name: key }))}
                />
            </TableCell>
            <TableCell>
                <FunctionField render={runtimeRender} record={record} />
            </TableCell>
            <TableCell>
                <DateField source="start_time" record={record} showTime options={{ hour12: false }} />
            </TableCell>
            <TableCell>
                <a href={`#${process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE}/${record.id}/log`} target="_blank">
                    Logs
                </a>
            </TableCell>
            <TableCell>
                <TextField source="running_on" record={record} />
            </TableCell>
            <TableCell>
                <StopJobExecutionBotton record={record} />
            </TableCell>
        </TableRow>
    ))
}

const FinishedJobExecutionsTableBody = (props: any) => {
    const runtimeRender = (record: any) => formattedTimeDelta(record.start_time, record.end_time)
    const { records, basePath } = props
    return records.map((record: any) => (
        <TableRow key={record.id}>
            <TableCell>
                <TextField source="id" record={record} />
            </TableCell>
            <TableCell>
                <ReferenceField source="job_instance.job_instance_id" resource="job_executions" reference="jobs" record={record} basePath={basePath}>
                    <TextField source="spider.spider_name" />
                </ReferenceField>
            </TableCell>
            <TableCell>
                <TextField source="project.project_name" record={record} />
            </TableCell>
            <TableCell>
                <TextField source="job_instance.spider_arguments" record={record} />
            </TableCell>
            <TableCell>
                <SelectField
                    source="job_instance.priority"
                    record={record}
                    choices={Object.keys(JobModel.PRIORITY).map((key) => ({ id: JobModel.PRIORITY[key], name: key }))}
                />
            </TableCell>
            <TableCell>
                <FunctionField render={runtimeRender} record={record} />
            </TableCell>
            <TableCell>
                <DateField source="start_time" record={record} showTime options={{ hour12: false }} />
            </TableCell>
            <TableCell>
                <a href={`#${process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE}/${record.id}/log`} target="_blank">
                    Logs
                </a>
            </TableCell>
        </TableRow>
    ))
}

const PendingJobExecutionsTableHead = () => (
    <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Job</TableCell>
        <TableCell>Project</TableCell>
        <TableCell>Args</TableCell>
        <TableCell>Priority</TableCell>
        <TableCell>Wait</TableCell>
        <TableCell>Action</TableCell>
    </TableRow>
)
const RunningJobExecutionsTableHead = () => (
    <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Spider</TableCell>
        <TableCell>Project</TableCell>
        <TableCell>Args</TableCell>
        <TableCell>Priority</TableCell>
        <TableCell>Runtime</TableCell>
        <TableCell>Started</TableCell>
        <TableCell>Log</TableCell>
        <TableCell>Running On</TableCell>
        <TableCell>Action</TableCell>
    </TableRow>
)
const FinishedJobExecutionsTableHead = () => (
    <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Spider</TableCell>
        <TableCell>Project</TableCell>
        <TableCell>Args</TableCell>
        <TableCell>Priority</TableCell>
        <TableCell>Runtime</TableCell>
        <TableCell>Started</TableCell>
        <TableCell>Log</TableCell>
    </TableRow>
)


const JobExcutionsTable = (props: any) => {
    const { records, runningStatus, basePath } = props

    const getTableBody = () => {
        // return null
        switch (runningStatus) {
            case JobModel.RUNNING_STATUS['PENDING']:
                return <PendingJobExecutionsTableBody records={records} basePath={basePath} />
            case JobModel.RUNNING_STATUS['RUNNING']:
                return <RunningJobExecutionsTableBody records={records} basePath={basePath} />
            case JobModel.RUNNING_STATUS['FINISHED']:
                return <FinishedJobExecutionsTableBody records={records} basePath={basePath} />
            case JobModel.RUNNING_STATUS['CANCELED']:
                return <FinishedJobExecutionsTableBody records={records} basePath={basePath} />
            default:
                break;
        }
    }
    const getTableHead = () => {
        switch (runningStatus) {
            case JobModel.RUNNING_STATUS['PENDING']:
                return <PendingJobExecutionsTableHead />
            case JobModel.RUNNING_STATUS['RUNNING']:
                return <RunningJobExecutionsTableHead />
            case JobModel.RUNNING_STATUS['FINISHED']:
                return <FinishedJobExecutionsTableHead />
            case JobModel.RUNNING_STATUS['CANCELED']:
                return <FinishedJobExecutionsTableHead />
            default:
                break;
        }
    }

    return <JobExcutionsTableWrapper body={getTableBody()} head={getTableHead()} />
}



const JobExecutionGrid = (props: any) => {
    const { ids, data, basePath } = props
    const [openedStatusList, setOpenedStatusList]: any = useState([])
    const [groupedData, setGroupedData]: any = useState({})

    useEffect(() => {
        const gd = ids.reduce((accumulator: any, currentId: any) => {
            const { running_status } = data[currentId]
            if (accumulator[running_status]) {
                accumulator[running_status] = [...accumulator[running_status], data[currentId]]
            }
            else {
                accumulator = { ...accumulator, [running_status]: [data[currentId]] }
            }
            return accumulator

        }, {})
        console.log(666, gd)
        setGroupedData(gd)
    }, [ids])

    const onStatusGroupClick = (status: any) => {
        const statusId = JobModel.RUNNING_STATUS[status]
        if (openedStatusList.indexOf(statusId) != -1) {
            setOpenedStatusList(openedStatusList.filter((s: any) => s != statusId))
        }
        else {
            setOpenedStatusList([...openedStatusList, statusId])
        }
    }

    const getRecords = (status: any) => (
        groupedData[status] ? groupedData[status] : []
    )



    return (
        <MUIList
            component="nav"
        >

            {
                Object.keys(JobModel.RUNNING_STATUS).map((status) => {
                    let records = getRecords(JobModel.RUNNING_STATUS[status])
                    return (
                        <>
                            <ListItem button onClick={() => onStatusGroupClick(status)}>
                                <ListItemIcon>
                                    <Badge badgeContent={records.length} color="primary">
                                        <InboxIcon />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText inset primary={status} />
                                {openedStatusList.includes(JobModel.RUNNING_STATUS[status]) ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openedStatusList.includes(JobModel.RUNNING_STATUS[status])} timeout="auto" unmountOnExit>
                                <MUIList disablePadding>
                                    <JobExcutionsTable records={records} runningStatus={JobModel.RUNNING_STATUS[status]} basePath={basePath} />
                                </MUIList>
                            </Collapse>
                        </>
                    )
                })
            }
        </MUIList>
    )

};
JobExecutionGrid.defaultProps = {
    data: {},
    ids: [],
};

const JobExecutionList = (props: any) => (
    <List title="All job executions" {...props} perPage={100}>
        <JobExecutionGrid />
    </List>
);

export default JobExecutionList
