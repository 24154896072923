import React from 'react';
import { BooleanInput } from 'react-admin';
import { Field } from 'redux-form';
import { translate } from 'react-admin';
import { Paper, withStyles } from '@material-ui/core';
import { addField } from 'react-admin';

const styles = (theme:any) => ({
  control: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    maxWidth: 200
  }
});

const EBooleanInput = (props:any) => {
  return <Paper className={props.classes.control}>
    <BooleanInput {...props}/>
  </Paper>
}

export default translate(withStyles(styles)(addField(EBooleanInput)))