function humanizeNumber(num: number) {
  let result = num.toString();
  let suffix = '';
  if (num >= 1000000) {
      result = (num / 1000000).toFixed(num >= 10000000 ? 1 : 2);
      suffix = 'M';
  }
  else if (num >= 1000) {
    result = (num / 1000).toFixed(1);
    suffix = 'K';
  }
  if (result.endsWith('.0')) {
    result = result.slice(0, -2);
  }
  return result + suffix + '€'
}

class Transfer {
  private model: { [x: string]: any }
  constructor(player: { [x: string]: any }) {
    this.model = player
  }
  public getInstance() {
    let personName = this.model.person.match_name
    if (!personName) {
      personName = `${this.model.person.first_name} ${this.model.person.last_name}`
    }
    return {
      announced_date: this.model.announced_date * 1000,
      from: [
        {
          object_id: this.model.from_contestant_id,
          name: this.model.from_contestant_name,
          content_type: "team",
        }
      ],
      to: [
        {
          object_id: this.model.contestant_id,
          name: this.model.contestant_name,
          content_type: "team",
        }
      ],
      inPerson: [
        {
          object_id: this.model.person.id,
          name: personName,
          content_type: "player",
        }
      ]
    }
  }


  transformFields() {
    if (this.model['from']) {
      this.model['from_contestant_id'] = this.model['from'][0].object_id
      this.model['from_contestant_name'] = this.model['from'][0].name
      this.model['from_contestant_name_fr'] = this.model['from'][0].name_fr
      this.model['from_contestant_name_en'] = this.model['from'][0].name_en
      delete this.model['from']
    }
    if (this.model['to']) {
      this.model['contestant_id'] = this.model['to'][0].object_id
      this.model['contestant_name'] = this.model['to'][0].name
      this.model['contestant_name_fr'] = this.model['to'][0].name_fr
      this.model['contestant_name_en'] = this.model['to'][0].name_en
      delete this.model['to']
    }
    if (this.model['inPerson']) {
      this.model['person'] = {
        id: this.model['inPerson'][0].object_id,
        match_name: this.model['inPerson'][0].name,
        match_name_fr: this.model['inPerson'][0].name_fr,
        match_name_en: this.model['inPerson'][0].name_en,
      }
      delete this.model['inPerson']
    }
    if (this.model['value']) {
      this.model['humanized_value'] = humanizeNumber(this.model['value'])
    }
    return { ...this.model }
  }
}

export default Transfer
