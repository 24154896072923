import React from 'react';
import { List, Datagrid, TextField, ImageField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';


const styles = {
    tabField: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".74rem",
    },
    alignRight: {
        textAlign: 'right' as 'right'
    },
    tableHeader: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".8rem",
        fontWeight: 700,
    },
    compImg: {
        width: 50,
        '& img': {
            width: "100%",
        }
    }
}



const Standings = (props: any) => {
    const classes = props.classes;
    return (
        <List {...props} title="جداول الترتيب"  exporter={false} actions={<></>}>
            <Datagrid rowClick="edit">
                <ImageField source='logo' customLabel='شعار المسابقة' className={classNames(classes.compImg)}/>
                <TextField source="competition.name" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
            </Datagrid>
        </List>
    )
};

const StandingsList = withStyles(styles)(Standings)

export default StandingsList