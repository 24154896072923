import React from 'react';
import { Toggle } from 'react-powerplug'
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { translate } from 'react-admin';

const styles = (theme: any) => ({
  spreadsheetButtonToggle: {
    height: 200
  }
});


const LiveSpreadsheet = (props: any) => {
  const { classes } = props
  return <Toggle initial={false}>
    {({ on, toggle }) => (
      <>
        <Grid
          className={!on && classes.spreadsheetButtonToggle}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button variant="contained" onClick={toggle}>
            {on ?
              props.translate(`resources.${process.env.REACT_APP_ARTICLE_RESOURCE}.form.liveSpreadsheet/hideButton`)
              : props.translate(`resources.${process.env.REACT_APP_ARTICLE_RESOURCE}.form.liveSpreadsheet/showButton`)
            }
          </Button>
        </Grid>
        {on &&
          <iframe
            src="https://docs.google.com/spreadsheet/ccc?key=1HmPNCd8S-LRO-zt5weqZvN6BVAb1LClw_uQMiiyFIq0&single=false&gid=0&widget=false&rm=minimal"
            height={250}
            width={"100%"}
            frameBorder="0"
          >
          </iframe>
        }
      </>
    )}
  </Toggle>
}

export default withStyles(styles)(translate(LiveSpreadsheet))