import React from "react";
import {
    SelectInput,
    SimpleFormIterator,
    ArrayInput,
    FormDataConsumer,
    NumberInput,
    RadioButtonGroupInput,
    required
} from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import SquadPlayerSearchInput from '../inputs/SquadPlayerSearchInput';


const styles = {
    cardsList: {
        width: "100%",
        '& p': {
            display: "none",
        },
        '& li': {
            marginBottom: 30,
            borderBottom: "none",
        }
    },
    root: {
        marginTop: 30,
        '&>div': {
            display: 'flex' as 'flex',
            flexWrap: 'wrap' as 'wrap',
            width: "100%",
        },
        '& div': {
            width: "100%",
        },
        // exclude Add BTN from being impacted by Remove BTN custom styling
        '&>li': {
            '&>span': {
                position: "initial" as 'initial',
                '& button': {
                    padding: "7px 8px",
                    '&>span': {
                        width: "inherit",
                        overflow: 'hidden',
                        '&>svg': {
                            padding: "auto",
                            left: "auto",
                            position: 'inherit' as 'inherit',
                        }
                    }
                }
            }
        }
    }, //wrapper
    line: {
        paddingRight: "16px",
        paddingLeft: "16px",
        width: "33.33%",
        boxSizing: "border-box" as 'border-box',
        position: "relative" as 'relative',

        // remove btn (within the iterator)
        '& >span': {
            position: "absolute" as 'absolute',
            left: 10,
            '& button': {
                padding: 0,
                '&>span': {
                    width: 20,
                    overflow: 'hidden' as 'hidden',
                    '&>svg': {
                        padding: 11,
                        left: -16,
                        position: 'relative' as 'relative',
                        color: "#cccccc",
                    }
                }
            }
        }
    }, //li
    form: {
        padding: 24,
        boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
        '& .ra-input': {
            marginTop: 16
        }
    }, //section
}



const MatchVarInput = (props: any) => {
    const classes = props.classes;

    return (
        <ArrayInput label="VAR" source="var" className={classes.cardsList}>
            <SimpleFormIterator classes={classes}>
                <FormDataConsumer>
                    {
                        (dataProps: any) => {
                            return (
                                <RadioButtonGroupInput
                                    style={{ width: '100%' }}
                                    classes={classes.radioChoices}
                                    source={dataProps.getSource("contestant_id")}
                                    label="الفريق" choices={[
                                        { id: dataProps.formData.contestant[0].id, name: dataProps.formData.contestant[0].name },
                                        { id: dataProps.formData.contestant[1].id, name: dataProps.formData.contestant[1].name },
                                    ]}
                                    options={{
                                        row: 'true',
                                        classes: classes.radioChoices
                                    }}
                                    validate={required()}
                                />
                            )
                        }

                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {(dataProps: any) => {
                        const selectedCtst = dataProps.scopedFormData.contestant_id
                        return (
                            <SquadPlayerSearchInput
                                name={dataProps.getSource("player")}
                                squad={props.squads[selectedCtst]}
                                label="اللاعب"
                                fullWidth
                                required={true}
                            />
                        )
                    }

                    }
                </FormDataConsumer>

                <SelectInput
                    label="type"
                    source="type"
                    choices={[
                        { id: "Goal awarded", name: 'Goal awarded' },
                        { id: "Goal not awarded", name: 'Goal not awarded' },
                        { id: "Penalty awarded", name: 'Penalty awarded' },
                        { id: "Penalty not awarded", name: 'Penalty not awarded' },
                        { id: "Red card given", name: 'Red card given' },
                        { id: "Card upgrade", name: 'Card upgrade' },
                        { id: "Mistaken Identity", name: 'Mistaken Identity' },
                        { id: "Other", name: 'Other' },
                    ]}
                    validate={required()}
                />
                <SelectInput
                    label="decision"
                    source="decision"
                    choices={[
                        { id: "Confirmed", name: 'Confirmed' },
                        { id: "Cancelled", name: 'Cancelled' },
                    ]}
                />
                <SelectInput
                    label="outcome"
                    source="outcome"
                    choices={[
                        { id: "Goal", name: 'Goal' },
                        { id: "No goal", name: 'No goal' },
                        { id: "Penalty", name: 'Penalty' },
                        { id: "No penalty", name: 'No penalty' },
                        { id: "Red card", name: 'Red card' },
                        { id: "No red card", name: 'No red card' },
                        { id: "Card upgraded", name: 'Card upgraded' },
                        { id: "No change", name: 'No change' },
                        { id: "Ref cautions/sends off different player", name: 'Ref cautions/sends off different player' },
                        { id: "Player originally cautioned confirmed", name: 'Player originally cautioned confirmed' },
                    ]}
                />

                <NumberInput label="الدقيقة" source="time_min" validate={required()} />
                <SelectInput
                    label="شوط المباراة"
                    source={"period_id"}
                    choices={[
                        { id: 1, name: "شوط الأول" },
                        { id: 2, name: "شوط الثاني" },
                        { id: 3, name: "شوط الإضافي الأول" },
                        { id: 4, name: "شوط الإضافي الثاني" },
                    ]}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};



export default withStyles(styles)(MatchVarInput)
