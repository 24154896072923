import * as DraftConvert from 'draft-convert';
import React from 'react';
import { SPELLCHECKERROR } from './SpellCheckerButton';

function RGBToHex(rgb: any) {
  // Choose correct separator
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb
    .substr(4)
    .split(")")[0]
    .split(sep);

  let r = (+rgb[0]).toString(16),
    g = (+rgb[1]).toString(16),
    b = (+rgb[2]).toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
}

export const convertFromHTML = (html: any) => {
  const contentState = DraftConvert.convertFromHTML({
    //@ts-ignore
    htmlToStyle: (nodeName: string, node: HTMLElement, currentStyle) => {
      switch (nodeName) {
        case "span":
          if (node.style.color) {
            const color = RGBToHex(node.style.color).replace("#", "").toUpperCase();
            return currentStyle.add(`color-${color}`)
          }
        case "font":
          if (node.style.color) {
            const color = node.style.color.replace("#", "").toUpperCase()
            return currentStyle.add(`color-${color}`)
          }
        default:
          return currentStyle
      }
    },
    htmlToEntity: (nodeName: string, node: HTMLElement, createEntity) => {
      switch (nodeName) {
        case 'img':
          return createEntity(
            'IMAGE',
            'IMMUTABLE',
            { src: node.getAttribute('src')}
          )
        case 'a':
          if (node.className === "article_tag") {
            const mention = {
              url: node.getAttribute("href"),
              link: node.getAttribute("href"),
              class: node.className,
              "data-id": node.getAttribute("data-id"),
              "data-type": node.getAttribute("data-type"),
              "data-name": node.getAttribute("data-name")
            }
            return createEntity(
              'mention',
              'MUTABLE',
              //@ts-ignore
              { mention }
            )
          }
      case "div":
          const dataEmbedoUrl = node.attributes.getNamedItem("data-embedo-url")
          if (dataEmbedoUrl && dataEmbedoUrl.value) {
            const entity = createEntity(
              "draft-js-video-plugin-video",
              "IMMUTABLE",
              {
                src: dataEmbedoUrl.value,
                width: 200,
                height: 200
              })
            return entity
          }
        }
      },
    //@ts-ignore
    htmlToBlock: (nodeName, node) => {
      switch (nodeName) {
        case 'img':
          return 'atomic'
        case "div":
          const dataEmbedoUrl = node.attributes.getNamedItem("data-embedo-url")
          if (dataEmbedoUrl) {
            return 'atomic'
          }
      }
    }
  })(html);
  return contentState;
}

export const convertToHTML = (contentState:any) => {
  const html = DraftConvert.convertToHTML({
    styleToHTML: (style:string) => {
      const key = "color-";
      if(style.startsWith(key)){
        return <span style={{ color: style.replace(key, "#") }}/>;
      }
      if(style === SPELLCHECKERROR){
        return null
      }
    },
    //@ts-ignore
    blockToHTML: (block:any) => {
      //@ts-ignore
      if(!block || ! block.type){
        return null
      }
      switch(block.type){
        // case "unstyled":
        //   if(block.text === ""){
        //     return <p><br/></p>
        //   }
        //   break;
        // case "draft-js-video-plugin-video":
        //   if(block.data.src){
        //     return <span/>
        //   }
        //   break;
      }
    },
    entityToHTML: (entity, originalText) => {
      switch(entity.type){
        case "LINK":
          return {
            start: `<a href="${entity.data.url}" class="article_tag" >`,
            end: `</a>`,
          }
        case "IMAGE":
          if(entity.data){
            return <img className="medium-insert-images" src={entity.data.src} />
          }
        case "draft-js-video-plugin-video":
          if(entity.data){
            return <div data-embedo-url={entity.data.src}></div>
          }
          break;
        case "mention":
        case "link":
          if(entity.data){
            let data = entity.data
            if(entity.data.mention){
              data = entity.data.mention
            }
            return <a href={data.url} 
                      className="article_tag" 
                      data-id={data['data-id']} 
                      data-type={data['data-type']} 
                      data-name={'data-name'}>
                      {originalText}
                    </a>
          }
      }
      return originalText;
    }
  })(contentState);
  return html;
}