import React from 'react';
import { List, Datagrid, TextField, SelectField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import dayjs from "dayjs";
import PubDateField from '../../fields/CustomDateField';


const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  allBlue:{
    direction: 'ltr' as 'ltr'
  }
}


const NotificationList = (props: any) => {
  const classes = props.classes;
  return (
  <List {...props} exporter={false} title="قائمة الاشعارات">
    <Datagrid rowClick="edit">
      <PubDateField source="pub_date" unix={true} label="تاريخ النشر" headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
      <TextField source="title" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} />
      <TextField source="author.username" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} />
      <TextField source="country.name_ar" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} />
      <SelectField source="category" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
        choices={[
          { id: "article", name: 'مقال' },
          { id: "match", name: 'مباراة' },
          { id: "video", name: 'فيديو' },
          { id: "betting", name: 'توقعات' },
          { id: "chat", name: 'شات' },
          { id: "promotion", name: 'ترويج' },
        ]} 
      />
      
    </Datagrid>
    </List>
)};

const NotificationListComponent = withStyles(styles)(NotificationList)

export default NotificationListComponent