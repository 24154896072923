import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import _ from 'lodash';


const itemToString = (item:any)  => {
  return `${item.first_name} ${item.last_name} (${item.position})`
}

const styles = (theme:any) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    // maxWidth: 200
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    maxHeight: 250,
    overflowY: 'scroll',
    // maxWidth: 300
  },
  inputRoot: {
    display: 'block'
  },
  input: {
    width: '100%'
  }
})

function renderChipAvatar(item: any) {
  const src = `${process.env.REACT_APP_MEDIA_URL}/stats/players/${item.id}.png`
  return (<Avatar src={src} style={{ width: 30 }}/>)
}


function renderInput(inputProps:any) {
  const { InputProps, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps:any) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.player_id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {renderChipAvatar(suggestion)}
      {itemToString(suggestion)}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};





function DownshiftMultiple(props:any) {
  const { classes } = props
  const [inputValue, setInputValue] = React.useState('');
  const [query, setQuery] = React.useState('');
  const [suggestions, setSuggestions] = React.useState<any[]>([]);
  const [selectedItems, setSelectedItems] = React.useState(props.input.value || []);

  function getSuggestions(q: string) {
    if(q == query){ return suggestions }
    // if(q.length <= 3) { return }
    const request = new Request(`${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_PLAYER_RESOURCE}/?q=${q}`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include'
    })
    return fetch(request)
      .then(response => (response.json()))
      .then(response => {
        setQuery(q)
        setSuggestions(response.results ? response.results : [])
        return response
      })
  }
  const memoizedDebounce = useCallback(_.debounce(getSuggestions, 400), [])

  function handleKeyDown(event:any) {
    if (selectedItems.length && !inputValue.length && event.key === 'Backspace') {
      const newSelectedItems = selectedItems.slice(0, selectedItems.length - 1)
      setSelectedItems(newSelectedItems);
      invokeFieldEvents(newSelectedItems)
    }
  }

  function handleInputChange(event:any) {
    setInputValue(event.target.value);
    memoizedDebounce(event.target.value);
  }

  function handleChange(item:any) {
    item = {
      ...item,
      id: item.player_id,
      type: item.type_name,
      height: !isNaN(parseInt(item.height)) ? parseInt(item.height) : null,
      weight: !isNaN(parseInt(item.weight)) ? parseInt(item.weight) : null,
    }
    const keysToDrop = ['membership', 'type_name', 'player_id']
    keysToDrop.map((key: string) => delete item[key])
    let newSelectedItems: any = [...selectedItems];
    const exists = newSelectedItems.find((i:any) => (
      i.id == item.id
    ))
    if (!exists) {
      newSelectedItems = [...newSelectedItems, item];
    }
    setInputValue('');
    setSelectedItems(newSelectedItems);
    invokeFieldEvents(newSelectedItems)
  }

  const handleDelete = (item:any) => () => {
    const newSelectedItems: any = [...selectedItems];
    newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
    setSelectedItems(newSelectedItems);
    invokeFieldEvents(newSelectedItems)
  };

  const invokeFieldEvents = (newSelectedItems:Array<any>) => {
    props.input.onFocus(newSelectedItems)
    props.input.onChange(newSelectedItems)
    props.input.onBlur(newSelectedItems)
  }

  const { meta: { touched, error } } = props

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItems}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItems2,
        highlightedIndex,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: props.placeholder || props.translate('inputs.squad.placeholder'),
        });
        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              label: props.label,
              InputLabelProps: getLabelProps(),
              InputProps: {
                classes:{ root: classes.inputRoot, input: classes.input },
                startAdornment: selectedItems.map((item: any, index: any) => (
                  [
                    <div key={item.object_id} style={{ margin: 20 }}>
                      <Chip
                        tabIndex={-1}
                        label={itemToString(item)}
                        onDelete={handleDelete(item)}
                        avatar={renderChipAvatar(item)}
                      />
                    </div>,
                    (index + 1) % 5 == 0 ? <div></div> : null
                  ]
                )),
                onBlur,
                onChange: (event:any) => {
                  handleInputChange(event);
                  if(onChange) {onChange(event)}
                },
                onFocus,
              },
              error: !!(touched && error),
              helperText: touched && error,
              inputProps
            })}

            {isOpen ? (
              <Paper className={classes.paper} square>
                {
                  suggestions.length > 0 &&
                  suggestions.map((suggestion:any, index:number) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItems: selectedItems2,
                    }),
                  )
                }
              </Paper>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
}

const isRequired = (value:any) => {
  return value && value.length > 0 ? undefined : `مطلوب`
}

function SquadInput(props:any){
  const {required, ...other} = props
  return (
    <span>
      <Field
        name="person"
        component={(componentProps:any) => (<DownshiftMultiple  {...componentProps} />)}
        validate={required ? [isRequired] : []}
        {...other}
      />
    </span>
  )
};
//@ts-ignore
export default withStyles(styles)(translate(SquadInput))