import _ from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ar-ma'

dayjs.locale('ar-ma') // use loaded locale globally
dayjs.extend(customParseFormat)
dayjs.extend(utc)


class Competition {
  private model : { [x:string]: any }
  constructor(comp : { [x:string]: any }){
    this.model = comp
  }
  public getInstance(){
    return {
      logo: this.getLogo()
    }
  }
  getLogo(){
    return `${process.env.REACT_APP_MEDIA_URL}/stats/competitions/${this.model.competition_id}.png`
  }
}

export default Competition
