import React from 'react';
import { Create, SimpleForm, TextInput, required} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';


  const styles = {
    tabLabel:{
      background:"blue",
    },
    inputLabel: {
      '& label':{
        transformOrigin: "top right",
        right: 0,
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".74rem",
      },
      '& input':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".84rem",
      },
      '& textarea':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".9rem",
      },
    },
    coloredLabel: {
      '& label':{
        fontWeight:700,
      }
    },
    largeSizedInput:{
      width: "70%",
    },
    boldText:{
      fontWeight: 700,
      color: "#687c8c",
      '& input':{
        color: "#687c8c",
      }
    },
    txtColor:{
      color:"red",
    },
    radioBtn:{
      '& label':{
        flexDirection: "row-reverse" as 'row-reverse',
        justifyContent: "flex-end",
        margin: 0,
        '&>span:first-child':{
          direction:"ltr" as 'ltr',
        },
        '&>span:last-child':{
          width: 100,
          display: "inline-block",
        }
      },
      '& span':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      }
    },
  
    tagsList:{
      '& p':{
        display:"none",
      },
      '& li':{
        marginBottom: 30,
        borderBottom: "none",
      }
    },
    test:{
      background:"red",
    },
    isLTR:{
      direction: 'ltr' as 'ltr',
    }
  }
const FlatpageCreate = (props: any) => {
  const classes = props.classes;
  return(
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" className={ classes.inputLabel } validate={required()}/>
        <TextInput
          source="content"
          className={ classNames(classes.inputLabel,classes.isLTR, classes.largeSizedInput) }
          multiline
          validate={required()}
        />
        <TextInput source="absolute_url" className={ classNames(classes.inputLabel,classes.isLTR) }/>
      </SimpleForm>
    </Create>
  )
};

const FlatpageCreateForm = withStyles(styles)(FlatpageCreate)

export default FlatpageCreateForm