import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SvgIcon from "@material-ui/core/SvgIcon";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AIPromptModal from "./AIPromptModal";

const defaultPrompts: string[] = [
  "As a sports journalist writing in French, you are tasked with reworking and rewriting a sports news article originally written in Arabic. Using the inverted pyramid writing principle, your goal is to create a unique article that captures the essence of the original piece while also making it engaging and accessible to a French-speaking audience. Consider the different cultural references and nuances that may need to be translated or adapted for a new audience, as well as the tone and style of writing that best suits the topic. Remember to prioritize the most important information at the beginning of the article and build on it with additional details and context further down. Ultimately, your goal is to create a compelling and informative piece of sports journalism that resonates with your readership. Be creative, be concise, and have fun crafting a unique French-language article",
];

type contentProps = {
  record?: any;
  classes?: any;
  historyPush?: any;
};

const styles = {
  menuLinkItem: {
    textDecoration: "none",
    color: "#0000008a",
    fontSize: ".95rem",
  },
  menuIcon: {
    marginLeft: 8,
  },
  link: {
    textDecoration: "none",
    display: "inline-block",
  },
  roundBtn: {
    borderRadius: "50%",
  },
  button: {
    boxShadow: "none",
    background: "transparent",
  },
  twitterButton: {
    color: "#1da1f2",
  },
  fbButton: {
    color: "#4267b2",
  },
  instaButton: {
    color: "#C1558B",
  },
  interButton: {
    color: "#4b8acc",
  },
};

class LongMenu extends React.Component<contentProps> {
  constructor(props: any) {
    super(props);
    this.onReformulateClick = this.onReformulateClick.bind(this);
  }

  state = {
    anchorEl: null,
    promptOpen: false,
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSelect = (url: any, source: any, contentType: any, e: any) => {
    var clipboardText = url;
    switch (source) {
      case "url":
        window.open(url);
        break;
      case "facebook":
        clipboardText = clipboardText.concat(
          `?utm_source=facebook&utm_medium=page&utm_content=${contentType}`
        );
        break;
      case "twitter":
        clipboardText = clipboardText.concat(
          `?utm_source=twitter&utm_medium=page&utm_content=${contentType}`
        );
        break;
      case "instagram":
        clipboardText = clipboardText.concat(
          `?utm_source=instagram&utm_medium=page&utm_content=${contentType}`
        );
        break;
      case "threads":
          clipboardText = clipboardText.concat(
            `?utm_source=threads&utm_medium=page&utm_content=${contentType}`
          );
          break;
      default:
        console.log("fail to copy");
    }
    navigator.clipboard.writeText(clipboardText);
    this.setState({ anchorEl: null });
  };

  async onReformulateClick() {
    this.setState({ promptOpen: true });
  }

  render() {
    const { anchorEl, promptOpen } = this.state;
    const open = Boolean(anchorEl);
    const { classes, record } = this.props;
    let { absolute_url: url, content_type: contenType, lang, id } = record;
    if (!url) {
      return null;
    }
    if (lang === "fr") {
      url = url.replace("www.", "m.").replace(".com", ".com/fr");
    }

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          <MenuItem
            key="preview"
            onClick={this.handleSelect.bind(this, url, "url", null)}
          >
            <SvgIcon
              className={classNames(classes.menuIcon, classes.interButton)}
            >
              <path d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </SvgIcon>
            <a href={url} target="_blank" className={classes.menuLinkItem}>
              معاينة على الموقع
            </a>
          </MenuItem>
          <MenuItem
            key="preview"
            onClick={this.handleSelect.bind(this, url, "facebook", contenType)}
          >
            <SvgIcon className={classNames(classes.menuIcon, classes.fbButton)}>
              <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
            </SvgIcon>
            <span className={classes.menuLinkItem}>نسخ رابط فايسبوك</span>
          </MenuItem>
          <MenuItem
            key="preview"
            onClick={this.handleSelect.bind(this, url, "twitter", contenType)}
          >
            <SvgIcon
              className={classNames(classes.menuIcon, classes.twitterButton)}
            >
              <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
            </SvgIcon>
            <span className={classes.menuLinkItem}>نسخ رابط تويتر</span>
          </MenuItem>
          <MenuItem
            key="preview"
            onClick={this.handleSelect.bind(this, url, "instagram", contenType)}
          >
            <SvgIcon
              className={classNames(classes.menuIcon, classes.instaButton)}
            >
              <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
            </SvgIcon>
            <span className={classes.menuLinkItem}>نسخ رابط إنستجرام</span>
          </MenuItem>
          <MenuItem
            key="preview"
            onClick={this.handleSelect.bind(this, url, "threads", contenType)}
          >
            <SvgIcon
              className={classNames(classes.menuIcon)}
              viewBox="0 0 192 192"
            >
              <path d="M141.537 88.9883C140.71 88.5919 139.87 88.2104 139.019 87.8451C137.537 60.5382 122.616 44.905 97.5619 44.745C97.4484 44.7443 97.3355 44.7443 97.222 44.7443C82.2364 44.7443 69.7731 51.1409 62.102 62.7807L75.881 72.2328C81.6116 63.5383 90.6052 61.6848 97.2286 61.6848C97.3051 61.6848 97.3819 61.6848 97.4576 61.6855C105.707 61.7381 111.932 64.1366 115.961 68.814C118.893 72.2193 120.854 76.925 121.825 82.8638C114.511 81.6207 106.601 81.2385 98.145 81.7233C74.3247 83.0954 59.0111 96.9879 60.0396 116.292C60.5615 126.084 65.4397 134.508 73.775 140.011C80.8224 144.663 89.899 146.938 99.3323 146.423C111.79 145.74 121.563 140.987 128.381 132.296C133.559 125.696 136.834 117.143 138.28 106.366C144.217 109.949 148.617 114.664 151.047 120.332C155.179 129.967 155.42 145.8 142.501 158.708C131.182 170.016 117.576 174.908 97.0135 175.059C74.2042 174.89 56.9538 167.575 45.7381 153.317C35.2355 139.966 29.8077 120.682 29.6052 96C29.8077 71.3178 35.2355 52.0336 45.7381 38.6827C56.9538 24.4249 74.2039 17.11 97.0132 16.9405C119.988 17.1113 137.539 24.4614 149.184 38.788C154.894 45.8136 159.199 54.6488 162.037 64.9503L178.184 60.6422C174.744 47.9622 169.331 37.0357 161.965 27.974C147.036 9.60668 125.202 0.195148 97.0695 0H96.9569C68.8816 0.19447 47.2921 9.6418 32.7883 28.0793C19.8819 44.4864 13.2244 67.3157 13.0007 95.9325L13 96L13.0007 96.0675C13.2244 124.684 19.8819 147.514 32.7883 163.921C47.2921 182.358 68.8816 191.806 96.9569 192H97.0695C122.03 191.827 139.624 185.292 154.118 170.811C173.081 151.866 172.51 128.119 166.26 113.541C161.776 103.087 153.227 94.5962 141.537 88.9883ZM98.4405 129.507C88.0005 130.095 77.1544 125.409 76.6196 115.372C76.2232 107.93 81.9158 99.626 99.0812 98.6368C101.047 98.5234 102.976 98.468 104.871 98.468C111.106 98.468 116.939 99.0737 122.242 100.233C120.264 124.935 108.662 128.946 98.4405 129.507Z"></path>
            </SvgIcon>
            <span className={classes.menuLinkItem}>نسخ رابط Threads</span>
          </MenuItem>
          {contenType === "article" && lang === "ar" && (
            <MenuItem key="preview" onClick={this.onReformulateClick}>
              <SvgIcon className={classNames(classes.menuIcon)}>
                <path d="M12 6v3l4-4-4-4v3a8 8 0 00-8 8c0 1.57.46 3.03 1.24 4.26L6.7 14.8A5.9 5.9 0 016 12a6 6 0 016-6m6.76 1.74L17.3 9.2c.44.84.7 1.8.7 2.8a6 6 0 01-6 6v-3l-4 4 4 4v-3a8 8 0 008-8c0-1.57-.46-3.03-1.24-4.26z" />
              </SvgIcon>
              <span className={classes.menuLinkItem}>Reformulate to FR</span>
            </MenuItem>
          )}
        </Menu>
        <AIPromptModal
          open={promptOpen}
          defaultPrompts={defaultPrompts}
          onClose={() => this.setState({ promptOpen: false })}
          articleId={id}
          historyPush={this.props.historyPush}
        />
      </div>
    );
  }
}

const ExpandableMenu = withStyles(styles)(LongMenu);

export default ExpandableMenu;
