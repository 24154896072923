import React from 'react';
import { Create, SimpleForm, TextInput, ImageField,
  BooleanInput, ImageInput, SelectInput, required } from 'react-admin';
import { DateTimeInput } from "react-admin-date-inputs";
import _ from 'lodash';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TagsInput from '../../inputs/TagsInputs';
import { connect } from "react-redux";
import CountrySearchInput from '../../inputs/CountrySearchInput';
import SportSearchInput from '../../inputs/SportSearchInput';
import { getDefaultFilterLang } from '../../filters/ContentFilter';


const styles = {
  tabLabel:{
    background:"blue",
  },
  inputLabel: {
    width:'50%',
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    }
  },
  biggerTag:{
    '& label':{
      fontSize:".85rem",
    }
  },
  coloredLabel: {
    '& label':{
      fontWeight:700,
    }
  },
  boldText:{
    fontWeight: 700,
    color: "#687c8c",
    '& input':{
      color: "#687c8c",
    }
  },
  txtColor:{
    color:"red",
  },
  radioBtn:{
    '& label':{
      flexDirection: "row-reverse" as 'row-reverse',
      justifyContent: "flex-end",
      margin: 0,
      '&>span:first-child':{
        direction:"ltr" as 'ltr',
      },
      '&>span:last-child':{
        width: 100,
        display: "inline-block",
      }
    },
    '& span':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    }
  },

  goalsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  imgWrapper:{
    width:"50%"
  },
  notifImg:{
    width: "100%"
  },
  tagsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
}


const NewsfeedCreate = (props: any) => {
  const classes = props.classes;
  let defaultLang = getDefaultFilterLang()
  defaultLang = defaultLang == 'fr' ? 'fr' : 'ar'
  const defaultValues:{[x:string]:any} = {pub_date: new Date(), lang: defaultLang}
  return <Create {...props}>
      <SimpleForm defaultValue={defaultValues}>
        <SelectInput source="lang" initialValue={'ar'} choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
        <TextInput source="title" className={ classes.inputLabel } validate={required()}/>
        <TextInput source="absolute_url" className={ classes.inputLabel }  validate={required()}/>
        <ImageInput source="w_image" accept="image/*" className={ classes.inputLabel }>
            <ImageField source="default_path" title="title" />
        </ImageInput>
        <SelectInput
          source="content_type"
          className={classNames(classes.tabField,classes.alignRight)}
          headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
          choices={[
            { id: "article", name: 'مقال' },
            { id: "video", name: 'فيديو' },
            { id: "gallery", name: 'رواق' },
            { id: "match", name: 'مباراة' },
            { id: "highlight", name: 'ملخص' },
            { id: "streaming", name: 'بث مباشر' },
          ]}
          validate={required()} 
          />
        <BooleanInput source="is_important" className={ classes.inputLabel }  />
        <BooleanInput source="has_copyright" className={ classes.inputLabel }  />
        <DateTimeInput className={classNames(classes.inputLabel,classes.boldText)}  source="pub_date" label='تاريخ النشر'  options={{ format: 'd/MM/YYYY HH:mm', ampm: false}}/>
        <TagsInput label="طاغات المادة"/>

        <CountrySearchInput
          className={ classes.inputLabel } 
          required
        />

        <SportSearchInput
          className={ classes.inputLabel } 
          required
        />
      </SimpleForm>
  </Create>
};


const NewsfeedCreateComponent = withStyles(styles)(NewsfeedCreate)

export default NewsfeedCreateComponent
