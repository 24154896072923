import React from 'react';
import { List, Datagrid, TextField, ImageField, FunctionField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import SearchComponent from '../../filters/SearchComponent';
import Avatar from '@material-ui/core/Avatar';

const styles = {
    tabField: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".74rem",
    },
    alignRight: {
        textAlign: 'right' as 'right'
    },
    tableHeader: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".8rem",
        fontWeight: 700,
    },
    playerImg: {
        width: 50,
        '& img': {
            width: "100%",
        }
    }
}



const Player = (props: any) => {
    const classes = props.classes;
    const filters = (
        <SearchComponent
            source="player_id"
            reference="analytics/players"
            itemToString={(choice:any) => (`${choice.club_name}`)}
        /> 
    )
    return (
        <List {...props} title="قائمة اللاعبين" filters={filters} exporter={false}>
            <Datagrid rowClick="edit">
                <FunctionField
                    label="team"
                    render={
                        (record:any) => <Avatar src={`https://images.elbotola.com/stats/logos/${record.activeMembership ? record.activeMembership.contestant_id : '0'}.png`} />
                    }
                    />
                <ImageField source="logo" className={classNames(classes.tabField, classes.alignRight, classes.playerImg)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="first_name" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="last_name" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="status" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="activeMembership.contestant_name" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="position" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="nationality.name" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="country_of_birth" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <PreviewButtonComponent />
            </Datagrid>
        </List>
    )
};

const PlayerView = withStyles(styles)(Player)

export default PlayerView