import React from 'react';
import { 
  Edit, SimpleForm, TextInput, BooleanInput, ImageField,
  SelectInput, ImageInput, required,
} from 'react-admin';
import { connect } from "react-redux";
import { DateTimeInput } from "react-admin-date-inputs";
import _ from 'lodash';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import dayjs from "dayjs";
import TagsInput from '../../inputs/TagsInputs';
import CountrySearchInput from '../../inputs/CountrySearchInput';
import SportSearchInput from '../../inputs/SportSearchInput';


const styles = {
  tabLabel:{
    background:"blue",
  },
  inputLabel: {
    width:'50%',
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    }
  },
  biggerTag:{
    '& label':{
      fontSize:".85rem",
    }
  },
  coloredLabel: {
    '& label':{
      fontWeight:700,
    }
  },
  boldText:{
    fontWeight: 700,
    color: "#687c8c",
    '& input':{
      color: "#687c8c",
    }
  },
  txtColor:{
    color:"red",
  },
  radioBtn:{
    '& label':{
      flexDirection: "row-reverse" as 'row-reverse',
      justifyContent: "flex-end",
      margin: 0,
      '&>span:first-child':{
        direction:"ltr" as 'ltr',
      },
      '&>span:last-child':{
        width: 100,
        display: "inline-block",
      }
    },
    '& span':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    }
  },

  goalsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  imgWrapper:{
    width:"50%"
  },
  notifImg:{
    width: "100%"
  },
  tagsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
}

const NewsfeedEdit = (props: any) => {
  const classes = props.classes;

  return <Edit  {...props}>
      <SimpleForm handleSubmit={(Submit: any) => {
            if (_.get(props, 'form.record-form.syncErrors')){
              return
            }
            const anyTouched = _.get(props, 'form.record-form.anyTouched', null)
            if(anyTouched){
                const fields = _.get(props, 'form.record-form.fields', {})
                const dirtyValues: { [x:string]: any } = {}
                Object.keys(fields).map((field:string) => {
                  dirtyValues[field] = props.form['record-form'].values[field]
                })             
                Submit(dirtyValues)
            }
         }}>
        <SelectInput source="lang" choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
        <TextInput source="title" className={ classes.inputLabel } validate={required()}/>
        <TextInput source="absolute_url" className={ classes.inputLabel }  validate={required()}/>
        <ImageInput source="w_image" accept="image/*">
            <ImageField source="default_path" title="title" />
        </ImageInput>
        <SelectInput source="content_type" className={ classes.inputLabel } 
            choices={[
              { id: "article", name: 'مقال' },
              { id: "video", name: 'فيديو' },
              { id: "gallery", name: 'رواق' },
              { id: "match", name: 'مباراة' },
              { id: "highlight", name: 'ملخص' },
              { id: "streaming", name: 'بث مباشر' },
            ]}
            validate={required()}
          />
        <BooleanInput source="is_important" className={ classes.inputLabel }  />
        <BooleanInput source="has_copyright" className={ classes.inputLabel }  />
        <DateTimeInput className={classNames(classes.inputLabel,classes.boldText)}  source="pub_date" label='تاريخ النشر'   options={{ format: 'd/MM/YYYY HH:mm', ampm: false}}/>
        <TagsInput label='طاغات المادة' />
          
                <CountrySearchInput
                  className={ classes.inputLabel } 
                  required
                />

                <SportSearchInput
                  className={ classes.inputLabel } 
                  required
                />
      </SimpleForm>
  </Edit>
};

const NewsfeedEditComponent = withStyles(styles)(NewsfeedEdit)

export default connect(
  (state: any, props: any) => {
    return state
  },
  {}
)(NewsfeedEditComponent);