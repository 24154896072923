import React from 'react';
import { Edit,  SimpleForm, NumberInput,  TextInput,  ImageInput, ImageField,  SelectInput, required } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ColorInput } from 'react-admin-color-input';
import { connect } from "react-redux";
import _ from 'lodash';
import TagsInput from '../../inputs/TagsInputs';



const styles = {
  tabLabel:{
    background:"blue",
  },
  inputLabel: {
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    }
  },
  biggerTag:{
    '& label':{
      fontSize:".85rem",
    }
  },
  coloredLabel: {
    '& label':{
      fontWeight:700,
    }
  },
  boldText:{
    fontWeight: 700,
    color: "#687c8c",
    '& input':{
      color: "#687c8c",
    }
  },
  txtColor:{
    color:"red",
  },
  radioBtn:{
    '& label':{
      flexDirection: "row-reverse" as 'row-reverse',
      justifyContent: "flex-end",
      margin: 0,
      '&>span:first-child':{
        direction:"ltr" as 'ltr',
      },
      '&>span:last-child':{
        width: 100,
        display: "inline-block",
      }
    },
    '& span':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    }
  },

  goalsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  imgWrapper:{
    width:"50%"
  },
  notifImg:{
    width: "100%"
  },
  tagsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
}

const ChatroomsEdit = (props: any) => {
  
  const classes = props.classes;
  return(
  <Edit {...props} title="تعديل صالة دردشة">
    <SimpleForm handleSubmit={(Submit: any) => {
      if (_.get(props, 'form.record-form.syncErrors')){
        return
      }
      const anyTouched = _.get(props, 'form.record-form.anyTouched', null)
      if(anyTouched){
          const fields = _.get(props, 'form.record-form.fields', {})
          const dirtyValues: { [x:string]: any } = {}
          Object.keys(fields).map((field:string) => {
              dirtyValues[field] = props.form['record-form'].values[field]
          })             
          Submit(dirtyValues)
      }
    }}>
      <TextInput source="title" className={ classes.inputLabel } validate={required()}/>
      <TextInput source="opening_message" />
      <TextInput source="match_id" className={ classes.inputLabel }/>
      <SelectInput source="type" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
            choices={[
              { id: 1, name: 'كلاسيكي' },
              { id: 2, name: 'مباراة' },
            ]} 
          />
      <ImageInput source="w_image">
        <ImageField source="default_path" title="title" />
      </ImageInput>
      <TextInput source="w_image_url" className={ classes.inputLabel }/>
      <TagsInput label="Tags" className={ classes.inputLabel }/>
      <NumberInput source="ordering" className={ classes.inputLabel }/>
      <TextInput source="team_A_id" className={ classes.inputLabel }/>
      <TextInput source="team_A_name" className={ classes.inputLabel }/>
      <ColorInput source="team_A_color"   className={ classes.inputLabel }/>
      <TextInput source="team_B_id" className={ classes.inputLabel }/>
      <TextInput source="team_B_name" className={ classes.inputLabel }/>
      <ColorInput source="team_B_color"   className={ classes.inputLabel }/>
    </SimpleForm>
  </Edit>
)};

const ChatroomsEditForm = withStyles(styles)(ChatroomsEdit)

export default connect(
  (state: any, props: any) => {
    return state
  },
  {}
)(ChatroomsEditForm);