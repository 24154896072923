import React from 'react';
import dayjs from "dayjs";


const PubDateField = (props: any) => {
    const { record, source, unix, format="HH:mm - DD/MM/YYYY" } = props
    var datetime = (unix ? dayjs.unix(record[source]) : dayjs(record[source])).format(format);
    return (<span>{datetime}</span>)
}

export default PubDateField