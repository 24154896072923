import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Embedo from 'embedo';

const embedo = new Embedo({
  facebook: {
     appId: '560212177337698', // Enable facebook SDK
     version: 'v2.10'
   },
   youtube: true,
   twitter: true,  // Enable twitter SDK
   instagram: true,  // Enable instagram SDK
   pinterest: true  // Enable pinterest SDK,
 });

const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';
const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
const utils = {
  isYoutube: (url:any) => YOUTUBEMATCH_URL.test(url),
  isVimeo: (url:any) => VIMEOMATCH_URL.test(url),
  getYoutubeSrc: (url:any) => {
    const id = url && url.match(YOUTUBEMATCH_URL)[1];
    return {
      srcID: id,
      srcType: 'youtube',
      url,
    };
  },
  getVimeoSrc: (url:any) => {
    const id = url.match(VIMEOMATCH_URL)[3];
    return {
      srcID: id,
      srcType: 'vimeo',
      url,
    };
  },
};

const getSrc = ({ src }: {[x:string]: any}) => {
  const { isYoutube, getYoutubeSrc, isVimeo, getVimeoSrc } = utils;
  if (isYoutube(src)) {
    const { srcID } = getYoutubeSrc(src);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (isVimeo(src)) {
    const { srcID } = getVimeoSrc(src);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  return undefined;
};

const DefaultVideoCompoent = ({ blockProps, className = '', style, theme }: {[x:string]: any}) => {
  const { src, width, height} = blockProps
  //@ts-ignore
  let embedoContainer = null;
  const [ rendered, setRender ] = useState(false)

  useEffect(() => {
    if(rendered) return;
      Embedo.defaults.SOURCES.reddit = {
        GLOBAL: 'elbotola',
        oEmbed: null,
        REGEX: /(https?:\/\/(ww.)?)?elbotola(\.[a-z]+).*/i,
        PARAMS: {}
      };
      Object.defineProperty(Embedo.prototype, 'reddit', {
        //@ts-ignore
        value: function (id, element, url, options, callback) {
          var size = Embedo.utils.dimensions(element, options.width = "100%", options.height = "100%");
          console.log('embed_options dimensions ', options)
          var embed_options = Embedo.utils.merge({
              embed: true,
              context: 1,
              depth: 2,
              uuid: null,
              showedits: true,
              showmore: false,
              width: size.width,
              height: size.height
            },
            options,
            Embedo.defaults.RESTRICTED
          );
          //@ts-ignore
          const foundList = url.split('/').filter(u => u.indexOf('.html') > 0).map(u => u.replace(".html", ""))
          if(foundList.length > 0){
            const articleSlug = foundList[0]
            fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/articles/${articleSlug}/`)
              // .then(response => response.json())
              .then(response => {
                if(response.status > 400){
                  throw new Error('Url is not correct')
                }
                return response.json()
              })
              .then(data => {
                console.log('embed_options width', data)         
                var embed_el = Embedo.utils.generateElement('div', {
                  style: `
                    width: ${embed_options.width || 500}px;
                    height: ${embed_options.height || 500}px;
                    margin:0;
                    padding:0;
                    background:#ecf0f1;
                    white-space:normal;
                    border-radius:5px;
                    overflow:hidden;
                  `
                }, `
                    <div style="vertical-align:top;">
                      <img style="width:100%" src="${data.image.default_path}" />
                    </div>
                    <div style="padding: 10px 12px;">
                      <h4 style="color:#004a7a; margin: 0; margin-bottom:5px;">${data.title}</h4>
                      <img 
                        src="https://images.elbotola.com/favicon.ico"
                        style="display:inline-block; vertical-align:middle; margin-left:10px;" />
                      <span style="display:inline-block; color:#7f8c8d; font-size:80%;">elbotola.com</span>
                    </div>
                `);
                var container = Embedo.utils.generateEmbed(id, 'elbotola', embed_el);
        
                element.appendChild(container);
        
                callback(null, {
                  id: id,
                  el: element,
                  width: size.width,
                  height: size.height
                });


              }).catch(err => console.error(err))
          }

        },
        writable: false,
        enumerable: true,
        configurable: true
      });
    //@ts-ignore
    embedo.render(embedoContainer, src, {
      width,
      height
    })
    setRender(true)
  }, []);
    return (
      <div ref={(input) => { embedoContainer = input; }} className={theme.iframe} id="embedo-container"> </div>
    );
  // }

  // return <div className={theme.invalidVideoSrc}>invalid video source</div>;
};

DefaultVideoCompoent.propTypes = {
  blockProps: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.object.isRequired,
  getSrc: PropTypes.func
};
export default DefaultVideoCompoent;
