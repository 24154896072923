import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import CustomAutoComplete from './CustomAutoComplete';

function fetchValues(q: string) {
    if(!q || q.length <= 3){
        return Promise.reject()
    }
    const request = new Request(`${process.env.REACT_APP_AUTH_ENDPOINT}/${process.env.REACT_APP_COUNTRY_RESOURCE}/?q=${q}`, {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include'
    })
    return fetch(request)
        .then(response => (response.json()))
        .then(response => response.results || [])
}

function renderSuggestion(suggestionProps: any) {
    const {
        suggestion,
        index,
        itemProps,
        highlightedIndex,
        selectedItem
    } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem && (selectedItem.name || "").indexOf(suggestion.name) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.name}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400
            }}
        >
            {`${suggestion.name} ${suggestion.name_ar}`}
        </MenuItem>
    );
}

export default function CountrySearchInput(props: any) {
    function onSelectedItemChange(selectedItem: any, input: any) {
        input.onFocus(selectedItem.name)
        input.onChange(selectedItem.name)
        input.onBlur(selectedItem.name)
    }
    return (
        <CustomAutoComplete
            label="الدولة"
            name="country"
            fetchValues={fetchValues}
            itemToString={(item: any) => (item ? `${item.name} ${item.name_ar}` : null)}
            findSelectedItem={(suggestions: Array<any>, selectedItemString: any) => (
                suggestions.find((item: any) => (item.name == selectedItemString))
            )}
            renderSuggestion={renderSuggestion}
            onSelectedItemChange={onSelectedItemChange}
            {...props}
        />
    )
} 