import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, ImageField, SelectInput, required } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import SvgIcon from '@material-ui/core/SvgIcon';
import _ from 'lodash'
import TagsInput from '../../inputs/TagsInputs';
import { Avatar } from '@material-ui/core';
import CountrySearchInput from '../../inputs/CountrySearchInput';



const styles = {
  tabLabel:{
    background:"blue",
  },
  inputLabel: {
    width:'50%',
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    },
    '& textarea':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    },
  },
  biggerTag:{
    '& label':{
      fontSize:".85rem",
    }
  },
  coloredLabel: {
    '& label':{
      fontWeight:700,
    }
  },
  boldText:{
    fontWeight: 700,
    color: "#687c8c",
    '& input':{
      color: "#687c8c",
    }
  },
  txtColor:{
    color:"red",
  },
  radioBtn:{
    '& label':{
      flexDirection: "row-reverse" as 'row-reverse',
      justifyContent: "flex-end",
      margin: 0,
      '&>span:first-child':{
        direction:"ltr" as 'ltr',
      },
      '&>span:last-child':{
        width: 100,
        display: "inline-block",
      }
    },
    '& span':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    }
  },

  goalsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  imgWrapper:{
    width:"50%"
  },
  notifImg:{
    width: "100%"
  },
  tagsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  isLTR:{
    direction: 'ltr' as 'ltr',
  },
}

const cardStyles = {
  card: {
    width: 400,
    direction: "ltr" as 'ltr',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    borderRadius: 0,
  },
  title:{
    fontSize: ".9rem",
    direction: "rtl" as 'rtl',
    textAlign: 'left' as 'left',
    whiteSpace: "nowrap" as 'nowrap',
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  content:{
    border: "solid 2px red",
  },
  brand:{
    color: "#25A9FE",
    direction: "rtl" as 'rtl',
    display: "inline-block",
    fontSize: "0.85rem",
  },
  cardHeaderContent:{
    paddingTop: 0,
    overflow: 'hidden',
    paddingRight:0,
    paddingLeft:0,
    maxWidth:300,
  },
  subHeader:{
    fontSize: ".75rem",
    marginTop:4,
    direction: "rtl" as 'rtl',
    textAlign: 'left' as 'left',
    whiteSpace: "nowrap" as 'nowrap',
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  contentWrapper:{
    display: "flex",
    justifyContent: "space-evenly",
  }
}

const NotificationPreview = (props: any) => {
  const classes = props.classes;
  return (
    <Card className={classes.card}>
      <div>
        <IconButton color="primary">
          <SvgIcon>
            <path d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21" />
          </SvgIcon>
        </IconButton>
        <span className={classes.brand}>البطولة - Elbotola</span>
      </div>

      <div className={classes.contentWrapper}>
        <CardHeader
          title={props.title}
          subheader={props.description}
          className={classes.cardHeaderContent}
          classes={{
            title: classes.title,
            content: classes.cardHeaderContent,
            subheader: classes.subHeader,
          }}
        />
        <div>
          <Avatar
            alt="Elbotola logo"
            src="https://images.elbotola.com/images/botola-app_icon.png"
            className={classes.avatar}
          />
        </div>
      </div>
    </Card>
  );
};

export const NotificationPreviewComponent = withStyles(cardStyles)(NotificationPreview)

const CustomTitle = (props:any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>تعديل إشعار "{props.record.title}"</span>
  )
}

const NotificationEdit = (props: any) => {
  const [defaultValues, setDefaultValues] = React.useState({  lang: "ar" });

  const [previewValues, setPreviewValues] = React.useState({});
  React.useEffect(() => {setPreviewValues({
    title: _.get(props, 'form.record-form.values.title'),
    description: _.get(props, 'form.record-form.values.description')
  });})

  const classes = props.classes;
  return(
  <Edit {...props} title={<CustomTitle/>}>
    <SimpleForm
      defaultValues
      handleSubmit={(Submit: any) => {
        if (_.get(props, 'form.record-form.syncErrors')){
          return
        }
        const anyTouched = _.get(props, 'form.record-form.anyTouched', null)
        if(anyTouched){
            const fields = _.get(props, 'form.record-form.fields', {})
            const dirtyValues: { [x:string]: any } = {}
            Object.keys(fields).map((field:string) => {
              dirtyValues[field] = props.form['record-form'].values[field]
            })             
            Submit(dirtyValues)
        }
      }}
      onChange={(e:any) => {
        if(e.target && e.target.name == "title"){
          setPreviewValues({ ...previewValues, title: e.target.value })
        }
        if(e.target && e.target.name == "description"){
          setPreviewValues({ ...previewValues, description: e.target.value })
        }
      }}
    >
      <SelectInput source="lang" choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
      <TextInput 
        className={ classNames(classes.inputLabel, classes.isLTR) } 
        source="url" 
      />
      <SelectInput source="category" className={ classes.inputLabel } 
            choices={[
              { id: "article", name: 'مقال' },
              { id: "match", name: 'مباراة' },
              { id: "video", name: 'فيديو' },
              { id: "betting", name: 'توقعات' },
              { id: "chat", name: 'شات' },
              { id: "promotion", name: 'ترويج' },
            ]} 
          />
      <TextInput source="description" className={ classes.inputLabel } multiline rows={2} validate={required()}/>
      <TextInput source="title" className={ classes.inputLabel } validate={required()}/>
      <TextInput source="image" className={ classes.inputLabel } validate={required()}/>
      <ImageField source="image" title="title" />
      
      <NotificationPreviewComponent {...previewValues}/>

      <TagsInput label="طاغات المادة"/>
        <CountrySearchInput
            className={ classes.inputLabel } 
            required
          />
        <BooleanInput source="test_only" />

        <TextInput source="platform" className={ classes.inputLabel }/>
        <TextInput source="custom_token" className={ classes.inputLabel }/>
    </SimpleForm>
  </Edit>
)};

const NotificationEditForm = withStyles(styles)(NotificationEdit)

export default connect(
  (state: any, props: any) => {
    return state
  },
  {}
)(NotificationEditForm);