import React, { createRef } from "react";
import { addField } from "react-admin";
import flatten from "lodash/flatten";
import ImageAdd from "./ImageSideButton/Button";
import editorStyles from "./RichTextEditor.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import VideoEmbedComponent from "../components/VideoEmbedComponent";
import { TwitterPicker, ColorResult } from "react-color";
import { withStyles } from "@material-ui/core/styles";
import createImagePlugin from "draft-js-image-plugin";
import createMentionPlugin, { defaultTheme } from "draft-js-mention-plugin";
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import { OrderedMap } from "immutable";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  SelectionState,
  AtomicBlockUtils
} from "draft-js";
import createVideoPlugin from "draft-js-video-plugin";
import createSideToolbarPlugin from "../plugins/SideToolbar/index";
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
import createEmbedPlugin from "./EmbedPlugin";
import EmbedButton from "./VideoSideButton/EmbedButton";
import createAutosavePlugin from "@jimmycode/draft-js-autosave-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  BlockquoteButton } from "draft-js-buttons";
import { Grid, Button } from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";

import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';

// import sideToolbarStyles from "./SideToolbarPlugin/index.module.css";
import CustomInlineToolbarStyles from "./CustomInlineToolbarStyles.module.css";

import { convertFromHTML, convertToHTML } from './RichTextConverter';

import AlignmentToolStyles from './AlignmentStyles.module.css';
import AlignementButtons from './AlignmentButtonStyles.module.css';
import "medium-draft/lib/index.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "draft-js-image-plugin/lib/plugin.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js-inline-toolbar-plugin/lib/plugin.css";
import "draft-js-embed-plugin/lib/plugin.css";
import "last-draft-js-sidebar-plugin/lib/plugin.css";
import SideToolbarButtonStyles from './SideToolbarPlugin/buttonStyles.module.css'
import SideToolbarBlockTypeSelectStyles from './SideToolbarPlugin/blockTypeSelectStyles.module.css'
import SideToolbarToolbarStyles from './SideToolbarPlugin/toolbarStyles.module.css'
import createLinkPlugin from '@iliyass/draft-js-anchor-plugin';
import LinkForm from './AnchorPlugin/LinkForm';
import AnchorButton from './AnchorPlugin/AnchorButton';
import SpellCheckerButton, { SPELLCHECKERROR_STYLE } from './SpellCheckerButton';
import SpellCheckDialog from './SpellCheckDialog';

const colorStyleMap = {
  "color-E10000": { color: "#e10000" },
  "color-FF6900": { color: "#FF6900" },
  "color-FCB900": { color: "#FCB900" },
  "color-7BDCB5": { color: "#7BDCB5" },
  "color-00D084": { color: "#00D084" },
  "color-8ED1FC": { color: "#8ED1FC" },
  "color-0693E3": { color: "#0693E3" },
  "color-ABB8C3": { color: "#ABB8C3" },
  "color-EB144C": { color: "#EB144C" },
  "color-F78DA7": { color: "#F78DA7" },
  "color-9900EF": { color: "#9900EF" },
  ...SPELLCHECKERROR_STYLE
};

const colorsPicker = [
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF"
];


const tagLinkPlugin = createLinkPlugin({
  LinkForm: LinkForm,
  LinkButton: AnchorButton
});
const hrefLinkPlugin = createLinkPlugin({});
const embedPlugin = createEmbedPlugin();

const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    buttonStyles: CustomInlineToolbarStyles,
    toolbarStyles: CustomInlineToolbarStyles
  }
});
// const { EmbedButton } = iframelyPlugin;

type TextProps = {
  id: string;
  record: any;
  input: {
    onChange: (editorState: any) => void;
  };
  classes: any;
  lang: string;
};
type TextState = {
  editorState: any;
  suggestions: any;
  hasSpellingErrors: boolean;
  openSpellDialog: boolean,
};

const regExpLat =
  "[" +
  "\\w-" +
  // Latin-1 Supplement (letters only) - https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin-1_Supplement
  "\u00C0-\u00D6" +
  "\u00D8-\u00F6" +
  "\u00F8-\u00FF" +
  // Latin Extended-A (without deprecated character) - https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_Extended-A
  "\u0100-\u0148" +
  "\u014A-\u017F" +
  // Cyrillic symbols: \u0410-\u044F - https://en.wikipedia.org/wiki/Cyrillic_script_in_Unicode
  "\u0410-\u044F" +
  // hiragana (japanese): \u3040-\u309F - https://gist.github.com/ryanmcgrath/982242#file-japaneseregex-js
  "\u3040-\u309F" +
  // katakana (japanese): \u30A0-\u30FF - https://gist.github.com/ryanmcgrath/982242#file-japaneseregex-js
  "\u30A0-\u30FF" +
  // For an advanced explaination about Hangul see https://github.com/draft-js-plugins/draft-js-plugins/pull/480#issuecomment-254055437
  // Hangul Jamo (korean): \u3130-\u318F - https://en.wikipedia.org/wiki/Korean_language_and_computers#Hangul_in_Unicode
  // Hangul Syllables (korean): \uAC00-\uD7A3 - https://en.wikipedia.org/wiki/Korean_language_and_computers#Hangul_in_Unicode
  "\u3130-\u318F" +
  "\uAC00-\uD7A3" +
  // common chinese symbols: \u4e00-\u9eff - http://stackoverflow.com/a/1366113/837709
  "\u4e00-\u9eff" +
  // Arabic sybmols
  "\u0600-\u06ff" +
  "]*";

const sideToolbarPlugin = createSideToolbarPlugin({
  position: "right",
  theme: {
    buttonStyles: SideToolbarButtonStyles,
    blockTypeSelectStyles: SideToolbarBlockTypeSelectStyles,
    toolbarStyles: SideToolbarToolbarStyles
  }
});

const loadSavedDrafts = (key:string) => {
  if (!localStorage) return null;

  let savedContent = localStorage.getItem(key);
  if (! savedContent) return null;

  try{
    savedContent = JSON.parse(savedContent)
    if (! savedContent) return null;

    return OrderedMap(savedContent)
  }
  catch(e){
    console.log('Someting went wring parsing ', e)
  }
  return null
}
const localStorageSetItem = (key:string, value: any) => {
  try{
    localStorage.setItem(key, value)
  }catch(err){
    if(err.name === 'QuotaExceededError'){
      const yes = window.confirm("لقد تم نفاذ مساحة التسجيل، هل تريد أن تمسح كل تسجيلتك لهذا المقال؟")
      if(yes){
        localStorage.removeItem(key)
        localStorage.setItem(key, value)
      }
    }
  }
}
const restoreSavedDraft = (onChange: any, offset = 0) => {
  const key = 'draft_content'
  let orderedDrafts = loadSavedDrafts(key)
  if(! orderedDrafts) return null
  if(offset > 0){
    //@ts-ignore
    orderedDrafts = orderedDrafts.slice(0, offset)
  }
  if(! orderedDrafts) return null
  const  lastDraft = orderedDrafts.last()
  if(! lastDraft) return null
  const editorState = EditorState.createWithContent(
    //@ts-ignore
    convertFromRaw(lastDraft)
  );
  onChange(editorState)
  //@ts-ignore
  //Removing first key
  orderedDrafts = orderedDrafts.delete(orderedDrafts.keySeq().last())
  localStorageSetItem(key, JSON.stringify(orderedDrafts.toJS()));
};

const saveDraft = (editorState: any) => {
  const timestamp = +new Date()
  const currentContent = editorState.getCurrentContent()
  const rawContent = convertToRaw(currentContent);
  console.log('Save Draft', rawContent)
  const key = 'draft_content'
  let orderedDrafts = loadSavedDrafts(key)

  if(! orderedDrafts) {
    //@ts-ignore
    orderedDrafts = OrderedMap({ [timestamp]: rawContent })
    //@ts-ignore
    localStorageSetItem(key, JSON.stringify(orderedDrafts.toJS()));
    return;
  }
  orderedDrafts = orderedDrafts.set(String(timestamp), rawContent)
  localStorageSetItem(key, JSON.stringify(orderedDrafts.toJS()));
};
const autosavePlugin = createAutosavePlugin({
  saveFunction: saveDraft,
  debounceTime: 500
});

const { SideToolbar } = sideToolbarPlugin;

const videoPlugin = createVideoPlugin({
  videoComponent: VideoEmbedComponent
});


const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const alignmentPlugin = createAlignmentPlugin({
  theme: {
    alignmentToolStyles: {...AlignmentToolStyles},
    buttonStyles: {...AlignementButtons}
  }
});
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });



// const plugins = [mentionPlugin, toolbarPlugin, sideToolbarPlugin,
//   imagePlugin, resizeablePlugin, videoPlugin, embedPlugin, sidebarPlugin];
const { InlineToolbar } = inlineToolbarPlugin;



interface ColorPickerToolbarProps {
  [x: string]: any;
}
class ColorPickerToolbar extends React.Component<ColorPickerToolbarProps> {
  handleChange(color: ColorResult): void {
    this.setColor(color.hex);
  }
  setColor(color: string) {
    const editorState = this.props.getEditorState();
    const currentStyle = editorState.getCurrentInlineStyle();
    if (!currentStyle.has(color)) {
      const safeName = color.replace("#", "").toUpperCase();
      this.props.setEditorState(
        RichUtils.toggleInlineStyle(editorState, `color-${safeName}`)
      );
      // Remove the picker from rendering after selecting a color
      this.props.onOverrideContent(null);
    }
  }
  isVisible() {
    //@ts-ignore
    const selection = this.props.getEditorState().getSelection();
    // To close the ColorPicker if the user changed the selection
    if (selection.isCollapsed() || !selection.getHasFocus()) {
      const timeoutId = setTimeout(() => {
        this.props.onOverrideContent(null);
        clearTimeout(timeoutId);
      });
    }
  }
  render() {
    return (
      <TwitterPicker
        colors={colorsPicker}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}
class ColorPickerButton extends React.Component {
  // When using a click event inside overridden content, mouse down
  // events needs to be prevented so the focus stays in the editor
  // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
  onMouseDown = (event: any) => event.preventDefault();

  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    //@ts-ignore
    this.props.onOverrideContent(ColorPickerToolbar);

  render() {
    return (
      //@ts-ignore
      <div onMouseDown={this.onMouseDown} className={this.props.theme.buttonWrapper}>
        {/* 
          // @ts-ignore */}
        <button onClick={this.onClick} className={this.props.theme.button}>
          <svg
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="26px"
            height="30px"
            viewBox="0 0 431.985 431.985"
          >
            <g>
              <path
                d="M423.257,51.829c-0.808-2.045-2.67-3.484-4.853-3.751c-2.177-0.266-4.335,0.682-5.612,2.472
		c-7.581,10.629-17.529,14.172-29.053,18.275c-9.292,3.31-18.901,6.73-29.286,14.186c-14.687,10.544-21.405,24.917-18.055,38.54
		l-0.358,0.459c-6.133-8.897-12.806-17.126-19.848-24.474c-32.947-34.378-78.984-55.046-126.311-56.703
		c-2.085-0.073-4.204-0.11-6.298-0.11c-52.846,0-103.428,23.624-138.775,64.813C9.646,146.512-5.939,199.853,2.051,251.882
		c0.668,4.349,1.504,8.743,2.487,13.063c12.996,57.202,46.189,100.717,91.069,119.383c11.063,4.602,22.222,6.934,33.163,6.934
		c27.183,0,50.926-14.539,65.143-39.889c5.404-9.646,8.891-19.621,10.36-29.651c0.866-5.92,0.274-11.835-0.3-17.567
		c-0.591-5.9-1.149-11.476-0.256-17.09c2.047-12.869,11.036-20.553,24.047-20.553c3.701,0,7.483,0.609,11.26,1.812
		c-4.422,8.11-8.438,15.854-11.947,23.032c-7.437,15.212-12.567,27.81-15.252,37.44c-1.655,5.939-6.052,21.722,4.67,29.164
		c3.405,2.363,7.722,3.197,12.215,2.361c4.049-0.752,16.369-3.041,51.378-42.896c9.396-10.695,19.521-23.072,30.104-36.794
		c27.168-9.15,48.31-31.921,53.903-58.087c1.4-6.541,1.984-13.541,1.735-20.812c10.172-15.72,19.094-30.388,28.072-46.156
		c0.172-0.304,0.342-0.628,0.51-0.96c13.031-3.569,24.254-13.71,30.842-27.891C434.872,106.028,434.163,79.428,423.257,51.829z
		 M276.385,149.834c-4.713,7.485-12.814,11.954-21.673,11.954c-4.81,0-9.515-1.361-13.605-3.937
		c-5.782-3.642-9.803-9.317-11.316-15.98s-0.345-13.518,3.298-19.301c4.714-7.485,12.816-11.954,21.675-11.954
		c4.811,0,9.515,1.361,13.604,3.938c5.782,3.64,9.802,9.315,11.316,15.979C281.197,137.197,280.026,144.051,276.385,149.834z
		 M309.592,196.187c12.934-19.057,26.612-38,39.604-54.85c2.106,1.902,4.461,3.76,7.012,5.53c4.227,2.933,8.648,5.201,13.164,6.754
		c-10.969,18.758-22.763,37.342-37.043,58.375c-23.463,34.571-47.859,66.684-68.695,90.424
		c-11.638,13.26-21.823,23.498-29.671,29.839c3.029-9.69,8.818-22.989,16.875-38.746
		C265.245,265.336,286.111,230.772,309.592,196.187z M82.506,196.023c-4.811,0-9.516-1.361-13.607-3.938
		c-5.782-3.641-9.801-9.314-11.315-15.979c-1.514-6.664-0.342-13.519,3.301-19.302c4.711-7.484,12.813-11.953,21.671-11.953
		c4.812,0,9.517,1.361,13.607,3.938c11.936,7.518,15.532,23.345,8.019,35.279C99.466,191.554,91.363,196.023,82.506,196.023z
		 M55.688,252.358c4.713-7.486,12.814-11.955,21.673-11.955c4.81,0,9.514,1.362,13.606,3.938c5.782,3.641,9.801,9.315,11.315,15.979
		c1.515,6.662,0.343,13.516-3.301,19.301c-4.711,7.483-12.813,11.953-21.671,11.953c-4.811,0-9.517-1.361-13.609-3.938
		c-5.782-3.642-9.8-9.315-11.313-15.979C50.876,264.995,52.049,258.14,55.688,252.358z M137.62,100.414
		c4.713-7.485,12.815-11.954,21.673-11.954c4.809,0,9.514,1.361,13.604,3.937c11.937,7.516,15.533,23.344,8.019,35.28
		c-4.715,7.486-12.817,11.955-21.675,11.955c-4.81,0-9.515-1.361-13.605-3.938c-5.781-3.64-9.799-9.314-11.313-15.979
		C132.807,113.052,133.979,106.198,137.62,100.414z"
              />
            </g>
          </svg>
        </button>
      </div>
    );
  }
}

//@ts-ignore
const styles = (theme: any) => ({
  control: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  //@ts-ignore
  mentionContainer: {
    border: '1px solid #eee',
    marginTop: '0.4em',
    position: 'absolute',
    minWidth: 220,
    maxWidth: 440,
    background: '#fff',
    borderRadius: 2,
    boxShadow: '0px 4px 30px 0px rgba(220, 220, 220, 1)',
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    transform: 'scale(0)',
    maxHeight: 250,
    overflow: 'scroll'
  }
});

const styleMap = {
  'SPELLCHECKERROR': {
    backgroundColor: '#fee481'
  },
};
class App extends React.Component<TextProps, TextState> {
  convertFromHtml = (htmlContent: any) => {
    //@ts-ignore
    return convertFromHTML(htmlContent);
  };
  mentionPlugin: any;
  private editor = createRef<any>();
  constructor(props: TextProps) {
    super(props);
    this.mentionPlugin = createMentionPlugin({
      mentionRegExp: regExpLat,
      supportWhitespace: true,
      theme: {
        ...defaultTheme,
        mentionSuggestions: props.classes.mentionContainer
      },
      mentionComponent: (mentionProps: any) => {
        //TODO: no mention data in here (please check customInlineFn)
        return (
          <Tooltip title={mentionProps.decoratedText}>
            <span className={mentionProps.className}>{mentionProps.children}</span>
          </Tooltip>
        );
      }
    });
    //@ts-ignore
    let initialHtml = props.input.value

    if (! initialHtml) {
      this.state = {
        editorState: EditorState.createEmpty(), // for empty content
        suggestions: [],
        hasSpellingErrors: false,
        openSpellDialog: false,
      };
      // Just to not to proceed
      return this;
    }
    //@ts-ignore
    const contentState = this.convertFromHtml(initialHtml);
    let editorState = EditorState.createWithContent(contentState);

  

    this.state = {
      hasSpellingErrors: false,
      openSpellDialog: false,
      //@ts-ignore
      // editorState: EditorState.createWithContent(convertFromRaw(initialState)),
      editorState: editorState, // for empty content
      // editorState: EditorState.createEmpty(), // for empty content
      suggestions: []
    };
  }
  onChange = (editorState: any) => {
    this.setState({ editorState });
    // const html = convertToHTML(editorState.getCurrentContent())
    // this.props.input.onChange(html);
  };
  componentDidMount() {
    // this.focus()
  }
  componentDidUpdate(prevProps:any) {
    //@ts-ignore
    let initialHtml = this.props.input.value
    if (!prevProps.input.value && initialHtml) {
      const contentState = this.convertFromHtml(initialHtml);
      let editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState })
    }
  }
  onSearchChange = async (search: any) => {
    // TODO: Make this Query API from dataProvider
    const { value } = search;
    if(value.length < 3) return; 
    const URI = `${process.env.REACT_APP_API_ENDPOINT}/search/autocomplete_tags`;
    const response = await fetch(`${URI}/?q=${value}`)
    let results = await response.json()
    results = results.map((r:any) => ({
      name: r.name,
      url: r.url,
      link: r.url,
      avatar: r.image,
      type: r.content_type,
      id: r.object_id
    }));

    this.setState({
      suggestions: results
    });
  };

  focus = () => {
    const node = this.editor.current;
    if (node) {
      node.focus();
      //@ts-ignore
      this.props.input.onFocus();
    }
  };
  handleBlur = (e:React.FormEvent<HTMLInputElement>) => {
    if(this.state.hasSpellingErrors){
      e.preventDefault()
      this.setState({ openSpellDialog: true })
    }
    if(this.props.input){
      //@ts-ignore
      const { editorState } = this.state
      const html = convertToHTML(editorState.getCurrentContent())
      this.props.input.onChange(html);
      //@ts-ignore
      this.props.input.onBlur(e)
    }
  }
  componentDidCatch(error:any, info:any) {
    switch(error.name){
      case "Invariant Violation":
        return restoreSavedDraft(this.onChange, -1)
      case "QuotaExceededError":
        console.log('componentDidCatch', error)
    }
  }
  render() {
    const { editorState } = this.state;
    const { classes, lang } = this.props;
    
    const { MentionSuggestions } = this.mentionPlugin;

    const plugins = [
      this.mentionPlugin,
      inlineToolbarPlugin,
      sideToolbarPlugin,
      videoPlugin,
      imagePlugin,
      autosavePlugin,
      focusPlugin,
      alignmentPlugin,
      resizeablePlugin,
      tagLinkPlugin,
      hrefLinkPlugin
    ];
    
    return (
      <div>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <Button
              className={classes.control}
              variant="contained"
              size="small"
              aria-label="Delete"
              //@ts-ignore
              onClick={restoreSavedDraft.bind(this, this.onChange)}
            >
              إرجاع آخر مقال
              <UndoIcon />
            </Button>
          </Grid>
        </Grid>

        <div className={editorStyles.editor} onClick={this.focus}>
          <Editor
            placeholder="نص المقال"
            textAlignment={lang === "fr" ? "left" : "right"}
            editorState={editorState}
            onChange={this.onChange}
            plugins={plugins}
            customStyleMap={colorStyleMap}
            ref={this.editor}
            //@ts-ignore
            onFocus={this.props.input.onFocus}
            //@ts-ignore
            onBlur={this.handleBlur}
            //@ts-ignore
          />
          <AlignmentTool />
        </div>
        <InlineToolbar>
          {// may be use React.Fragment instead of div to improve perfomance after React 16
          (externalProps: any) => (
            <div>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
              <ColorPickerButton {...externalProps} />
              <tagLinkPlugin.LinkButton {...externalProps} lang={lang} />
              <hrefLinkPlugin.LinkButton {...externalProps} />
            </div>
          )}
        </InlineToolbar>
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
        />
        <div>
          <SideToolbar>
            {// may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps: any) => {
              return (
                <div>
                  <SpellCheckerButton {...externalProps} onSpellingError={(hasError:boolean) => { 
                    this.setState({ hasSpellingErrors: hasError })
                  }} />
                  <ImageAdd
                    {...externalProps}
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    modifier={imagePlugin.addImage}
                  />
                  <EmbedButton
                    {...externalProps}
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    modifier={embedPlugin.addEmbed}
                  />
                </div>
              );
            }}
          </SideToolbar>
          <SpellCheckDialog open={this.state.openSpellDialog} onClose={() => { this.setState({ openSpellDialog: false, hasSpellingErrors: false  }) }} />
        </div>
      </div>
    );
  }
}
//@ts-ignore
export default addField(withStyles(styles)(App));
