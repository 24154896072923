import React from 'react';
import {
    Edit, SimpleForm, TextInput ,translate,
    ImageField, ImageInput, BooleanInput,
    required, SelectInput
} from 'react-admin';
import { connect } from "react-redux";
import TagsInput from '../../inputs/TagsInputs';
import { DateTimeInput } from "react-admin-date-inputs";
import _ from 'lodash'
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import TagQuickCreation from '../tags/TagQuickCreationButton';
import CountrySearchInput from '../../inputs/CountrySearchInput';
import SportSearchInput from '../../inputs/SportSearchInput';


const styles = {
    inputLabel: {
      '& label':{
        transformOrigin: "top right",
        right: 0,
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".8rem",
      },
      '& input':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".9rem",
      },
      '& textarea':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".9rem",
      },
      '& .previews>div':{
        float: "right" as 'right',
      }
    },
    midSizedInput:{
      width: "50%",
    },
    largeSizedInput:{
      width: "70%",
    },
    smallSizedInput:{
      width: 130,
    },
    coloredLabel: {
      '& label':{
        fontWeight:700,
      }
    },
    boldText:{
      fontWeight: 700,
      color: "#687c8c",
      '& input':{
        color: "#687c8c",
      }
    },
    txtColor:{
      color:"red",
    },
    radioBtn:{
      '& label':{
        flexDirection: "row-reverse" as 'row-reverse',
        justifyContent: "flex-end",
        margin: 0,
        '&>span:first-child':{
          direction:"ltr" as 'ltr',
        },
        '&>span:last-child':{
          width: 100,
          display: "inline-block",
        }
      },
      '& span':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      }
    },
  
    goalsList:{
      '& p':{
        display:"none",
      },
      '& li':{
        marginBottom: 30,
        borderBottom: "none",
      }
    },
    isLTR:{
      direction: 'ltr' as 'ltr',
    }
  }

  const CustomTitle = (props:any) => {
    return (
      <span style={{ fontSize: "1.2rem", lineHeight: "250%"}}>تعديل فيديو "{props.record.title}"</span>
    )
  }
  


const VideoEdit = (props: any) => {
    const classes = props.classes;

    const onTagQuickCreate = (tag:any) => {
      props.form['record-form'].values.tags.push(tag)
      _.set(props, 'form.record-form.anyTouched', true)
      _.set(props, 'form.record-form.fields.tags', {visited: true, touched: true})
    }
    return (
        <Edit {...props} title={<CustomTitle/>}>
            <SimpleForm defaultValue={{ lang: "ar" }} handleSubmit={(Submit: any)  => { 
                if (_.get(props, 'form.record-form.syncErrors')){
                  return
                }
                const anyTouched = _.get(props, 'form.record-form.anyTouched', null)
                if(anyTouched){
                    const fields = _.get(props, 'form.record-form.fields', {})
                    const dirtyValues: { [x:string]: any } = {}
                    Object.keys(fields).map((field:string) => {
                        dirtyValues[field] = props.form['record-form'].values[field]
                    })             
                    Submit(dirtyValues)
                }
            }}>
                <SelectInput source="lang" choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />

                <TextInput
                  source="title"
                  className={ classNames(classes.inputLabel, classes.midSizedInput) }
                  validate={required()}
                />
                <TextInput
                  source="url"
                  className={ classNames(classes.inputLabel, classes.isLTR, classes.midSizedInput) }
                />
                <TextInput
                  source="description" 
                  multiline 
                  className={ classNames(classes.inputLabel, classes.midSizedInput) } validate={required()}
                />
                <ImageInput source="w_image" accept="image/*" className={ classNames(classes.inputLabel)} >
                    <ImageField source="default_path" title="title"/>
                </ImageInput>

                <TextInput
                  source="embed"
                  multiline 
                  className={ classNames(classes.inputLabel, classes.isLTR, classes.largeSizedInput) }
                  validate={required()}
                />

                <TagsInput name="tags" source="tags" required/>
                <TagQuickCreation onTagCreate={onTagQuickCreate} />
                <CountrySearchInput
                    className={ classes.inputLabel } 
                    required
                />

                <SportSearchInput
                    className={ classes.inputLabel } 
                    required
                />

                <DateTimeInput 
                    source="pub_date" 
                    label='تاريخ النشر'
                    className={ classNames(classes.inputLabel, classes.smallSizedInput) } 
                    options={{ 
                        format: 'd/MM/YYYY HH:mm', 
                        ampm: false
                    }}
                />

                <BooleanInput source="is_important" />
                <BooleanInput source="on_slideshow"/>
                <BooleanInput source="on_newsfeed"/>
                <BooleanInput source="with_copyright"/>
                <BooleanInput source="historical"/>
                <BooleanInput source="trending"/>
                <BooleanInput source="is_global"/>

            </SimpleForm>
        </Edit>
    )
}

const VideoEditView = translate(VideoEdit)

const VideoEditForm = withStyles(styles)(VideoEditView)

export default connect(
  (state: any, props: any) => {
    return state
  },
  {}
)(VideoEditForm);