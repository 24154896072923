import _ from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ar-ma'

dayjs.locale('ar-ma') // use loaded locale globally
dayjs.extend(customParseFormat)
dayjs.extend(utc)


class Team {
  private model : { [x:string]: any }
  constructor(team : { [x:string]: any }){
    this.model = team
  }
  public getInstance(){
    return {
      logo: this.getLogo(),
      country: {
        name: this.model.country,
        country_id: this.model.country_id,
      },
    }
  }
  getLogo(){
    return `${process.env.REACT_APP_MEDIA_URL}/stats/logos/${this.model.contestant_id}.png`
  }

  transformFields() {
    if (this.model['country']) {
      this.model = {
        ...this.model,
        'country': this.model['country']['name'],
        'country_fr': this.model['country']['name_fr'],
        'country_en': this.model['country']['name_en'],
        'country_id': this.model['country']['country_id'],
      }
    }
    return { ...this.model }
  }
}

export default Team
