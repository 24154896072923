// in src/posts.js
import React from 'react';
import {
  Create, SimpleForm, TextInput,
  ImageInput, ImageField,
  FormDataConsumer, required, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
// import CountryDropdown from '../../inputs/CountryDropdown';
import RichTextEditor from '../../inputs/RichTextEditor';
import { Grid } from '@material-ui/core';
import { DateTimeInput } from "react-admin-date-inputs";
import TagsInput from '../../inputs/TagsInputs';
import CustomAutoComplete from '../../inputs/CustomAutoComplete';
import { withStyles } from '@material-ui/core/styles';
import ImageCropper from '../../inputs/ImageCropper/ImageCropper';
import { connect } from 'react-redux';
import EBooleanInput from '../../inputs/BooleanInput';
import { translate } from 'react-admin';
import LiveSpreadsheetComponent from '../../components/LiveSpreadsheetComponent';
import CountrySearchInput from '../../inputs/CountrySearchInput';
import SportSearchInput from '../../inputs/SportSearchInput';
import { getDefaultFilterLang } from '../../filters/ContentFilter';

const styles = (theme: any) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  spreadsheetButtonToggle: {
    height: 200
  }
});


const ArticleCreate = (props: any) => {
  const { classes } = props
  let defaultLang = getDefaultFilterLang()
  defaultLang = defaultLang == 'fr' ? 'fr' : 'ar'
  return <Create {...props} title="إنشاء مقال">
    <SimpleForm defaultValue={{ lang: defaultLang, on_newsfeed: true, with_watermark: false, ...props.defaultValue }} redirect="list">
      <SelectInput source="lang" initialValue={'ar'} choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
      <TextInput source="title" fullWidth validate={required()} />
      <TextInput source="custom_author" />

      <FormDataConsumer>
        {({ formData, ...rest }: { [x: string]: any }) =>
          <RichTextEditor source="html_content" lang={formData.lang}/>
        }
      </FormDataConsumer>

      <ImageInput source={"w_image"} accept="image/*" validate={required()}>
        <ImageField source={"default_path"} title="title" />
      </ImageInput>
      <FormDataConsumer>
        {({ formData, ...rest }: { [x: string]: any }) => (formData && formData.w_image) &&
          <ImageCropper name="crop_area" source="crop_area" image={formData.w_image.default_path} />
        }
      </FormDataConsumer>
      <TextInput
        multiline
        rows={2}
        maxRows={4}
        source="image_description"
        fullWidth
      />
      <EBooleanInput source="with_watermark" />
      <DateTimeInput
        source="pub_date"
        label={props.translate(`resources.${process.env.REACT_APP_ARTICLE_RESOURCE}.fields.pub_date`)}
        options={{ format: 'd/MM/YYYY HH:mm', ampm: false }}
        validate={required()}
      />
      <TagsInput className={classes.control} label="Tags" required />
      <Grid container spacing={40}>
        <Grid item>
          <CountrySearchInput
            className={classes.inputLabel}
            required
            _view={"CREATE_ARTICLE"}
          />
        </Grid>
        <Grid item>
          <SportSearchInput
            className={classes.inputLabel}
            required
            _view={"CREATE_ARTICLE"}
          />
        </Grid>
      </Grid>

      <EBooleanInput source="is_important" />
      <EBooleanInput source="is_global" />
      <EBooleanInput source="on_newsfeed" />
      <EBooleanInput source="on_slideshow" />

      <TextInput
        source="description"
        label="Description"
        multiline
        rows={2}
        maxRows={4}
        fullWidth
      />

      <LiveSpreadsheetComponent />
    </SimpleForm>
  </Create>
};

// const StyledArticleCreate = withStyles(styles)(translate(ArticleCreate))

// export default StyledArticleCreate;

export default connect(
  (state: any, props: any) => {
    let defaultValue = {}
    try {
      defaultValue = window.history.state.defaultValue 
    }
    catch (err) {}
    
    return { ...state, defaultValue, dirtyFields: {html_content: {visited: true, touched: true}}}
  },
  {}
)(withStyles(styles)(translate(ArticleCreate)));