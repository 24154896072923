import _ from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ar-ma'

dayjs.locale('ar-ma') // use loaded locale globally
dayjs.extend(customParseFormat)
dayjs.extend(utc)


class Video {
  private model : { [x:string]: any }
  constructor(video : { [x:string]: any }){
    this.model = video
  }
  public getInstance(){
    return {
      pub_date: this.model.pub_date * 1000,
      w_image: this.model['image']
    }
  }

  public transformFields() {
    const w_fields:{ [x:string]: string } = {'tags': 'w_tags', 'country': 'w_country', 'sport': 'w_sport'}
  
    const formData = new FormData()
    Object.keys(this.model).map(field => {
      let value = this.model[field]
      if(field == 'tags'){
        value = value.map((item:any) => ( _.pick(item, [
          "object_id", "content_type", "name", "name_fr", "name_en"
        ])))
      }
      if(value instanceof Date){
        value = dayjs.utc(value).format()
      }
      else if (field == "w_image"){
        value = value['rawFile']
      }
      else{
        value = (typeof value == "string" ) ? value : JSON.stringify(value)
      }
      if(w_fields[field]){
        formData.append(w_fields[field], value)
      }
      else {
        formData.append(field, value)
      }
      
    })
    return formData
  }
}

export default Video
