import React from 'react';
import { connect } from 'react-redux';
import {
  Edit, SimpleForm, DisabledInput, TextInput, NumberInput,
  FormDataConsumer, SelectArrayInput, BooleanInput
} from 'react-admin';
import _ from 'lodash';
import AnalyticsLogoInput from '../../inputs/AnalyticsLogoInput'

const CustomTitle = (props:any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>تعديل معلومات مسابقة {props.record.name}</span>
  )
}

const CompetitionEdit = (props: any) => {
  const getLogoProps = (p:any) => (
    {
      logo: p.record.logo,
      content_type: "competition",
      object_id: p.record.competition_id
    }
  )
  return <Edit  {...props} title={<CustomTitle/>}>
      <SimpleForm handleSubmit={(Submit: any) => {
        if (props.dirtyFields && props.form) {
          const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, n: any) => {
            let key = n
            let value = props.form["record-form"].values[n]
            return { ...p, [key]: value }
          }, {})
          console.log('DIRTY FIELDS', dirtyValues)
          return Submit(dirtyValues)
        }
        return Submit
      }}>
        <AnalyticsLogoInput getAltProps={getLogoProps} customLabel='شعار المسابقة'/>
          <DisabledInput label="Id" source="competition_id" />
          <DisabledInput source="name" />
          <DisabledInput source="name_en" />
          <DisabledInput source="name_fr" />
          <TextInput source="custom_name" />
          <DisabledInput source="competition_format" />
          <DisabledInput source="country" />
          <DisabledInput source="type_name" />
          <DisabledInput source="competition_type" />
          <NumberInput source="display_order" />
          <NumberInput source="followers_count" />
          <BooleanInput source="show_calendar" label="عرض في المباريات" />
          <FormDataConsumer>
              {
                (dataProps: any) => {
                  if(! dataProps.record.updated_keys ){
                    return null
                  }
                  return (
                    <SelectArrayInput
                      label="updated_keys"
                      source={"updated_keys"}
                      choices={dataProps.record.updated_keys.map((k:string) => ({ id: k, name: k}))}
                    />
                  )
                }
              }
            </FormDataConsumer>
      </SimpleForm>
  </Edit>
};

export default connect(
  (state: any, props: any) => {
    if (state && state.form && state.form['record-form'] && state.form['record-form'].anyTouched) {
      return { ...state, dirtyFields: state.form['record-form'].fields }
    }
    return state
  }, {} )(CompetitionEdit)