import React from "react";
import {
  SelectInput,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  required
} from "react-admin";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SquadPlayerSearchInput from '../inputs/SquadPlayerSearchInput';


const styles = {
  isLTR: {
    direction: 'ltr' as 'ltr',
  },
  midSizedInput: {
    width: "50%",
  },
  inputLabel: {
    '& label': {
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".74rem",
    },
    '& input': {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".84rem",
    },
    '& textarea': {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".84rem",
    }
  },
  coloredLabel: {
    '& label': {
      fontWeight: 700,
    }
  },
  goalsList: {
    width: "100%",
    '& p': {
      display: "none",
    },
    '& li': {
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  root: {
    marginTop: 30,
    '&>div': {
      display: 'flex' as 'flex',
      flexWrap: 'wrap' as 'wrap',
      width: "100%",
    },
    '& div': {
      width: "100%",
    },
    // exclude Add BTN from being impacted by Remove BTN custom styling
    '&>li':{
      '&>span':{
        position: "initial" as 'initial',
        '& button': {
          padding: "7px 8px",
          '&>span':{
            width: "inherit",
            overflow: 'hidden',
            '&>svg':{
              padding: "auto",
              left: "auto",
              position: 'inherit' as 'inherit',
            }
          }
        }
      }
    }
  }, //wrapper
  line: {
    paddingRight:"16px",
    paddingLeft:"16px",
    width: "33.33%",
    boxSizing: "border-box" as 'border-box',
    position:"relative" as 'relative',

    // remove btn (within the iterator)
    '& >span':{
      position: "absolute" as 'absolute',
      left: 10,
      '& button': {
        padding: 0,
        '&>span':{
          width: 20,
          overflow: 'hidden' as 'hidden',
          '&>svg':{
            padding: 11,
            left: -16,
            position: 'relative' as 'relative',
            color: "#cccccc",
          }
        }
      }
    }
  }, //li
  form: {
    padding: 24,
    boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
    '& .ra-input':{
      marginTop: 16
    }
  }, //section
}


const MatchGoalsInput = (props: any) => {
  const classes = props.classes;

  return (
    <ArrayInput label="أهداف المباراة" source="goal" className={classes.goalsList}>
        <SimpleFormIterator classes={classes}>
            <FormDataConsumer>
                {
                (dataProps: any) => {
                    const selectedCtst = dataProps.scopedFormData ? dataProps.scopedFormData.contestant_id : null
                    return (
                      <>
                    <RadioButtonGroupInput 
                      style={{ width: '100%'}}
                      classes= { classes.radioChoices }
                      source={dataProps.getSource("contestant_id")}
                      label="هدف لصالح" choices={[
                        { id: dataProps.formData.contestant[0].id, name: dataProps.formData.contestant[0].name  },
                        { id: dataProps.formData.contestant[1].id, name: dataProps.formData.contestant[1].name },
                      ]} 
                      options={{ 
                        row: 'true', 
                        classes: classes.radioChoices 
                      }}
                      validate={required()}
                    />
                    <SquadPlayerSearchInput
                          formClassName={classes.wrapper}
                          className={classes.radioChoices}
                          name={dataProps.getSource("scorer")}
                          squad={props.squads[selectedCtst]}
                          label="مسجل الهدف"
                          fullWidth
                          required={true}
                      />
                    </>
                  )
                }

                }
            </FormDataConsumer>
            
            <SelectInput
                label="نوع الهدف"
                source="type"
                choices={[
                { id: 'G', name: 'هدف' },
                { id: 'OG', name: 'هدف ضد مرماه' },
                { id: 'PG', name: 'ضربة جزاء' },
                ]}
                validate={required()}
            />
            
            <NumberInput label="دقيقة الهدف" source="time_min" validate={required()}/>
            <SelectInput
              label="مرحلة المباراة"
              source={"period_id"}
              choices={[
                { id: 1, name: "شوط الأول" },
                { id: 2, name: "شوط الثاني"  },
                { id: 3, name: "شوط الإضافي الأول"  },
                { id: 4, name: "شوط الإضافي الثاني"  },
              ]}
              validate={required()}
            />
            {/* <TextInput label="رابط الهدف"
                source="url"
                className={classNames(classes.inputLabel, classes.coloredLabel, classes.isLTR, classes.midSizedInput)}
                multiline
            /> */}
        </SimpleFormIterator>
    </ArrayInput>
    );
};



export default withStyles(styles)(MatchGoalsInput)
