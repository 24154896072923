import React from 'react';
import { connect } from "react-redux";
import { List, Datagrid, TextField, translate, SelectField, EditButton } from 'react-admin';
import _ from 'lodash'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ExpandableMenu from '../../components/SocialSharing';
import PubDateField from '../../fields/CustomDateField';
import ContentFilter, { getDefaultFilterLang } from '../../filters/ContentFilter';


const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  editBtn:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem",
    color: "#fff",
    backgroundColor: "#25A9FE",
    '& svg': { 
      marginLeft: 7,
      fontSize: 16,
      color: "#fff",
    },
    '&:hover': {
      color: "#25A9FE",
      backgroundColor: "#f1f1f1",
      '& svg': { 
        color: "#25A9FE",
      }
    }
  },
}


const VideoList = (props: any,  state: any) => {
  const classes = props.classes;
  const filterDefaultValues = {
    lang: getDefaultFilterLang()
  }
  return (
    <List {...props} title="قائمة الفيديوهات" perPage={15}
      exporter={false} sort={{ field: 'pub_date', order: 'DESC' }}
      filters={<ContentFilter />} filterDefaultValues={filterDefaultValues}
    >
        <Datagrid>
          <PubDateField source="pub_date" label="تاريخ النشر" headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
          <TextField source="title"  className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
          <TextField source="author.username" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
          <TextField source="country.name"  className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
          <SelectField source="is_important"  className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
              choices={[
                { id: true, name: 'نعم' },
                { id: false, name: 'لا' },
              ]} 
            />
          <EditButton
            resource="videos"
            basePath={"videos"}
            identifier="video_id"
            label="تعديل"
            className={classes.editBtn}
            headerClassName={classNames(classes.tableHeader,classes.alignRight)}
          />
          <ExpandableMenu />
        </Datagrid>
    </List>
  )
};



const VideoListView = translate(VideoList)


const VideoListViewComponent = withStyles(styles)(VideoListView)


export default connect((state: any, props: any) => {
    return state
}, {})
(VideoListViewComponent);