class JobExecution {
    private model: { [x: string]: any }
    constructor(jobExecution: { [x: string]: any }) {
        this.model = jobExecution
    }
    public getInstance() {
        return {
            start_time: this.model.start_time * 1000,
            create_time: this.model.create_time * 1000,
            end_time: this.model.end_time * 1000,
            date_created: this.model.date_created * 1000,
            date_modified: this.model.date_modified * 1000,
        }
    }

}

export default JobExecution