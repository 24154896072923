import React from 'react';
import { Edit, SimpleForm, TextInput, required} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';



const styles = {

  inputLabel: {
    width:'50%',
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    },
    '& textarea':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".9rem",
    },
  },
  biggerTag:{
    '& label':{
      fontSize:".85rem",
    }
  },
  largeSizedInput:{
    width: "70%",
  },
  coloredLabel: {
    '& label':{
      fontWeight:700,
    }
  },
  boldText:{
    fontWeight: 700,
    color: "#687c8c",
    '& input':{
      color: "#687c8c",
    }
  },
  txtColor:{
    color:"red",
  },
  radioBtn:{
    '& label':{
      flexDirection: "row-reverse" as 'row-reverse',
      justifyContent: "flex-end",
      margin: 0,
      '&>span:first-child':{
        direction:"ltr" as 'ltr',
      },
      '&>span:last-child':{
        width: 100,
        display: "inline-block",
      }
    },
    '& span':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    }
  },

  goalsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  imgWrapper:{
    width:"50%"
  },
  notifImg:{
    width: "100%"
  },
  tagsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  isLTR:{
    direction: 'ltr' as 'ltr',
  }
}

const ImgInput = (props: any) => {
  const classes = props.classes;
  const customLabel = props.customLabel;
  return (
    <div className={classes.imgWrapper}>
      <span style={{ display : "block", fontSize: ".70rem", color: "rgba(0, 0, 0, 0.54)", marginTop: "10px",  marginBottom: 5}}>{customLabel}</span>
      <img src={props.record[props.source]} className={classes.notifImg}/>
    </div>
  );
};

const NotificationImg = withStyles(styles)(ImgInput)


const CustomTitle = (props:any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>تعديل صفحة "{props.record.title}"</span>
  )
}

const FlatpageEdit = (props: any) => {
  const classes = props.classes;
  return(
  <Edit {...props} title={<CustomTitle/>}>
    <SimpleForm>
      <TextInput source="title" className={ classes.inputLabel } validate={required()} />
      <TextInput source="content" className={ classNames(classes.inputLabel,classes.isLTR, classes.largeSizedInput) } multiline validate={required()}/>
      <TextInput source="absolute_url" className={ classNames(classes.inputLabel,classes.isLTR) }/>
    </SimpleForm>
  </Edit>
)};

const FlatpageEditForm = withStyles(styles)(FlatpageEdit)

export default FlatpageEditForm