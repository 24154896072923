import Cookies from 'js-cookie';
import { stringify } from 'query-string';
import {
    CREATE, DELETE,
    DELETE_MANY, fetchUtils,
    GET_LIST, GET_MANY,
    GET_MANY_REFERENCE, GET_ONE, HttpError, UPDATE,
    UPDATE_MANY
} from 'react-admin';
import ChatRoomModel from '../models/ChatRoom';
import Competition from '../models/Competition';
import JobExecutionModel from '../models/JobExecution';
import Match from '../models/Match';
import NewsfeedModel from '../models/Newsfeed';
import NotificationModel from '../models/Notification';
import Player from '../models/Player';
import Squad from '../models/Squad';
import Standings from '../models/Standings';
import Team from '../models/Team';
import VideoModel from '../models/Video';
import Transfer from '../models/Transfer';

type RequestOptions = {
    method: string,
    credentials?: string,
    headers: Headers,
    body?: any
}
type Response = {
    json: any,
    headers: any
}
type ResponseError = { 
    status: number; 
    body: { [x: string]: any[]; }; 
}
type FormattedError = {
    message: string,
    status: number,
    errors: any
}

export type DataProvider = (
    type: string,
    resource: string,
    params: any,
    meta: HTTPMapper
) => Promise<any>;

type HTTPMapper = {
    convertToHTTP?: (data: any) => any
    convertToResponse?: (data: any) => any
}

type GetListQuery = {
    page?: any,
    page_size?: any,
    ordering?: any,
    offset?: any,
    limit?: any,
    // [x:string]: string
    
}

const customHttpClient = (url:string, options:{[x:string]: any} = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.append("X-CSRFToken", Cookies.get('csrftoken'));
    options.headers.append("X-referer", process.env.REACT_APP_DOMAIN_NAME);
    // sending cookies too with request
    options.credentials = 'include'

    return fetchUtils.fetchJson(url, options);
}


export const fetchContestantSquad = (contestantId: string) => {
   

    const request = new Request(
      `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_TEAM_RESOURCE}/${contestantId}/squad/`,
      {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include'
      }
    )
    return fetch(request)
      .then(response => (response.json()))
      .then(response => {
          let squad = []
          if (Array.isArray(response.person)) {
            squad = response.person
          }
          return squad
      })
}


// TODO: THIS DATA PROVIDER NEEDS TO E RENAMED,  
export const AnalyticsDataProvider = (requestHandler : DataProvider) => (type: string, resource: string, params: any) => {
    const meta : HTTPMapper = {}
    if (resource == `${process.env.REACT_APP_MATCH_RESOURCE}`) {
        resource = `${process.env.REACT_APP_MATCHES_RESOURCE}`
    }
    switch(resource){
        case `${process.env.REACT_APP_TRANSFER_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new Transfer(data)
                const mappedModel = model.transformFields()
                return { ...mappedModel, id: data._id }
            }
            meta.convertToResponse = (data: any) => {
                const model = new Transfer(data)
                const mappedModel = model.getInstance()
                return {...data, _id: data.id, id: data.transfer_id, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_PLAYER_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new Player(data)
                const mappedModel = model.transformFields()
                return { ...mappedModel, id: data._id }
            }
            meta.convertToResponse = (data: any) => {
                const model = new Player(data)
                const mappedModel = model.getInstance()
                return {...data, _id: data.id, id: data.player_id, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_TEAM_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new Team(data)
                const mappedModel = model.transformFields()
                return { ...mappedModel, id: data._id }
            }
            meta.convertToResponse = (data: any) => {
                const model = new Team(data)
                const mappedModel = model.getInstance()
                return {...data, _id: data.id, id: data.contestant_id, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_COMPETITION_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                return { ...data, id: data._id }
            }
            meta.convertToResponse = (data: any) => {
                const model = new Competition(data)
                const mappedModel = model.getInstance()
                return {...data, _id: data.id, id: data.competition_id, ...mappedModel}
            }
            break;
        case `${process.env.REACT_APP_MATCHES_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new Match(data)
                const mappedModel = model.transformFields()
                return { ...mappedModel, id: data._id }
            }
            meta.convertToResponse = (data: any) => {
                const model = new Match(data)
                const mappedModel = model.getInstance()
                return {...data, _id: data.id, id: data.match_id, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_VIDEO_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new VideoModel(data)
                const mappedModel = model.transformFields()
                return mappedModel
            }
            meta.convertToResponse = (data: any) => {
                const model = new VideoModel(data)
                const mappedModel = model.getInstance()
                return { ...data, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_ARTICLE_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new VideoModel(data)
                const mappedModel = model.transformFields()
                return mappedModel
            }
            meta.convertToResponse = (data: any) => {
                const model = new VideoModel(data)
                const mappedModel = model.getInstance()
                return { ...data, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_NEWSFEED_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new NewsfeedModel(data)
                const mappedModel = model.transformFields()
                return mappedModel
            }
            meta.convertToResponse = (data: any) => {
                const model = new NewsfeedModel(data)
                const mappedModel = model.getInstance()
                return { ...data, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_NOTIFICATION_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new NotificationModel(data)
                const mappedModel = model.transformFields()
                return mappedModel
            }
            break;
        case `${process.env.REACT_APP_ROOM_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                const model = new ChatRoomModel(data)
                const mappedModel = model.transformFields()
                return mappedModel
            }
            meta.convertToResponse = (data: any) => {
                const model = new ChatRoomModel(data)
                const mappedModel = model.getInstance()
                return { ...data, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_SQUAD_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                // const model = new Squad(data)
                // const mappedModel = model.transformFields()
                return { ...data, id: data._id }
            }
            meta.convertToResponse = (data: any) => {
                const model = new Squad(data)
                const mappedModel = model.getInstance()
                return {...data, _id: data.id, id: data.squad_id, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_STANDINGS_RESOURCE}`:
            meta.convertToHTTP = (data: any) => {
                return { ...data, id: data._id }
            }
            meta.convertToResponse = (data: any) => {
                const model = new Standings(data)
                const mappedModel = model.getInstance()
                return {...data, _id: data.id, ...mappedModel }
            }
            break;
        case `${process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE}`:
            meta.convertToResponse = (data: any) => {
                const model = new JobExecutionModel(data)
                const mappedModel = model.getInstance()
                return { ...data, ...mappedModel }
            }
            break;
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params, meta);
};
/**
 * Maps react-admin queries to the default format of Django REST Framework
 */
const drfProvider = (httpClient=customHttpClient) => {
    const getApiUrl = (resource: any) => {
        const isSchedulerResource = [
            process.env.REACT_APP_SCHEDULER_PROJECT_RESOURCE,
            process.env.REACT_APP_SCHEDULER_JOB_RESOURCE,
            process.env.REACT_APP_SCHEDULER_SPIDER_RESOURCE,
            process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE
        ].includes(resource)
        if (isSchedulerResource) {
            return process.env.REACT_APP_SCHEDULER_API_ENDPOINT
        }

        const isAIResource = [
            process.env.REACT_APP_AI_FEEDS_RESOURCE,
        ].includes(resource)
        if (isAIResource) {
            return process.env.REACT_APP_AI_API_ENDPOINT
        }
        return process.env.REACT_APP_API_ENDPOINT
    }
    
    /**
     * @param {String} type React-admin request type, e.g. 'GET_LIST'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params Request parameters. Depends on the request type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHttp = (type: any, resource: any, params: { filter?: any; target?: any; id?: any; data?: any; pagination?: any; sort?: any; }, meta?: HTTPMapper) => {
        const apiUrl = getApiUrl(resource)
        let url = "";
        let options : RequestOptions = {
            method: 'GET',
            headers: new Headers()
        };
        switch(type){
            case CREATE:
                url = `${apiUrl}/${resource}/`;
                options.method = 'POST';
                options.credentials = 'include';
                let itemData = params.data
                if(meta!.convertToHTTP){
                    itemData = meta!.convertToHTTP(itemData);
                }
                options.body = itemData instanceof FormData ? itemData : JSON.stringify(itemData);
                break;
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}/`;
                break;
            case GET_LIST: {
                const { page, perPage = '10', disablePagination = false } = params.pagination;
                // const { field = null, order = null } = params.sort;
                // const { filter } = params;
                let query : GetListQuery;
                if (disablePagination) {
                    query = {}
                }
                else {
                    query = {
                        page: page,
                        page_size: perPage
                    }
                }

                if(params.sort && params.sort.field && params.sort.order){
                    // query.ordering = `${params.sort.order === 'ASC' ? '' : '-'}${params.sort.field}`
                }
                if(params.filter){
                    query = { ...query, ...params.filter }
                }
                url = `${apiUrl}/${resource}/?${stringify(query)}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const { filter, target, id } = params;
                const query = {
                    page,
                    page_size: perPage,
                    ordering: `${order === 'ASC' ? '' : '-'}${field}`,
                    ...filter,
                    [target]: id
                };
                url = `${apiUrl}/${resource}/?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}/`;
                options.method = 'PATCH';
                options.credentials = 'include'
                let data = params.data
                
                if(meta!.convertToHTTP){
                    data = meta!.convertToHTTP(params.data);
                }
                options.body = data instanceof FormData ? data : JSON.stringify(data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}/`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported Data Provider request type ${type}`);
        }
        return { url, options };
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type React-admin request type, e.g. 'GET_LIST'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params Request parameters. Depends on the request type
     * @returns {Object} Data response
     */
    const convertHttpResponse = (response: Response, type: any, resource: any, params: { filter?: any; target?: any; id?: any; data: any; pagination?: any; sort?: any; previousData?: any; ids?: any; }, meta?: HTTPMapper) => {
        const { headers, json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if ('count' in json){
                    if(meta!.convertToResponse){
                        return { data: json.results.map(meta!.convertToResponse), total: json.count }
                    }
                    return { data: json.results, total: json.count }
                } else if (headers.has('content-range')) {
                    return {
                        data: json,
                        total: parseInt(
                            headers
                            .get('content-range')
                            .split('/')
                            .pop(),
                            10
                        ),
                    };
                } else if ('detail' in json && json.detail === 'Invalid page.') {
                    return { data: [], total: 0 }
                } else {
                    throw new Error(
                        'The total number of results is unknown. The DRF data provider ' +
                        'expects responses for lists of resources to contain this ' +
                        'information to build the pagination. If you\'re not using the ' +
                        'default PageNumberPagination class, please include this ' +
                        'information using the Content-Range header OR a "count" key ' +
                        'inside the response.'
                    );
                }
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            case DELETE:
                return { data: params.previousData };
            default:
                if(meta!.convertToResponse){
                    return { data: meta!.convertToResponse(json) }
                }
                return { data: json };
        }
    }

    /**
     * @param {String} type React-admin request type, e.g. 'GET_LIST'
     * @param {string} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type: any, resource: any, params: { filter?: any; target?: any; id?: any; data: any; pagination?: any; sort?: any; previousData?: any; ids?: any; }, meta?: HTTPMapper) => {
        /**
         * Split GET_MANY, UPDATE_MANY and DELETE_MANY requests into multiple promises,
         * since they're not supported by default.
         */
        const apiUrl = getApiUrl(resource)
        switch (type) {
            case GET_MANY:
                return Promise.all(
                    params.ids.map((id: string) =>
                        httpClient(`${apiUrl}/${resource}/${id}/`, {
                            method: 'GET'
                        })
                    )
                ).then((responses:any[]) => ({
                    data: responses.map((response: Response) => response.json),
                }));
            case UPDATE_MANY:
                return Promise.all(
                    params.ids.map((id: string) => {
                        return httpClient(`${apiUrl}/${resource}/${id}/`, {
                                method: 'PATCH',
                                body: JSON.stringify({...params.data, id: params.data._id}),
                            })
                        }
                    )
                ).then((responses:any[]) => ({
                    data: responses.map((response: Response) => response.json),
                }));
            case DELETE_MANY:
                return Promise.all(
                    params.ids.map((id: string) =>
                        httpClient(`${apiUrl}/${resource}/${id}`, {
                            method: 'DELETE',
                        })
                    )
                ).then((responses:any[]) => ({
                    data: responses.map((response: Response) => response.json),
                }));
            default:
                break;
        }

        const { url, options } = convertDataRequestToHttp(type, resource, params, meta);

        return httpClient(url, options)
            .then((response: Response)  => {
                if (options.method == "PATCH" && resource == `${process.env.REACT_APP_MATCHES_RESOURCE}`) {
                    const key = Object.keys(params.data).find(k => ["goal", "card", "substitute"].includes(k));
                    if (key) {
                        window.location.reload()
                    }
                }
                return response 
            })
            .then((response: Response) => convertHttpResponse(response, type, resource, params, meta))
            .catch(function (error: ResponseError) {
              let formattedError : FormattedError = {
                message: '',
                status: 0,
                errors: []
              }
              switch(error.status){
                case 400:
                    let message = 'Validation Error'
                    if(Array.isArray(error.body)) {
                        message = error.body.join('\n')
                    }
                    formattedError = {
                        message: message,
                        status: error.status,
                        errors: Object.keys(error.body)
                                .map(key => ({ [key]: error.body[key][0] }))
                                .reduce((p, n) => ({...p, ...n}), {})
                    }
              }
              throw new HttpError(formattedError.message, formattedError.status, formattedError.errors);
            });
    }
}

export default drfProvider;