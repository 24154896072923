class Job {
    static PRIORITY:any = {
        'Low': -1,
        'Normal': 0,
        'High': 1,
        'Highest': 2,
    }
    static RUN_TYPE:any = {
        'Once': 'onetime',
        'Periodic': 'periodic'
    }
    static RUNNING_STATUS:any = {
        'PENDING': 0,
        'RUNNING': 1,
        'FINISHED': 2,
        'CANCELED': 3,
    }

}

export default Job