import React from 'react';
import { List, Datagrid, TextField, SelectField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import dayjs from "dayjs";
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import PubDateField from '../../fields/CustomDateField';


const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  allBlue:{
    direction: 'ltr' as 'ltr'
  },
  capLength:{
    margin: "12px 0",
    lineHeight: "200%",
  },
  test:{
    width: "35%",
  }
}


const FullName = (props: any) => {
  return(
    <span>{props.record.user.first_name} {props.record.user.last_name}</span>
  )
}

const CommentsList = (props: any) => {
  const classes = props.classes;
  return (
  <List {...props} exporter={false} title="قائمة التعليقات">
    <Datagrid rowClick="edit">
      <PubDateField source="submit_date" unix={true} label="تاريخ النشر" headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
      <TextField source="comment" className={classNames(classes.tabField, classes.alignRight, classes.capLength)} headerClassName={classNames(classes.tableHeader,classes.alignRight, classes.test)} />
      <TextField source="user.username" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} />
      <FullName label="الإسم الكامل" className={classes.test} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
      <SelectField source="is_public" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
        choices={[
          { id: true, name: 'نعم' },
          { id: false, name: 'لا' },
        ]} 
      />
      <PreviewButtonComponent />
    </Datagrid>
</List>
)};

const CommentsListForm = withStyles(styles)(CommentsList)

export default CommentsListForm