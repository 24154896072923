import React from 'react';
import {
  Edit, FormTab, TabbedForm, DisabledInput,
  TextInput, Toolbar, SaveButton, ArrayInput,
  SimpleFormIterator,  SelectInput
} from 'react-admin';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ColorInput } from 'react-admin-color-input';
import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AnalyticsLogoInput from '../../inputs/AnalyticsLogoInput'
import AnalyticsCountrySearchInput from '../../inputs/AnalyticsCountrySearchInput'



const styles = {
  inputLabel: {
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    }
  },
  saveBtn:{
    '& svg':{
      marginLeft:"5px",
    }
  },
  playersList: {
    width: "100%",
    '& p': {
      display: "none",
    },
    '& li': {
      marginBottom: 40,
      borderBottom: "none",
    }
  }
}


const MyCustomToolbar = (props:any) => {

return(
  <Toolbar {...props} >
   <SaveButton label="تحديث" />
   <a href={props.record.absolute_url} target="_blank" style={{ marginRight: "15px", textDecoration: 'none', }}>
      <Button variant="contained" style={{ }}>
        <PhotoCamera />
        معاينة على الموقع
      </Button>
    </a>
 </Toolbar>
 )
};

const CustomTitle = (props:any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%"}}>تعديل معلومات فريق {props.record.club_name}</span>
  )
}


const TeamEdit = (props: any) => {
  const classes = props.classes;

  const getLogoProps = (p: any) => (
    {
      logo: p.record.logo,
      content_type: "team",
      object_id: p.record.contestant_id
    }
  )

  return(
  <Edit  {...props} title={<CustomTitle/>}>
    <TabbedForm toolbar={<MyCustomToolbar />} handleSubmit={(Submit: any) => {
        if (props.dirtyFields && props.form) {
          const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, n: any) => {
            let key = n
            let value = props.form["record-form"].values[n]
            return { ...p, [key]: value }
          }, {})
          if(props.dirtyFields.players) {
            let dirtyPlayers:any = []
            props.dirtyFields.players.filter((value:any, index:any) => {
              if(!value) {
                return
              }
              let player:any = { 'person_id': dirtyValues.players[index].person_id }
              Object.keys(value).map((touchedField:any) => {
                player[touchedField] = dirtyValues.players[index][touchedField]
              })
              dirtyPlayers.push(player)
            })
            dirtyValues.players = dirtyPlayers
          }
          return Submit(dirtyValues)
        }
        return Submit
      }} >        
      <FormTab label="معلومات عن الفريق">
        <AnalyticsLogoInput getAltProps={getLogoProps} customLabel='شعار الفريق'/>
        <DisabledInput source="name" className={ classes.inputLabel } />
        <TextInput source="custom_name" className={ classes.inputLabel } />
        <TextInput source="name_fr" className={ classes.inputLabel } />
        <TextInput source="name_en" className={ classes.inputLabel } />
        <TextInput source="short_name" className={ classes.inputLabel } />
        <TextInput source="short_name_fr" className={ classes.inputLabel } />
        <TextInput source="short_name_en" className={ classes.inputLabel } />
        <TextInput source="official_name" className={ classes.inputLabel } />
        <TextInput source="official_name_fr" className={ classes.inputLabel } />
        <TextInput source="official_name_en" className={ classes.inputLabel } />
        <TextInput source="code" className={ classes.inputLabel } />
        <SelectInput source="type_name" choices={[
          { id: null, name: 'Unknown' },
          { id: 'club', name: 'club' },
          { id: 'national', name: 'national' },
        ]} />
        <SelectInput source="team_type" choices={[
          { id: null, name: 'Unknown' },
          { id: 'default', name: 'default' },
          { id: 'woman', name: 'woman' },
          { id: 'youth', name: 'youth' },
        ]} />
        <AnalyticsCountrySearchInput label="بلد الفريق" name="country"/>
        <TextInput source="city" className={ classes.inputLabel } />
        <SelectInput source="status" choices={[
          { id: 'active', name: 'active' }
        ]} />
        <TextInput source="founded" className={ classes.inputLabel } />
        <ColorInput source="color1"  className={ classes.inputLabel }/>
        <ColorInput source="color2" className={ classes.inputLabel }/>
        <TextInput source="address_zip" className={ classes.inputLabel } />
        <TextInput source="details" className={ classes.inputLabel } />
        <TextInput source="postal_address" className={ classes.inputLabel } />
        <TextInput source="postal_number" className={ classes.inputLabel } />
        <TextInput source="postal_extra" className={ classes.inputLabel } />
      </FormTab>
    </TabbedForm>
  </Edit>
)};

const TeamEditForm = withStyles(styles)(TeamEdit)

export default connect(
  (state: any, props: any) => {
    if (state && state.form && state.form['record-form'] && state.form['record-form'].anyTouched) {
      return { ...state, dirtyFields: state.form['record-form'].fields }
    }
    return state
  }, {} )(TeamEditForm)