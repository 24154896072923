import React, { useEffect, useState } from 'react';
import {
    Create, SimpleForm, TextInput, AutocompleteInput,
    ReferenceInput, SelectInput, BooleanInput, FormDataConsumer
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import JobModel from '../../../models/Job';
import Cookies from 'js-cookie';



const styles = {
    inputLabel: {
        width: '50%',
        '& label': {
            transformOrigin: "top right",
            right: 0,
            fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
            fontSize: ".74rem",
        },
        '& input': {
            fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
            fontSize: ".84rem",
            display: 'block'
        },
        '& textarea': {
            fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
            fontSize: ".9rem",
        },
    },
    isLTR: {
        direction: 'ltr' as 'ltr',
    }
}


const CreateJob = (props: any) => {
    const classes = props.classes;
    const [selectedProject, setSelectedProject] = useState();
    const [spidersChoices, setSpidersChoices] = useState([]);

    const getSpiders = (projectId:any) => {
        return fetch(
            `${process.env.REACT_APP_SCHEDULER_API_ENDPOINT}/projects/${projectId}/spiders`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken') || "",
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res => res.json())
    }

    useEffect(() => {
        if (! selectedProject) {
            setSpidersChoices([])
        }
        else {
            getSpiders(selectedProject).then((result:any) => setSpidersChoices(result));
        }
    }, [selectedProject]);

    return (
        <Create {...props}>
            <SimpleForm>
                <BooleanInput source="enabled" />
                <ReferenceInput source={"w_project_id"} reference={"projects"}>
                    <SelectInput className={classNames(classes.inputLabel, classes.isLTR)}/>
                </ReferenceInput>

                <FormDataConsumer>
                    {(dataProps: any) =>
                        {
                            if(dataProps.formData && dataProps.formData.w_project_id) {
                                setSelectedProject(dataProps.formData.w_project_id)
                            }
                            else {
                                setSelectedProject(undefined)
                            }
                            return <SelectInput
                                optionValue="name"
                                source="w_spider_name"
                                choices={spidersChoices}
                                className={classNames(classes.inputLabel, classes.isLTR)}
                            />
                        }
                    }
                </FormDataConsumer>
                <TextInput source="spider_arguments" className={classNames(classes.inputLabel, classes.isLTR)} />
                <SelectInput source="priority" choices={Object.keys(JobModel.PRIORITY).map((key) => ({ id: JobModel.PRIORITY[key], name: key }))} />
                <SelectInput source="run_type" choices={Object.keys(JobModel.RUN_TYPE).map((key) => ({ id: JobModel.RUN_TYPE[key], name: key }))} />
                <FormDataConsumer>
                    {(dataProps: any) => dataProps.formData.run_type == 'periodic' &&
                        (
                            <>
                                <SelectInput source="trigger_type" choices={[
                                    { id: 'cron', name: 'Cron' },
                                    // { id: 'interval', name: 'Interval' },
                                ]} /><br />
                                <TextInput fullWidth source="cron_seconds" className={classNames(classes.inputLabel, classes.isLTR)} /><br />
                                <TextInput source="cron_minutes" className={classNames(classes.inputLabel, classes.isLTR)} /><br />
                                <TextInput source="cron_hour" className={classNames(classes.inputLabel, classes.isLTR)} /><br />
                                <TextInput source="cron_day_of_week" className={classNames(classes.inputLabel, classes.isLTR)} /><br />
                                <TextInput source="cron_day_of_month" className={classNames(classes.inputLabel, classes.isLTR)} /><br />
                                <TextInput source="cron_month" className={classNames(classes.inputLabel, classes.isLTR)} /><br />
                                <TextInput source="cron_exp" label={"Cron Expressions (s m h dom mon dow)"} className={classNames(classes.inputLabel, classes.isLTR)} />
                            </>
                        )
                    }
                </FormDataConsumer>


            </SimpleForm>
        </Create>
    )
};

const CreateJobForm = withStyles(styles)(CreateJob)

export default CreateJobForm