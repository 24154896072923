import React from 'react';
import {
    Filter, ReferenceInput, AutocompleteInput, SelectInput
} from 'react-admin';
import JobModel from '../models/Job'



export const JobsFilter = (props:any) => (
    <Filter {...props}>
        <ReferenceInput source={"project"} reference={"projects"}  alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>

    </Filter>
);

export const JobExecutionsFilter = (props:any) => (
    <Filter {...props}>
        <ReferenceInput source={"project"} reference={"projects"}  alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>
        <SelectInput
            source="running_status"
            label="Status"
            choices={Object.keys(JobModel.RUNNING_STATUS).map((key) => ({ id: JobModel.RUNNING_STATUS[key], name: key }))}
            alwaysOn
        />
    </Filter>
)