import React from 'react';
import { Edit, SimpleForm, TextInput, DisabledInput, required} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';



const styles = {
  inputLabel: {
    width:'50%',
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".84rem",
    },
    '& textarea':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".9rem",
    },
  },
  isLTR:{
    direction: 'ltr' as 'ltr',
  }
}


const ProjectEdit = (props: any) => {
  const classes = props.classes;
  return(
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id" className={ classes.inputLabel } validate={required()} />
      <TextInput source="name" className={ classNames(classes.inputLabel,classes.isLTR) } validate={required()}/>
      <TextInput source="server" className={ classNames(classes.inputLabel,classes.isLTR) }/>
    </SimpleForm>
  </Edit>
)};

const ProjectEditForm = withStyles(styles)(ProjectEdit)

export default ProjectEditForm