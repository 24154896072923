// in src/posts.js
import React from 'react';
import { Edit, SimpleForm, TextInput, ImageInput, ImageField, FormDataConsumer, required, SelectInput } from 'react-admin';
import RichTextEditor from '../../inputs/RichTextEditor';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import { DateTimeInput } from "react-admin-date-inputs";
import TagsInput from '../../inputs/TagsInputs';
import CustomAutoComplete from '../../inputs/CustomAutoComplete';
import { withStyles } from '@material-ui/core/styles';
import ImageCropper from '../../inputs/ImageCropper/ImageCropper';
import EBooleanInput from '../../inputs/BooleanInput';
import LiveSpreadsheetComponent from '../../components/LiveSpreadsheetComponent';
import _ from 'lodash';
import CountrySearchInput from '../../inputs/CountrySearchInput';
import SportSearchInput from '../../inputs/SportSearchInput';
import { translate } from 'react-admin';

// TODO: this ImageCropper has many issues with types
// import ImageCropper from '../../inputs/ImageCropper'; 


const styles = (theme:any) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  spreadsheetButtonToggle: {
    height: 200
  }
});

const CustomTitle = (props:any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>تعديل المقال  "{props.record.title}"</span>
  )
}

const ArticleEdit = (props: any) => {
    const { classes } = props
    return (<Edit {...props}  title={<CustomTitle />}>
        <SimpleForm handleSubmit={(Submit: any) => {
          if (_.get(props, 'form.record-form.syncErrors')){
            return
          }
          if (props.dirtyFields && props.form) {
            const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, n: any) => {
              let key = n
              let value = props.form["record-form"].values[n]
              return { ...p, [key]: value }
            }, {})
            return Submit(dirtyValues)
          }
        }}>
            <SelectInput source="lang" initialValue={'ar'} choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
            <TextInput source="title" fullWidth className={classes.control} validate={required()}/>
            <TextInput source="custom_author" />
            <FormDataConsumer>
              {({ formData, ...rest }: { [x: string]: any }) =>
                <RichTextEditor source="html_content" lang={formData.lang}/>
              }
            </FormDataConsumer>
            <ImageInput source="w_image" accept="image/*" validate={required()}>
                <ImageField source="default_path" title="title"/>              
            </ImageInput>
            {/* <ImageCropper name="w_image" source="w_image" field="default_path"/>   */}
            <FormDataConsumer>
              {({ formData, ...rest }:{[x:string]: any}) => (formData && formData.w_image) &&
                  <ImageCropper name="crop_area" source="crop_area" image={formData.w_image.default_path} {...rest}/>                      
              }
             </FormDataConsumer>
             <TextInput
              className={classes.control}
              multiline
              rows="2"
              fullWidth
              source="image_description"
            />
              
            <EBooleanInput source="with_watermark" />
          
            <TextInput source="custom_author" className={classes.control}/>
            <DateTimeInput
              source="pub_date"
              className={classes.control} 
              options={{ format: 'd/MM/YYYY HH:mm', ampm: false}}
              label={props.translate(`resources.${process.env.REACT_APP_ARTICLE_RESOURCE}.fields.pub_date`)}
            />
            <TagsInput className={classes.control} label="طاغات المقال" required/>
            <Grid container spacing={40}>
              <Grid item>
                <CountrySearchInput
                  className={ classes.inputLabel } 
                  required
                />
              </Grid>
              <Grid item>
                <SportSearchInput
                  className={ classes.inputLabel } 
                  required
                />
              </Grid>
            </Grid>

            <EBooleanInput source="is_important" />
            <EBooleanInput source="is_global" />
            <EBooleanInput source="on_newsfeed" />
            <EBooleanInput source="on_slideshow" />
 
            <TextInput
              source="description"
              label="Description"
              multiline
              rows={2}
              maxRows={4}
              fullWidth
            />
            
            <LiveSpreadsheetComponent />

        </SimpleForm>
    </Edit>)
};

export default connect(
  (state: any, props: any) => {
    if (state && state.form && state.form['record-form'] && state.form['record-form'].anyTouched) {
      return { ...state, dirtyFields: state.form['record-form'].fields }
    }
    return state
  },
  {}
)(withStyles(styles)(translate(ArticleEdit)));
