import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { submit, isSubmitting } from 'redux-form';
import {
    fetchEnd, fetchStart, showNotification, Button, SaveButton, SimpleForm,
    TextInput, CREATE, BooleanInput, SelectInput
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { dataProvider } from '../../App';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
  
  
const styles = {
    tabField: {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".74rem", 
    },
    alignRight:{
      textAlign: 'right' as 'right'
    },
    tableHeader:{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".8rem",
      fontWeight: 700,
    },
    allBlue:{
      direction: 'ltr' as 'ltr'
    }
  }

class TagCreate extends Component<any> {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;
        submit('post-quick-create');
    };

    handleSubmit = (values:any) => {
        const {
            fetchStart,
            fetchEnd,
            showNotification
        } = this.props;
        fetchStart();

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        dataProvider(CREATE, 'tags', { data: values })
            .then(({ data }) => {
                const tag = {
                    content_type: 'tag',
                    object_id: data.id,
                    name: data.name
                }
                this.props.onTagCreate(tag)
                this.setState({ showDialog: false });
            })
            .catch((error:any) => {
                showNotification(error.message, 'error');
            })
            .finally(() => {
                fetchEnd();
            });
    }

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, classes } = this.props;
        

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="أضف طاغ جديد">
                    <IconContentAdd />
                </Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="أضف طاغ جديد"
                >
                    <DialogTitle>Create Tag</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            form="post-quick-create"
                            resource="tags"
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                        >
                            <TextInput source="name" />
                            <TextInput source="custom_name" />
                            <SelectInput source="status" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
                                choices={[
                                { id: 1, name: 'في إنتظار الموافقة' },
                                { id: 2, name: 'منشور' },
                                { id: 3, name: 'سينشر لاحقا' },
                                ]} />
                            <TextInput source="slug" />
                            <BooleanInput source="is_trending" />
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleCloseClick}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const TagQuickCreation = withStyles(styles)(TagCreate)

const mapStateToProps = (state:any) => ({
    isSubmitting: isSubmitting('post-quick-create')(state)
});

const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
    TagQuickCreation
);
