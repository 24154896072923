import React from 'react';
import {
    Create, SimpleForm, TextInput ,translate,
    ImageField, ImageInput, BooleanInput, DisabledInput,
    required, SelectInput
} from 'react-admin';
import { connect } from "react-redux";
import TagsInput from '../../inputs/TagsInputs';
import CustomAutoComplete from '../../inputs/CustomAutoComplete';
import { DateTimeInput } from "react-admin-date-inputs";
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import TagQuickCreation from '../tags/TagQuickCreationButton';
import CountrySearchInput from '../../inputs/CountrySearchInput';
import SportSearchInput from '../../inputs/SportSearchInput';
import { getDefaultFilterLang } from '../../filters/ContentFilter';



const styles = {
    tabLabel:{
      background:"blue",
    },
    inputLabel: {
      '& label':{
        transformOrigin: "top right",
        right: 0,
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".8rem",
      },
      '& input':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".9rem",
      },
      '& textarea':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".9rem",
      }
    },
    midSizedInput:{
      width: "50%",
    },
    largeSizedInput:{
      width: "70%",
    },
    smallSizedInput:{
      width: 130,
    },
    coloredLabel: {
      '& label':{
        fontWeight:700,
      }
    },
    boldText:{
      fontWeight: 700,
      color: "#687c8c",
      '& input':{
        color: "#687c8c",
      }
    },
    txtColor:{
      color:"red",
    },
    radioBtn:{
      '& label':{
        flexDirection: "row-reverse" as 'row-reverse',
        justifyContent: "flex-end",
        margin: 0,
        '&>span:first-child':{
          direction:"ltr" as 'ltr',
        },
        '&>span:last-child':{
          width: 100,
          display: "inline-block",
        }
      },
      '& span':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      }
    },
  
    goalsList:{
      '& p':{
        display:"none",
      },
      '& li':{
        marginBottom: 30,
        borderBottom: "none",
      }
    },
    isLTR:{
      direction: 'ltr' as 'ltr',
    }
  }

  const validateVideoCreation = (values:any) => {
    const errors:any = {};
    if (!values.w_image && !values.w_image_url) {
        errors.w_image = ['صورة الفيديو مطلوبة'];
        errors.w_image_url = ['صورة الفيديو مطلوبة'];
    }
    return errors
  };

const VideoCreate = (props: any) => {
    const classes = props.classes;
    let defaultLang = getDefaultFilterLang()
    defaultLang = defaultLang == 'fr' ? 'fr' : 'ar'
    const initValues:{[x:string]:any} = {pub_date: new Date(), on_newsfeed: true, lang: defaultLang}
    const [defaultValues, setDefaultValues] = React.useState(initValues);

    const onTagQuickCreate = (tag:any) => {
      props.form['record-form'].values.tags = [ tag, ]
    }
    return (
        <Create {...props} title="إضافة فيديو">
            <SimpleForm defaultValue={defaultValues} validate={validateVideoCreation} onChange={(e:any) => {
                if(e.target && e.target.name == "url"){
                    const formData = new FormData()
                    formData.append("url", e.target.value)
                    const request = new Request(`${process.env.REACT_APP_AUTH_ENDPOINT}/url_infos/`, {
                        method: 'POST',
                        body: formData,
                        credentials: 'include'
                    })

                    fetch(request).then(response => (
                        response.json()
                    )).then(response => {
                        if(Object.keys(response).length == 0) {
                          return
                        }
                        setDefaultValues({
                            ...defaultValues,
                           url: response.url,
                           embed: response.html,
                           title: response.title,
                           description: response.title,
                           w_image_url: response.thumbnail_url
                        })
                    })
                }
            }}>
                <SelectInput source="lang" choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
                <TextInput
                  source="title"
                  className={ classNames(classes.inputLabel, classes.midSizedInput) }
                  validate={required()}
                />
                <TextInput
                  source="url"
                  className={classNames(classes.inputLabel, classes.midSizedInput)}
                />
                <TextInput
                  source="description"
                  multiline className={ classNames(classes.inputLabel, classes.midSizedInput) }
                  validate={required()}
                />
                <ImageInput source="w_image" accept="image/*">
                    <ImageField source="default_path" title="title" />
                </ImageInput>

                <TextInput
                  source="embed"
                  multiline
                  className={ classNames(classes.inputLabel, classes.isLTR, classes.largeSizedInput) }
                  validate={required()}
                />
        
                <TagsInput name="tags" source="tags" className={ classes.inputLabel } required/>
                <TagQuickCreation onTagCreate={onTagQuickCreate} />
                <CountrySearchInput
                  className={ classes.inputLabel } 
                  required
                />

                <SportSearchInput
                  className={ classes.inputLabel } 
                  required
                />

                <DateTimeInput 
                  source="pub_date" 
                  label='تاريخ النشر'  
                  className={ classNames(classes.inputLabel, classes.smallSizedInput) } 
                  options={{ format: 'dd/MM/YYYY HH:mm', ampm: false}}/>

                <BooleanInput source="is_important" />
                <BooleanInput source="on_slideshow"/>
                <BooleanInput source="on_newsfeed"/>
                <BooleanInput source="with_copyright"/>
                <BooleanInput source="historical"/>
                <BooleanInput source="trending"/>
                <BooleanInput source="is_global"/>

                <DisabledInput source="w_image_url" fullWidth style={{ display: 'none' }}/>
            </SimpleForm>
        </Create>
    )
};


const VideoCreateView = translate(VideoCreate)

const StyledVideoCreateView = withStyles(styles)(VideoCreateView)

export default connect(
    (state: any, props: any) => {
      return state
    },
    {}
  )(StyledVideoCreateView);