import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import DateFnsUtils from "material-ui-pickers/utils/date-fns-utils";
import DatePicker from "material-ui-pickers/DatePicker"
import _ from 'lodash';
import dayjs from 'dayjs';
import { Responsive } from 'react-admin';
import classNames from 'classnames';
import Grid from "@material-ui/core/Grid";

class TimelineCalendarTabs extends Component<any, any> {
  constructor(props:any){
    super(props)
    const value = props.defaultValue || dayjs().format("YYYY-MM-DD")
    const possibleValues = this.getTabsValues(dayjs().format("YYYY-MM-DD"))
    let datePicker = { disabled: true, value }
    if(possibleValues.indexOf(value) == -1) {
      datePicker.disabled = false
    }
    this.state = {
      value,
      possibleValues,
      datePicker
    }
  }
  componentDidMount(){
    const { value } = this.state
    window.history.replaceState({journee: value}, '', `#/${process.env.REACT_APP_MATCHES_RESOURCE}/${value}`)
    
    let self = this
    window.onpopstate = function(event:any) {
      if(!event.state) { return  }
      let { datePicker, possibleValues } = self.state
      const value = event.state.journee
      if(possibleValues.indexOf(value) == -1) {
        datePicker.disabled = {disabled: false, value}
      }
      self.setState({ value, datePicker })
      self.props.handleLoadMore(value)
    };
  }
  handleDateChange(date: any) {
    const formattedDate = dayjs(date).format("YYYY-MM-DD")
    this.handleChange(null, formattedDate)
  }

  handleChange = (event:any, newValue:string) => {
    this.setState({ value: newValue })
    window.history.pushState({journee: newValue}, '', `#/${process.env.REACT_APP_MATCHES_RESOURCE}/${newValue}`)
    this.props.handleLoadMore(newValue)
    this.setState({ datePicker: {disabled: Boolean(event), value: newValue, }})
  }
  getTabsValues = (currentDate : string) => {
    return _.range(-1, 4).map(i => dayjs(currentDate).add(i, "day").format("YYYY-MM-DD"))
  }
  render(){
    const { classes } = this.props
    const { value, possibleValues, datePicker } = this.state
    let tabs:Array<any> = possibleValues.map((v: any) => <Tab key={v} value={v} label={v} />)
    if(!datePicker.disabled){
      tabs.unshift((
        <Tab key='datePicker' {...datePicker} label={datePicker.value} />
      ))
    }

    return (
      <Fragment>
        <Responsive 
          small={
              <Paper className={classes.root}> 
                <Tabs
                  fullWidth
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="off"
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      onChange={this.handleDateChange.bind(this)}
                      value={datePicker.value}
                      format={"YYYY-MM-dd"}
                      className={classes.datePicker}
                    />
                  </MuiPickersUtilsProvider>
                  {tabs.slice(0,3)}
                </Tabs>
              </Paper>

          }
          medium={
            <Paper className={classes.root}> 
              <Tabs
                fullWidth
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="off"
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    onChange={this.handleDateChange.bind(this)}
                    value={datePicker.value}
                    format={"YYYY-MM-dd"}
                    className={classes.datePicker}
                  />
                </MuiPickersUtilsProvider>
                {tabs.slice(0,4)}
              </Tabs>
            </Paper> 
          }

          large={
            <Grid>
              <Paper className={classes.root}>
                  <Tabs
                    fullWidth
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                  >
                    {this.props.children}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        onChange={this.handleDateChange.bind(this)}
                        value={datePicker.value}
                        format={"YYYY-MM-dd"}
                        className={classes.datePicker}
                      />
                    </MuiPickersUtilsProvider>
                    {tabs.slice(0,5)}
                  </Tabs>
              </Paper> 
            </Grid>
          }
        />  
    
    </Fragment>
    )
  }
}

export default withStyles({
  root: {
    // flexGrow: 1,
    // maxWidth: 500

  },
  TESTS:{

  },
  datePicker:{
    visibility:"hidden",
    '&:before':{
      content: '"إختر التاريخ"',
      background: "red",
      visibility:"visible",
      color: "#fff",
      fontSize: ".74rem",
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      backgroundColor: "#25A9FE",
      padding: "2px 14px",
      margin: "11px 13px",
      borderRadius: "4px",
      fontWeight: 700,
    },
    '& input':{
      width:0,
      height:0,
      display:"none",
    }
  },
  onSmall_tabsWrapper:{
    margin: "24px 0",
    textAlign: "center",
  },
  onSmall_datePicker:{
    display: "block",
    margin: "24px auto",
    textAlign: "center",
    '& input':{
      textAlign:"center",
    }
  },
})(TimelineCalendarTabs)