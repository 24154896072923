import React from 'react';
import { List, Datagrid, TextField, ImageField } from 'react-admin';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import SearchComponent from '../../filters/SearchComponent';


const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize: ".74rem",
  },
  alignRight: {
    textAlign: 'right' as 'right'
  },
  tableHeader: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize: ".8rem",
    fontWeight: 700,
  },
  allBlue: {
    direction: 'ltr' as 'ltr'
  },
  smallLogo: {
    width: 25,
    '& img': {
      width: "100%",
    }
  }
}



const CompetitionsList = (props: any) => {
  const classes = props.classes;
  const filters = (
    <SearchComponent
      source="person_id"
      reference="analytics/players"
      itemToString={(choice:any) => (`${choice.club_name}`)}
    /> 
  )
return(
  <List {...props}  filters={filters} exporter={false} title="قائمة المسابقات">
      <Datagrid rowClick="edit">
          <ImageField source="logo" label="شعار الفريق" className={classNames(classes.tabField,classes.alignRight,classes.smallLogo)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
          <TextField source="name" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
          {/* <TextField source="name_en" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/> */}
          {/* <TextField source="name_fr" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/> */}
          <TextField source="area_name" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
          <PreviewButtonComponent />
      </Datagrid>
    </List>
  )
};

const CompetitionsListComponent = withStyles(styles)(CompetitionsList)

export default CompetitionsListComponent