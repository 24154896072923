import React from 'react';
import { Edit, SimpleForm, DisabledInput,Toolbar,
  SaveButton, TextInput, SelectInput } from 'react-admin';
  import { DateInput } from "react-admin-date-inputs";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AnalyticsLogoInput from '../../inputs/AnalyticsLogoInput'
import AnalyticsCountrySearchInput from '../../inputs/AnalyticsCountrySearchInput'


const styles = {
  inputLabel: {
    '& label': {
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".74rem",
    },
    '& input': {
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize: ".84rem",
    }
  },
  saveBtn: {
    '& svg': {
      marginLeft: "5px",
    }
  },
}


const MyCustomToolbar = (props: any) => {

  return (
    <Toolbar {...props} >
      <SaveButton label="تحديث" />
      <a href={props.record.absolute_url} target="_blank" style={{ marginRight: "15px", textDecoration: 'none', }}>
        <Button variant="contained" style={{}}>
          <PhotoCamera />
          معاينة على الموقع
      </Button>
      </a>
    </Toolbar>
  )
};

const CustomTitle = (props: any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>تعديل معلومات اللاعب {props.record.name}</span>
  )
}


const PlayerEdit = (props: any) => {
  const classes = props.classes;

  const getLogoProps = (p: any) => (
    {
      logo: `${process.env.REACT_APP_MEDIA_URL}/stats/players/${p.record.player_id}.png`,
      content_type: "player",
      object_id: p.record.player_id
    }
  )

  return (
    <Edit  {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<MyCustomToolbar />} handleSubmit={(Submit: any) => {
        if (props.dirtyFields && props.form) {
          const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, n: any) => {
            let key = n
            let value = props.form["record-form"].values[n]
            return { ...p, [key]: value }
          }, {})
          return Submit(dirtyValues)
        }
        return Submit
      }} >
        <AnalyticsLogoInput getAltProps={getLogoProps} customLabel='صورة اللاعب' />
        <DisabledInput source="player_id" className={classes.inputLabel} />
        <TextInput source="first_name" className={classes.inputLabel} />
        <TextInput source="last_name" className={classes.inputLabel} />
        <TextInput source="match_name" className={classes.inputLabel} />

        <TextInput source="first_name_fr" className={classes.inputLabel} />
        <TextInput source="last_name_fr" className={classes.inputLabel} />
        <TextInput source="match_name_fr" className={classes.inputLabel} />

        <TextInput source="first_name_en" className={classes.inputLabel} />
        <TextInput source="last_name_en" className={classes.inputLabel} />
        <TextInput source="match_name_en" className={classes.inputLabel} />

        <TextInput source="height" className={classes.inputLabel} />
        <TextInput source="weight" className={classes.inputLabel} />
        <SelectInput source="position" choices={[
          { id: 'Defender', name: 'مدافع' },
          { id: 'Attacker', name: 'مهاجم' },
          { id: 'Midfielder', name: 'لاعب وسط' },
          { id: 'Goalkeeper', name: 'حارس مرمى' },
          { id: 'Unknown', name: 'غير معروف' }
        ]} />
        <DateInput source='date_of_birth' 
          options={{
            format: "YYYY-MM-dd"
          }}
        />
        <SelectInput source="foot" choices={[
          { id: 'left', name: 'القدم اليسرى' },
          { id: 'right', name: ' القدم اليمنى' },
          { id: 'both', name: 'كلتا القدمين' }
        ]} />
        <SelectInput source="gender" choices={[
          { id: 'Male', name: 'ذكر' },
          { id: 'Female', name: 'انثى' }
        ]} />
        <SelectInput source="status" choices={[
          { id: 'active', name: 'نشيط' }
        ]} />
        <AnalyticsCountrySearchInput label="بلد الميلاد" name="country"/>
        <AnalyticsCountrySearchInput label="الجنسية" name="nationality"/>
        {/* <DisabledInput source="nationality" className={classes.inputLabel} /> */}
        <DisabledInput source="activeMembership.contestant_name" className={classes.inputLabel} />
        <TextInput source="team_id" className={classes.inputLabel} />
      </SimpleForm>
    </Edit>
  )
};

const PlayerEditForm = withStyles(styles)(PlayerEdit)

export default connect(
  (state: any, props: any) => {
    if (state && state.form && state.form['record-form'] && state.form['record-form'].anyTouched) {
      return { ...state, dirtyFields: state.form['record-form'].fields }
    }
    return state
  }, {})(PlayerEditForm)