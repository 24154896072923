import React from "react";
import { Filter, TextInput, translate, SelectInput } from "react-admin";

export const getDefaultFilterLang = () => {
  let value: any = localStorage.getItem("__filterLang");
  return ["ar", "fr"].includes(value) ? value : "";
};

const ContentFilter = ({
  sources = ["author", "lang", "title"],
  ...otherProps
}) => (
  <Filter {...otherProps}>
    {sources.includes("author") && (
      <TextInput
        label={otherProps.translate(
          `resources.${process.env.REACT_APP_ARTICLE_RESOURCE}.fields.author.username`
        )}
        source="author"
        alwaysOn
      />
    )}
    {sources.includes("lang") && (
      <SelectInput
        source="lang"
        allowEmpty={false}
        label="اللغة"
        choices={[
          { id: "", name: "الجميع" },
          { id: "ar", name: "العربية" },
          { id: "fr", name: "الفرنسية" },
        ]}
        alwaysOn
        onChange={(e: any, key: any) => {
          localStorage.setItem("__filterLang", key);
        }}
      />
    )}
    {sources.includes("title") && (
      <TextInput
        label={otherProps.translate(
          `resources.${process.env.REACT_APP_ARTICLE_RESOURCE}.fields.title`
        )}
        source="title"
        alwaysOn
      />
    )}

    {sources.includes("source") && (
      <SelectInput
        source="source"
        allowEmpty={false}
        label="Source"
        choices={[
          { id: "", name: "All sources" },
          { id: "le360", name: "Le360" },
          { id: "rmcsport", name: "RMC Sport" },
          { id: "equipe.fr", name: "Equipe.fr" },
          { id: "lionsdelatlas", name: "Lions de l'atlas" },
          { id: "arryadia_snrt", name: "Arryadia SNRT" },
          { id: "mapsport", name: "MAP Sport" },
        ]}
        alwaysOn
      />
    )}
  </Filter>
);

export default translate(ContentFilter);
