import React from 'react';
import {
    List, Datagrid, TextField, SelectField
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { RunJobBotton, SwitchJobEnableBotton } from '../../../components/SchedulerActionButtons';
import { JobsFilter } from '../../../filters/SchedulerFilters';
import JobModel from '../../../models/Job';


const styles = {
    tabField: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".74rem",
        direction: 'ltr' as 'ltr',
    },
    tableHeader: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".8rem",
        fontWeight: 700,
    }
}


const JobList = (props: any) => {
    const classes = props.classes;
    return (
        <List {...props} title="Jobs" filters={(<JobsFilter />)} exporter={false} filter={{ run_type: 'periodic' }} perPage={50}>
            <Datagrid rowClick="edit">

                <TextField source="id" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="project.project_name" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="spider.spider_name" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="spider_arguments" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <SelectField
                    source="priority"
                    label="Priority"
                    choices={Object.keys(JobModel.PRIORITY).map((key) => ({ id: JobModel.PRIORITY[key], name: key }))}
                    className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)}
                />
                <TextField source="cron_seconds" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="cron_minutes" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="cron_hour" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="cron_day_of_week" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="cron_day_of_month" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                <TextField source="cron_month" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} />
                {/* <BooleanField source="enabled" className={classNames(classes.tabField)} headerClassName={classNames(classes.tableHeader)} /> */}
                <SwitchJobEnableBotton />
                <RunJobBotton />
                
            </Datagrid>
        </List>
    )
};

export default withStyles(styles)(JobList)