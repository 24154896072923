import React from 'react';
import { List, Datagrid, UrlField, SingleFieldList, TextField, ArrayField, ChipField, SelectField, NumberField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Avatar } from "@material-ui/core";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';


const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  allBlue:{
    direction: 'ltr' as 'ltr'
  }
}


const ChatroomsList = (props: any) => {
  const classes = props.classes;
  return (
  <List {...props} exporter={false} title="قائمة صالات الدردشة">
    <Datagrid rowClick="edit">
      <TextField source="title" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
      {/* <NumberField source="ordering" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/> */}
      <TextField source="match_id"  className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
      <SelectField source="type" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
            choices={[
              { id: 1, name: 'كلاسيكي' },
              { id: 2, name: 'مباراة' },
            ]} 
          />
      <PreviewButtonComponent /> 
    </Datagrid>
</List>
)};

const ChatroomsListForm = withStyles(styles)(ChatroomsList)

export default ChatroomsListForm