import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Title, crudGetList, crudGetOne, Responsive } from 'react-admin';
import TimelineLoaded from './TimelineLoaded';
import TimelineLoading from './TimelineLoading';
import TimelineEmpty from './TimelineEmpty';
import TimelineCalendarTabs from './TimelineCalendarTabs';
import dayjs from 'dayjs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';

// TODO: refactor this timeline to use Custom Iterator
// Read more on: https://marmelab.com/react-admin/List.html#using-a-custom-iterator

type GetList = (
    resource: string,
    pagination: object,
    sort?: object,
    filter?: object
) => any
type TimelineProps = {
    crudGetList: GetList
    ids: any[],
    data: any,
    total: string,
    loadedOnce: boolean
}


export class Timeline extends Component<TimelineProps, any> {
    page = dayjs().format("YYYY-MM-DD");
    constructor(props: any) {
        super(props);
        this.state = {
            events: props.ids.map((id: string) => props.data[id]),
            latestId: props.ids[props.ids.length - 1],
            collapsedComps: []
        };
        this.page = _.get(props, 'match.params.date') || dayjs().format("YYYY-MM-DD")
    }

    updateData = (page = this.page) => {
        this.props.crudGetList(
            `${process.env.REACT_APP_MATCHES_RESOURCE}`,
            { disablePagination: true },
            undefined, // { field: 'datetime', order: 'DESC' },
            { date: page, showAll: this.state.showAll }
        );
        this.page = page
    };
    getCompIds(){
        return this.state.events.map((m:any) => m.competition.competition_id)
    }
    componentDidMount() {
        this.updateData();
    }


    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.ids !== prevProps.ids) {
            const { ids, data } = this.props;
            const latestId = ids[ids.length - 1];
            const newEvents = ids.map((id: string) => data[id]);
            this.setState((state: any) => ({
                events: newEvents,
                latestId,
            }));
        }
        if (this.state.showAll != prevState.showAll) {
            this.updateData()
        }
    }

    handleLoadMore = (nextPage = this.page) => {
        this.page = dayjs(nextPage).add(1, "day").format("YYYY-MM-DD")
        this.updateData();
    };

    render() {
        const { events } = this.state;
        const { total, loadedOnce } = this.props;
        return (
            <>
                <Title title="جدول المباريات" />
                <div style={{ flexGrow: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.state.showAll === 1} onChange={(e) => this.setState({ showAll: e.target.checked ? 1 : 0 })} />
                        }
                        label="إظهار كافة المباريات"
                        style={{ marginRight: 12 }}
                    />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <TimelineCalendarTabs handleLoadMore={this.updateData} defaultValue={this.page}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={this.state.collapsedComps.length > 0} onChange={(e) => this.setState({ collapsedComps: e.target.checked ? this.getCompIds() : [] })} value={'checked'} />
                            }
                            label="إختيار الكل"
                            style={{ marginRight: 12 }}
                        />
                    </TimelineCalendarTabs>

                    {!loadedOnce ? (
                        <TimelineLoading />
                    ) : events.length === 0 ? (
                        <TimelineEmpty />
                    ) : (
                                <TimelineLoaded
                                    collapsedComps={this.state.collapsedComps}
                                    events={events}
                                    total={total}
                                    handleLoadMore={this.handleLoadMore}
                                    date={this.page}
                                />
                            )}
                </div>
                
            </>
        );
    }
}


const mapStateToProps = (state: any) => {
    console.log('State', state)
    return {
        ids: state.admin.resources[`${process.env.REACT_APP_MATCHES_RESOURCE}`].list.ids,
        data: state.admin.resources[`${process.env.REACT_APP_MATCHES_RESOURCE}`].data,
        total: state.admin.resources[`${process.env.REACT_APP_MATCHES_RESOURCE}`].list.total,
        loadedOnce: state.admin.resources[`${process.env.REACT_APP_MATCHES_RESOURCE}`].list.loadedOnce,
    }
};

export default connect(
    mapStateToProps,
    { crudGetList, crudGetOne }
)(Timeline);
