import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Downshift from "downshift";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import _ from 'lodash'
import { withStyles } from '@material-ui/core';


function renderInput(props:any, inputProps: any) {
  const { classes } = props;
  const { InputProps, ref, ...other } = inputProps;
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        ...InputProps,
        classes:{
          root: classes.inputWrapper,
        }
      }}
      {...other}
      fullWidth
    />
  );
}


class IntegrationDownshift extends React.Component<any, any> {

  constructor(props: any) {
    super(props)
    this.state = {
      selectedItem: props.input.value,
      suggestions: [],
      inputValue: null
    }

    if (props._view === "CREATE_ARTICLE" && props.input.value) {
      props.onSelectedItemChange(props.input.value, props.input)
    }
    
    this.onDownShiftChange = this.onDownShiftChange.bind(this)
  }

  fetchSuggestions(inputValue: any) {
    const { fetchValues } = this.props
    fetchValues(inputValue)
      .then((values: Array<object>) => {
        this.setState({ suggestions: values })
      })
      .catch((err: any) => this.setState({ suggestions: this.state.suggestions }))
  }

  onDownShiftChange(selectedItemString: string) {
    const { suggestions } = this.state
    const { findSelectedItem, onSelectedItemChange, input } = this.props
    let selectedItem: any = findSelectedItem(suggestions, selectedItemString)
    this.setState({ selectedItem })
    onSelectedItemChange(selectedItem, input)
  }

  render() {
    const { classes, itemToString, label, touched, error, renderSuggestion, InputProps, suggestionGetItemProps } = this.props
    const { selectedItem, suggestions } = this.state
    const debouncedFetchSuggestions = _.debounce(this.fetchSuggestions.bind(this), 350, {leading:false, trailing:true})
    return (
      <div className={classes.container}>
        <Downshift
          id="downshift-simple2"
          selectedItem={selectedItem}
          itemToString={itemToString}
          onChange={this.onDownShiftChange}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem
          }) => {
            const { onBlur, onFocus, ...inputProps } = getInputProps({
              placeholder: label,
              onChange: (e: any) => { 
                debouncedFetchSuggestions(e.target.value) },
              ...InputProps
            });

            return (
              <div>
                {renderInput(this.props, {
                  label: label,
                  InputProps: {
                    onBlur,
                    onFocus,
                  },
                  error: !!(touched && error),
                  helperText: touched && error,
                  inputProps
                })}

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {

                        suggestions.map((suggestion: any, index: number) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: (suggestionGetItemProps ? suggestionGetItemProps(suggestion) : suggestion.name)
                            }),
                            highlightedIndex,
                            selectedItem
                          }))

                      }
                    </Paper>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }
}


const RenderCustomAutoComplete = (props: any) => {
  const { input, label, meta: { touched, error }, ...custom } = props
  return (
    <div>
      <IntegrationDownshift
        input={input}
        label={label}
        touched={touched}
        error={error}
        {...custom}
      />
    </div>
  )
};


const styles = (theme: any) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    // maxWidth: 200
  },
  textInput:{
    paddingLeft: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    overflowX: 'scroll',
    maxHeight: 300,
    minWidth: 400
  }
})
const isRequired = (value: any) => {
  return value ? undefined : `مطلوب`
}


function CustomAutoComplete(props: any) {
  let { name, label, required, ...other } = props
  name = other.source ? `${other.source}.${name}` : name
  return (
    <span>
      <Field
        name={name}
        label={label}
        component={RenderCustomAutoComplete}
        validate={required ? [isRequired] : []}
        {...other}
      />
    </span>
  )
};


//@ts-ignore
export default withStyles(styles)(CustomAutoComplete)