import React from 'react';
import { Filter, SearchInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
    alignRight: {
        textAlign: 'right' as 'right'
    },
    isLTR: {
        direction: 'ltr' as 'ltr',
        textAlign: "right" as 'right',
        '& label': {
            right: 50,
        },
        '& input': {
            textAlign: "right" as 'right',
            marginRight: 18,
            fontSize: '.9rem',
        }
    }
}


const Search = (props: any) => {
    const { classes, source, reference, itemToString } = props
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn label='بحث' className={classNames(classes.isLTR, classes.alignRight)} />
            <ReferenceInput source={source} reference={reference} >
                <AutocompleteInput
                    optionText={(choice: any) =>
                        itemToString(choice)
                    }
                />
            </ReferenceInput>
        </Filter>
    )
};

const SearchComponent = withStyles(styles)(Search)


export default SearchComponent