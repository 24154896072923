import React, { useEffect, useState } from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import CustomAutoComplete from './CustomAutoComplete';

const itemToString = (item:any)  => {
  if(!item.first_name && !item.last_name) {
    return item.match_name
  }
  const full_name = `${item.first_name} ${item.last_name}`
  if(item.match_name) {
    return `${full_name} (${item.match_name})`
  } 
  return full_name
}

function renderSuggestion(suggestionProps: any) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem && (selectedItem.id || "").indexOf(suggestion.id) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
    >
      {itemToString(suggestion)}
    </MenuItem>
  );
}

export default function SquadPlayerSearchInput(props: any) {

  const { squad } = props
  

  function onSelectedItemChange(selectedItem: any, input: any) {
    input.onFocus(selectedItem)
    input.onChange(selectedItem)
    input.onBlur(selectedItem)
  }
  

  return (
    <CustomAutoComplete
      fetchValues={(q: String) => {
        return new Promise((resolve, reject) => {
          const filtredSquad = squad.filter((p:any) => {
            let isQinName = false
            if(p.match_name) {
              isQinName = isQinName || p.match_name.includes(q)
            }
            if(p.first_name) {
              isQinName = isQinName || p.first_name.includes(q)
            }
            if(p.last_name) {
              isQinName = isQinName || p.last_name.includes(q)
            }
            return isQinName
          })
          resolve(filtredSquad);
        });
      }}
      itemToString={(item: any) => (item ? itemToString(item) : null)}
      findSelectedItem={(suggestions: Array<any>, selectedItem: any) => (
        suggestions.find((item: any) => (item.id == selectedItem.id))
      )}
      renderSuggestion={renderSuggestion}
      onSelectedItemChange={onSelectedItemChange}
      suggestionGetItemProps={(suggestion:any) => (suggestion)}
      {...props}
    />

  )
} 