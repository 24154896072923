import React from 'react';
import { List, Datagrid, TextField  } from 'react-admin';
import { SelectField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import dayjs from "dayjs";
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import PubDateField from '../../fields/CustomDateField';
import ContentFilter, { getDefaultFilterLang } from '../../filters/ContentFilter';




const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  }
}


export const Newsfeed = (props: any) => {
  const classes = props.classes;
  const filterDefaultValues = {
    lang: getDefaultFilterLang()
  }

  return(
  <List {...props} exporter={false}
    filters={<ContentFilter sources={['lang']}/>} filterDefaultValues={filterDefaultValues}
  >
      <Datagrid rowClick="edit" >
        <PubDateField source="pub_date" label="تاريخ النشر" headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        <TextField source="title" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)}/>
        <SelectField source="content_type" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
            choices={[
              { id: "article", name: 'مقال' },
              { id: "video", name: 'فيديو' },
              { id: "gallery", name: 'رواق' },
              { id: "match", name: 'مباراة' },
              { id: "highlight", name: 'ملخص' },
              { id: "streaming", name: 'بث مباشر' },
            ]} 
          />
        <SelectField source="is_important" className={classNames(classes.tabField,classes.alignRight)} headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
        choices={[
          { id: true, name: 'نعم' },
          { id: false, name: 'لا' },
        ]} 
      />
        <PreviewButtonComponent /> 
      </Datagrid>
  </List>
  )
};

const NewsfeedComponent = withStyles(styles)(Newsfeed)

export default NewsfeedComponent