import { AUTH_LOGIN, AUTH_GET_PERMISSIONS, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, fetchUtils } from 'react-admin';

const PERMISSIONS = [
  "manage_article",
  "edit_article",
  "manage_video",
  "manage_gallery",
  "manage_match",
  "manage_room",
  "manage_comment",
  "manage_newsfeed",
  "edit_notification",
  "edit_swearword",
  "edit_user",
  "manage_flatpage",
  "manage_tag",
  "manage_contestant",
  "manage_squad",
  "manage_player",
  "manage_scheduler",
  "manage_competition"
]
const login = () => {
  return fetchUtils.fetchJson(`${process.env.REACT_APP_AUTH_ENDPOINT}/${process.env.REACT_APP_USER_ME_URL}/`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    // credentials: 'same-origin',
    credentials: 'include'
  }).then((response: any) => {
      if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
      }
      return response.json
  })
}
const checkAuth = (type:string, params:any) => {
    if (type === AUTH_CHECK) {
      return login()
    }
    if (type === AUTH_GET_PERMISSIONS) {
      return login()
        .then((response : any) => {
          if(response.is_superuser){
            return PERMISSIONS
          }
          return response.user_permissions
        })
  }

    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(`${process.env.REACT_APP_AUTH_ENDPOINT}/${process.env.REACT_APP_USER_LOGIN_URL}/`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include'
        })
        return fetch(request)
            .then(function(response) {
              if(response.ok) {
                return response.json();
              }
              if(response.status >= 500){
                throw new Error('login.error5xx')
              }
              throw new Error('login.incorrectUsernameOrPassowrd')
            })
            .then(({ auth_token }) => {
              localStorage.setItem('__token', auth_token);
              return login()
            })
            .then(({ is_staff, is_superuser, user_permissions }) => {
              let message = !is_staff ? 'login.notStaff' : (
                !is_superuser && user_permissions.length == 0 ? 'login.noPermissions' : null
              )
              if (message) {
                localStorage.removeItem('__token');
                throw new Error(message)
              }
            })

    }
    return Promise.resolve();
}

const authProvider = {
  checkAuth,
  login,
  getIdentity: () => {
    try {
        return login();
    } catch (error) {
        return Promise.reject(error);
    }
}
}

export default authProvider