import React from 'react';
import { List, Datagrid, TextField, ImageField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';
import SearchComponent from '../../filters/SearchComponent';

const styles = {
    tabField: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".74rem",
    },
    alignRight: {
        textAlign: 'right' as 'right'
    },
    tableHeader: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".8rem",
        fontWeight: 700,
    },
    teamImg: {
        width: 50,
        '& img': {
            width: "100%",
        }
    }
}



const Squads = (props: any) => {
    const classes = props.classes;
    const filters = (
        <SearchComponent
            source="contestant_name"
            reference="analytics/v2/sqaud"
            itemToString={(choice:any) => (`${choice.contestant_name}`)}
        /> 
    )
    return (
        <List {...props} title="قائمة اللاعبين" filters={filters} exporter={false} actions={<></>}>
            <Datagrid rowClick="edit">
                <ImageField source='logo' customLabel='شعار الفريق' className={classNames(classes.teamImg)}/>
                <TextField source="contestant_name" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
            </Datagrid>
        </List>
    )
};

const SquadsView = withStyles(styles)(Squads)

export default SquadsView