

class Standings {
    private model : { [x:string]: any }
    constructor(standings : { [x:string]: any }){
      this.model = standings
    }
    public getInstance(){
      return {
        logo: this.getLogo(),
      }
    }
    getLogo(){
      return `${process.env.REACT_APP_MEDIA_URL}/stats/competitions/${this.model.competition.id}.png`
    }
  
  }
  
  export default Standings
  