import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";


const styles = {
  link: {
    textDecoration: "none",
    display: "block",
    margin: "8px 15px",
  },
  roundBtn: {
    borderRadius: 5,
  },
  button: {
    boxShadow: "none",
    width: "72px"
  },
  icon: {
    fontSize: 18,
  },
};

const StyledButton = (props: any) => {
  const classes = props.classes;

  const [isLoading, setIsLoading] = React.useState(false) 

  const onClick = async () => {
    setIsLoading(true)
    await props.onClick(props.record)
    setIsLoading(false)
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={20} style={{ color: "#fff" }}/>  : <span>{props.label}</span>}
    </Button>
  );
};

export default withStyles(styles)(StyledButton); 