import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

const fetchImage = async (imageUrl: string) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: "image/jpeg" });
  } catch (error) {
    console.error(error);
    return null;
  }
};

const styles = {
  textField: {
    "& textarea": {
      lineHeight: 1.5,
      direction: "ltr",
    },
    "& label": {
      left: 0,
    },
  },
  dialog: {
    direction: "ltr",
    "& .MuiDialog-paperScrollPaper-218": { height: "90%" },
  },
  results: {
    "& p": {
      lineHeight: 1.5,
      // direction: "ltr",
    },
  },
} as const;

const AIPromptModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(null);
  const [error, setError] = useState<null | String>(null);

  const [open, setOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const onTxtChange = (e: any) => {
    setTextValue(e.target.value);
  };
  const handleRadioGroupChange = (e: any) => {
    setTextValue(e.target.value);
    setSelectedPrompt(e.target.value);
  };
  const onReformulateClick = async () => {
    setLoading(true);

    setResults(null);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_AI_API_ENDPOINT}/generate/from_ar/?article_id=${props.articleId}&prompt=${textValue}`;
      console.log(url);

      const response = await fetch(url);
      let results = await response.json();
      setResults(results);
    } catch (err) {
      setError("Error");
    } finally {
      setLoading(false);
    }
  };

  const onContinueClick = async () => {
    results["w_image"] = results["image"];
    // to be removed after updating the api to recives pre saved PhotoEmbed
    let rawFile = await fetchImage(results["w_image"]["default_path"]);
    results["w_image"]["rawFile"] = rawFile;
    delete results["image"];
    handleClose()
    props.historyPush(`#/articles/create`, results)
  };

  const { classes } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Select Your Prompt</DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-label="Value"
            name="value"
            value={selectedPrompt}
            onChange={handleRadioGroupChange}
          >
            {props.defaultPrompts.map((p: string, i: number) => (
              <FormControlLabel
                value={p}
                control={<Radio />}
                label={`Prompt ${i + 1}`}
              />
            ))}
            <FormControlLabel
              value={""}
              control={<Radio />}
              label={`Custom Prompt`}
            />
          </RadioGroup>
          <TextField
            // autoFocus
            // margin="dense"
            type="text"
            label="Enter Prompt"
            fullWidth
            value={textValue}
            onChange={onTxtChange}
            multiline
            className={classes.textField}
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={onReformulateClick} color="primary">
              Reformulate
            </Button>
          </DialogActions>
          <div style={{ marginTop: "50px" }}>
            {error ? (
              <span>{error}</span>
            ) : loading ? (
              <CircularProgress
                size={50}
                style={{
                  display: "block",
                  margin: "0 auto",
                }}
              />
            ) : results ? (
              <>
                <DialogTitle>Results:</DialogTitle>
                <h4>{results.title}</h4>

                <div
                  className={classes.results}
                  dangerouslySetInnerHTML={{ __html: results.html_content }}
                ></div>
                <DialogActions>
                  <Button onClick={onContinueClick} color="primary">
                    Continue
                  </Button>
                </DialogActions>
              </>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const AIPromptModalComponent = withStyles(styles)(AIPromptModal);
export default AIPromptModalComponent;
