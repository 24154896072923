import React from 'react';
import {
  Edit, SimpleForm, translate, BooleanInput,
  SelectInput, NumberInput
} from 'react-admin';
import { connect } from "react-redux";
import TagsInput from '../../inputs/TagsInputs';
import { DateTimeInput } from "react-admin-date-inputs";
import _ from 'lodash'
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';



const styles = (theme: any) => ({
  control: {
    padding: theme.spacing.unit,
    marginBottom: 24,
    minWidth: '350px',
  }


});


const CustomTitle = (props: any) => {
  let title = props.record.person.match_name
  const fromTeam = props.record.from_contestant_name
  if (fromTeam) {
    title += ` - ${fromTeam}`
  }
  const toTeam = props.record.contestant_name
  if (toTeam) {
    title += ` - ${toTeam}`
  }
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>
      {title}
    </span>
  )
}
const CustomPaper = (props: any) => {
  return (
    <Paper elevation={1} style={{ width: "100%", padding: "4px 12px", marginBottom: 12, boxSizing: "border-box" }}>
      {props.children}
    </Paper>
  )
}
const TransferEdit = (props: any) => {
  const classes = props.classes;

  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm redirect={false} handleSubmit={(Submit: any) => {
        if (_.get(props, 'form.record-form.syncErrors')) {
          return
        }
        const anyTouched = _.get(props, 'form.record-form.anyTouched', null)
        if (anyTouched) {
          const fields = _.get(props, 'form.record-form.fields', {})
          const dirtyValues: { [x: string]: any } = {}
          Object.keys(fields).map((field: string) => {
            dirtyValues[field] = props.form['record-form'].values[field]
          })
          Submit(dirtyValues)
        }
      }}>

        <CustomPaper>
          <TagsInput
            name="inPerson"
            source="inPerson"
            multiple={false}
            searchType={"player"}
            label="اللاعب"
            placeholder="اللاعب"
          />
        </CustomPaper>
        <CustomPaper>
          <TagsInput
            name="from"
            source="from"
            multiple={false}
            searchType={"contestant"}
            label="انتقل من"
            placeholder="انتقل من"
          />
        </CustomPaper>
        <CustomPaper>
          <TagsInput
            name="to"
            source="to"
            multiple={false}
            searchType={"contestant"}
            label="انتقل الى"
            placeholder="انتقل الى"
          />
        </CustomPaper>


        <CustomPaper>
          <NumberInput
            source="value"
            label="القيمة (€)"
          />

        </CustomPaper>
        <CustomPaper>
          <SelectInput
            label="نوع الانتقال"
            source="transfer_type"
            choices={[
              { id: "rental", name: "Rental" },
              { id: "rental end", name: "Rental End" },
              { id: "transfer", name: "Transfer" },
              { id: "retirement", name: "Retirement" },
              { id: "draft", name: "Draft" },
              { id: "released", name: "Released" },
              { id: "signed", name: "Signed" },
              { id: "unknown", name: "Unknown" },
            ]}
          />
        </CustomPaper>
        <CustomPaper>
          <DateTimeInput
            source="announced_date"
            label="التاريخ المعلن"
            options={{
              format: 'dd/MM/YYYY',
            }}
          />
        </CustomPaper>


        <CustomPaper>
          <BooleanInput
            source="is_important"
            label="مهم"
          />
        </CustomPaper>





      </SimpleForm>
    </Edit>
  )
}

const TransferEditView = translate(TransferEdit)

const TransferEditForm = withStyles(styles)(TransferEditView)

export default connect(
  (state: any, props: any) => {
    return state
  },
  {}
)(TransferEditForm);