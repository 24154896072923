import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import CustomAutoComplete from './CustomAutoComplete';


function renderSuggestion(suggestionProps: any) {
    const {
        suggestion,
        index,
        itemProps,
        highlightedIndex,
        selectedItem
    } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem && (selectedItem.name || "").indexOf(suggestion.name) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.name}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400
            }}
        >
            {`${suggestion.name_fr} ${suggestion.name_ar}`}
        </MenuItem>
    );
}

export default function SportSearchInput(props: any) {
    function onSelectedItemChange(selectedItem: any, input: any) {
        input.onFocus(selectedItem.name)
        input.onChange(selectedItem.name)
        input.onBlur(selectedItem.name)
    }
    const getItemToString = (item: any) => {
        if (!item) {
            return null;
        }
        if (item.name_fr) {
            return `${item.name_fr} ${item.name_ar}`
        }
        else {
            return `${item.name} ${item.name_ar}`
        }
    }
    return (
        <CustomAutoComplete
            label="الرياضة"
            name="sport"
            fetchValues={(q: String) => {
                const request = new Request(`${process.env.REACT_APP_AUTH_ENDPOINT}/${process.env.REACT_APP_SPORT_RESOURCE}/?q=${q}`, {
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include'
                })
                return fetch(request)
                    .then(response => (response.json()))
                    .then(response => response.results || [])
            }}
            itemToString={getItemToString}
            findSelectedItem={(suggestions: Array<any>, selectedItemString: any) => (
                suggestions.find((item: any) => (item.name == selectedItemString))
            )}
            renderSuggestion={renderSuggestion}
            onSelectedItemChange={onSelectedItemChange}
            {...props}
        />
    )
} 