import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { Slider } from 'material-ui-slider';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import ImgDialog from './ImageDialog'
import getCroppedImg from './cropImage'
import { styles } from './styles'
import { ImageInput, ImageField } from 'react-admin';
import { Field } from 'redux-form';
import { translate } from 'react-admin';


const invokeFieldEvents = (input, newValue) => {
  newValue = {width: newValue.width, height:newValue.height, top: newValue.y, left: newValue.x}
  input.onFocus(newValue)
  input.onChange(newValue)
  input.onBlur(newValue)
}

const Demo = ({ classes, input, ...props }) => {
  const [img, setImg] = useState(props.image)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)

  const onCropChange = useCallback((newCrop) => {
    setCrop(newCrop)
  }, [])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    invokeFieldEvents(input, croppedAreaPixels)
  }, [])

  useEffect( () => {
    setImg(props.image)
  }, [props.image])

  return (
    <div>
      <div className={classes.cropContainer}>
            <Cropper
            image={img}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1.77}
            onCropChange={onCropChange}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
      </div>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            {props.translate(`resources.${process.env.REACT_APP_ARTICLE_RESOURCE}.form.imageCropper/zoom`)}            
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            decimals={2}
            scaleLength={0.1}
            aria-labelledby="Zoom"
            classes={{ container: classes.slider }}
            onChange={(e, zoom) => {
              setZoom(e)
            }}
          />
        </div>
      </div>
    </div>
  )
}

const StyledDemo = withStyles(styles)(Demo)

const ConnectedImageCropper = (props) => {
  const {name, source, label, ...other} = props
  return (
    <Field
      name={name}
      label={label} 
      component={StyledDemo}
      source={source}
      {...other}
  />
  )
}
export default translate(ConnectedImageCropper)