import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import CustomAutoComplete from '../CustomAutoComplete';
import EditorUtils from 'draft-js-plugins-utils';
import { withStyles } from '@material-ui/core';

const styles = {
  inputWrapper:{
    '&:before':{
      borderBottom:0,
    },
    '&:after':{
      borderBottom:0,
    },
    '&:hover:before':{
      borderBottom:"0 !important",
    }
  }
}

function fetchValues(q: String) {
  if(!q || q.length <= 3){
    return Promise.reject()
  }
  const request = new Request(`${process.env.REACT_APP_SEARCH_URL}/?q=${q}`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include'
  })
  return fetch(request)
    .then(response => (response.json()))
    .then(response => (Array.isArray(response) ? response : []))
}

function renderChipAvatar(tagItem:any) {
  let src = tagItem.image_path
  if (!src) {
    switch (tagItem.content_type) {
      case "team":
        src = `${process.env.REACT_APP_MEDIA_URL}/stats/logos/${tagItem. object_id}.png`
        break;
      case "competition":
        src = `${process.env.REACT_APP_MEDIA_URL}/stats/competitions/${tagItem. object_id}.png` 
        break;
      case "player":
        src = `${process.env.REACT_APP_MEDIA_URL}/stats/players/${tagItem. object_id}.png`
        break;
      default:
        break;
    }
  }
  return (<Avatar src={src} style={{ width: 30, height: 30 }}/>)
}


function renderSuggestion(suggestionProps: any) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem && (selectedItem.name || "").indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      { renderChipAvatar(suggestion) }
      {`${suggestion.name} ${suggestion.name_en}`}
    </MenuItem>
  );
}

function TagInput(props: any) {
  function formatSelectedItem(s:any) {
    return { person_id: s.object_id, person: s.name }
  }
  return (
    <CustomAutoComplete
      label="طاغ"
      name="person"
      fetchValues={fetchValues}
      itemToString={(item: any) => (item ? `${item.name} ${item.name_en}` : null)}
      findSelectedItem={(suggestions: Array<any>, selectedItemString: any) => (
        suggestions.find((item: any) => (item.name == selectedItemString))
      )}
      renderSuggestion={renderSuggestion}
      formatSelectedItem={formatSelectedItem}
      InputProps={{
        autoFocus: true,
      }}
      {...props}
    />

  )
} 

function LinkForm(props:any) {
  const onClose = () => props.onOverrideContent(undefined);

  const mutateEditorState = (value:{[x:string]: any}) => {
    if(! value ){
      return onClose();
    }
    const { content_type, object_id } = value
    
    const { getEditorState, setEditorState, lang } = props;

    let url = `/tag/${object_id}`
    switch (content_type) {
      case "team":
        url = `/analytics/team/${object_id}`
        break;
      case "competition":
        url = `/analytics/competition/${object_id}`
        break;
      case "player":
        url = `/analytics/player/${object_id}`
        break;
      default:
        break;
    }

    url = lang == "fr" ? `/fr${url}` : url

    setEditorState(EditorUtils.createLinkAtSelection(getEditorState(), url));
    onClose();
  }
  return (
    <div style={{ display:"flex", background: "#fff", padding: "0 12px 8px", }} >
      <TagInput onSelectedItemChange={mutateEditorState} classes={props.classes}/>
      <button 
        onClick={onClose} 
        style={{  
          width: "24px",
          height: "24px",
          border: "none",
          background: "transparent",
          display: "inline",
          position: "absolute",
          left: 0,
        }}
      >
        &times;
      </button>
    </div>
  )
}

export default withStyles(styles)(LinkForm)