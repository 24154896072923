import _ from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ar-ma'

dayjs.locale('ar-ma') // use loaded locale globally
dayjs.extend(customParseFormat)
dayjs.extend(utc)


class Player {
  private model : { [x:string]: any }
  constructor(player : { [x:string]: any }){
    this.model = player
  }
  public getInstance(){
    return {
      logo: this.getLogo(),
      activeMembership: this.getActiveMembership(),
      country: {
        name: this.model.country_of_birth,
        name_fr: this.model.country_of_birth_fr,
        name_en: this.model.country_of_birth_fr,
        country_id: this.model.country_of_birth_id,
      },
      nationality: {
        name: this.model.nationality,
        name_fr: this.model.nationality_fr,
        name_en: this.model.nationality_en,
        nationality_id: this.model.nationality_id,
      }
    }
  }
  getLogo(){
    return `${process.env.REACT_APP_MEDIA_URL}/stats/players/${this.model.player_id}.png`
  }
  getActiveMembership(){
    const ctst = this.model.membership.filter((m : { [x: string]:any } ) => m.active === 'yes')
    if (ctst && ctst.length > 0){
      return ctst[0]
    }
    return {}
  }
  transformFields() {
    if (this.model['country']) {
      const { name } = this.model['country']
      const { name_en = name, name_fr = name } = this.model['country']
      this.model = {
        ...this.model,
        'country_of_birth': name,
        'country_of_birth_fr': name_fr,
        'country_of_birth_en': name_en,
        'country_of_birth_id': this.model['country']['country_id'],
      }
      delete this.model['country']
    }
    if (this.model['nationality']) {
      const { name } = this.model['nationality']
      const { name_en = name, name_fr = name } = this.model['nationality']
      this.model = {
        ...this.model,
        'nationality': name,
        'nationality_fr': name_fr,
        'nationality_en': name_en,
        'nationality_id': this.model['nationality']['country_id'],
      }
    }
    return { ...this.model }
  }
}

export default Player
