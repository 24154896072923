import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
    tabField: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".74rem",
    },
    alignRight: {
        textAlign: 'right' as 'right'
    },
    tableHeader: {
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize: ".8rem",
        fontWeight: 700,
    },
}



const ProjectList = (props: any) => {
    const classes = props.classes;
    return (
        <List {...props} title="Projects">
            <Datagrid rowClick="edit">
                
                <TextField source="id" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="name" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />
                <TextField source="server" className={classNames(classes.tabField, classes.alignRight)} headerClassName={classNames(classes.tableHeader, classes.alignRight)} />

            </Datagrid>
        </List>
    )
};

export default withStyles(styles)(ProjectList)