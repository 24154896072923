import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, BooleanInput, required } from 'react-admin';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getDefaultFilterLang } from '../../filters/ContentFilter';
  
  
const styles = {
  inputLabel: {
    width:"50%",
    '& label':{
      transformOrigin: "top right",
      right: 0,
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".8rem",
    },
    '& input':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".9rem",
    },
    '& textarea':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      fontSize:".9rem",
    },
    '& .previews>div':{
      float: "right" as 'right',
    }
  },
  midSizedInput:{
    width: "50%",
  },
  largeSizedInput:{
    width: "70%",
  },
  smallSizedInput:{
    width: 130,
  },
  coloredLabel: {
    '& label':{
      fontWeight:700,
    }
  },
  boldText:{
    fontWeight: 700,
    color: "#687c8c",
    '& input':{
      color: "#687c8c",
    }
  },
  txtColor:{
    color:"red",
  },
  radioBtn:{
    '& label':{
      flexDirection: "row-reverse" as 'row-reverse',
      justifyContent: "flex-end",
      margin: 0,
      '&>span:first-child':{
        direction:"ltr" as 'ltr',
      },
      '&>span:last-child':{
        width: 100,
        display: "inline-block",
      }
    },
    '& span':{
      fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    }
  },

  goalsList:{
    '& p':{
      display:"none",
    },
    '& li':{
      marginBottom: 30,
      borderBottom: "none",
    }
  },
  isLTR:{
    direction: 'ltr' as 'ltr',
  }
}

const TagCreate = (props: any) => {
  const classes = props.classes;
  let defaultLang = getDefaultFilterLang()
  defaultLang = defaultLang == 'fr' ? 'fr' : 'ar'

  return(
    <Create {...props}>
      <SimpleForm defaultValue={{ status: 2, lang: defaultLang }} redirect="list">
        <SelectInput source="lang" initialValue={'ar'} choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
        <TextInput source="name" className={ classNames(classes.inputLabel, classes.midSizedInput) } validate={required()}/>
        <TextInput source="custom_name" className={ classNames(classes.inputLabel, classes.midSizedInput) } validate={required()}/>
        <TextInput source="slug" className={ classNames(classes.inputLabel, classes.midSizedInput) } />
        <BooleanInput source="is_trending" className={ classNames(classes.inputLabel, classes.midSizedInput) } />
      </SimpleForm>
    </Create>
  )
};

const TagCreateForm = withStyles(styles)(TagCreate)

export default TagCreateForm