export default {
    ar: {
        ra :{
            action: {
                add_filter: 'إضافة فلتر',
                add: 'إضافة',
                back: 'العودة',
                bulk_actions: '%{smart_count} العناصر المحددة',
                cancel: 'إلغاء',
                clear_input_value: 'إفراغ المدخلات',
                clone: 'استنساخ',
                confirm: 'تأكيد',
                create: 'إنشاء',
                delete: 'حذف',
                edit: 'تعديل',
                export: 'تصدير',
                list: 'قائمة',
                refresh: 'إعادة تحميل',
                remove_filter: 'إزالة هذا الفلتر',
                remove: 'إزالة',
                save: 'حفظ',
                search: 'بحث',
                show: 'عرض التفاصيل',
                sort: 'فرز',
                undo: 'تراجع',
            },
            boolean: {
                true: 'نعم',
                false: 'لا',
              },
              page: {
                create: 'إنشاء %{name}',
                dashboard: 'لوحة الإحصائيات',
                edit: '%{name} #%{id}',
                error: 'هناك خطأ ما',
                list: '%{name}',
                loading: 'جار التحميل',
                not_found: 'غير موجود',
                show: '%{name} #%{id}',
              },
              input: {
                file: {
                  upload_several: 'إسقاط بعض الملفات للتحميل ، أو انقر لتحديد واحد.',
                  upload_single: 'إسقاط ملف للتحميل ، أو انقر لتحديده.',
                },
                image: {
                  upload_several: 'قم بإسقاط بعض الصور للتحميل ، أو انقر لتحديد واحدة.',
                  upload_single: 'إسقاط صورة للتحميل ، أو انقر لتحديدها.',
                },
                references: {
                  all_missing: 'غير قادر على العثور على بيانات المراجع.',
                  many_missing: 'واحد على الأقل من المراجع المرتبطة لم تعد متوفرة.',
                  single_missing: 'المرجع المرتبط لم يعد يبدو متاحًا.',
                },
              },
              message: {
                about: 'حول',
                are_you_sure: 'هل أنت واثق؟',
                bulk_delete_content: 'هل أنت متأكد أنك تريد حذف هذا %{name}? |||| هل أنت متأكد من أنك تريد حذف هذه العناصر%{smart_count}?',
                bulk_delete_title: 'حذف %{name} |||| احذف عناصر%{smart_count}%{name}',
                delete_content: 'هل أنت متأكد أنك تريد حذف هذا البند؟',
                delete_title: 'حذف %{name} #%{id}',
                details: 'تفاصيل',
                error: 'حدث خطأ في العميل ولم يمكن إكمال طلبك.',
                invalid_form: 'النموذج غير صالح. يرجى التحقق من وجود أخطاء',
                loading: 'يتم تحميل الصفحة ، فقط لحظة من فضلك',
                no: 'لا',
                not_found: 'إما أن تكتب عنوان URL خاطئًا ، أو اتبعت رابطًا سيئًا.',
                yes: 'نعم ',
              },
              navigation: {
                no_results: 'لا توجد نتائج',
                no_more_results: 'رقم الصفحة%{page} خارج الحدود. جرب الصفحة السابقة.',
                page_out_of_boundaries: 'رقم الصفحة%{page} خارج الحدود',
                page_out_from_end: 'لا يمكن الذهاب بعد الصفحة الأخيرة',
                page_out_from_begin: 'لا يمكن الذهاب قبل الصفحة 1',
                page_range_info: '%{offsetBegin}-%{offsetEnd} من %{total}',
                page_rows_per_page: 'الصفوف لكل صفحة:',
                next: 'التالى',
                prev: 'السابق',
              },
              auth: {
                user_menu: 'الملف الشخصي',
                username: 'اسم المستخدم',
                password: 'كلمه السر',
                sign_in: 'تسجيل الدخول',
                sign_in_error: 'أخفقت المصادقة ، يرجى إعادة المحاولة',
                logout: 'الخروج',
              },
              notification: {
                updated: 'تم تحديث العنصر |||| تم تحديث%{smart_count} من العناصر',
                created: 'تم إنشاء العنصر',
                deleted: 'تم حذف العنصر |||| تم حذف%{smart_count} من العناصر',
                bad_item: 'عنصر غير صحيح',
                item_doesnt_exist: 'العنصر غير موجود',
                http_error: 'خطأ في اتصال الخادم',
                data_provider_error: 'خطأ في مزود البيانات. تحقق من وحدة التحكم للحصول على التفاصيل.',
                canceled: 'تم إلغاء الإجراء',
                logged_out: 'انتهت جلستك ، يرجى إعادة الاتصال.',
              },
              validation: {
                required: 'مطلوب',
                minLength: 'يجب أن يكون%{min} حرفًا على الأقل',
                maxLength: 'يجب أن يكون%{max} حرفًا أو أقل',
                minValue: 'يجب أن يكون%{min} على الأقل',
                maxValue: 'يجب أن يكون%{max} أو أقل',
                number: 'يجب أن يكون رقما',
                email: 'يجب أن يكون بريدًا إلكترونيًا صالحًا',
                oneOf: 'يجب أن يكون واحدًا من:%{options}',
                regex: 'يجب أن يتطابق مع تنسيق محدد (regex):%{pattern}',
              },
        },
        
        inputs: {
            'tags': {
                'placeholder': 'أضف ظاغ'
            },
            squad: {
                placeholder: 'ابحث عن طريق اسم اللاعب لإضافته إلى تشكيلة الفريق'
            }
        },
        resources: {
            [process.env.REACT_APP_ARTICLE_RESOURCE]: {
                name: 'المقالات',
                form: {
                    'imageCropper/showResult': 'أضهرها',
                    'imageCropper/rotation': 'ضورها',
                    'imageCropper/zoom': 'زووم',
                    'imageCropper/imageDialogTitle': 'نتيجة التقطيع',
                    'liveSpreadsheet/showButton': 'إضهار المقالات الآخرى',
                    'liveSpreadsheet/hideButton': 'إخفاء المقالات الآخرى',
                    'confirm/clearLocalStorage': 'مساحه تخزين المتصفح غير كافيه، هل تود حذف كل المقالات المسجلة؟'
                },
                fields: {
                    title:'العنوان',
                    'country.name_ar':'الدولة',
                    'author.username':'إسم الناشر',
                    "sport.name_ar":"الرياضة",
                    status: "حالة المقال",
                    url:'الرابط',
                    is_important: 'مهم',
                    description: 'وصف المقال',
                    w_image: 'الصورة',
                    embed: 'كود المقال',
                    Tags: 'طاغات المقال',
                    country: 'الدولة',
                    sport: 'الرياضة',
                    pub_date: 'تاريخ النشر',
                    on_slideshow: 'في الواجهة',
                    on_newsfeed: 'في المستجدات',
                    with_copyright: 'من إنتاج البطولة',
                    historical: 'تاريخي',
                    trending:'ترند',
                    is_global: 'عالمي',
                    socialSahring:'رابط المواقع الإجتماعية',
                    w_image_url:'رابط الصورة',
                    image_description: 'وصف الصورة',
                    with_watermark: "علامة البطولة",
                    custom_author: "كاتب المقال",
                    lang: "لغة"
                }
            },
            [process.env.REACT_APP_VIDEO_RESOURCE]: {
                name: 'فيديوهات',
                fields: {
                    title:'العنوان',
                    'country.name':'الدولة',
                    'author.username':'إسم الناشر',
                    url:'الرابط',
                    is_important: 'مهم',
                    description: 'وصف الفيديو',
                    w_image: 'الصورة',
                    embed: 'كود الفيديو',
                    Tags: 'طاغات الفيديو',
                    country: 'الدولة',
                    sport: 'الرياضة',
                    pub_date: 'تاريخ النشر',
                    on_slideshow: 'في الواجهة',
                    on_newsfeed: 'في المستجدات',
                    with_copyright: 'من إنتاج البطولة',
                    historical: 'تاريخي',
                    trending:'ترند',
                    is_global: 'عالمي',
                    socialSahring:'رابط المواقع الإجتماعية',
                    w_image_url:'رابط الصورة'
                }
            },
            [process.env.REACT_APP_COMPETITION_RESOURCE]: {
                name: 'البطولات',
                fields: {
                    name: 'الإسم',
                    name_en: 'الإسم الإجليزي',
                    name_fr: 'الإسم الفرنسي',
                    competition_id: 'رقم البطولة',
                    countrycode: 'كود الدولة',
                    type: 'نوع البطولة',
                    format: 'شكل البطولة'
                }
            },
            [process.env.REACT_APP_MATCH_RESOURCE]: {
                name: 'المباريات',
                form: {
                    competitionOrder: 'سيتم تحديث ترتيب هذه المسابقة'
                },
                fields:{
                    "competition.name":'المسابقة',
                    "round.name": 'الدورة',
                    "round.type": 'نوع المسابقة',
                    time_utc: 'توقيت المباراة',
                    date_utc: "تاريخ المباراة",
                    is_important: 'مباراة مهمة',
                    with_betting: "ضمن التوقعات" ,
                    highlight_embed: 'كود ملخص المباراة',
                    streaming_embed: "كود البث المباشر",
                    goals_videos: '',
                    person: 'مسجل الهدف' ,
                    minute:'دقيقة تسجيل الهدف',
                    url: 'رابط الهدف',
                    "goals.minute_extra": "sdsdsd"
                }
            },
            [process.env.REACT_APP_MATCHES_RESOURCE]: {
                fields:{
                    streaming_embed: "كود البث المباشر",
                }
            },
            [process.env.REACT_APP_TAG_RESOURCE]: {
                name: 'الطاغات',
                fields:{
                    name: 'إسم الطاغ',
                    name_fr: '(Francais) إسم الطاغ',
                    name_en: ' (English) إسم الطاغ',
                    custom_name: 'إسم الطاغ في الواجهة',
                    status: "حالة الطاغ",
                    custom_icon: 'أيقونة الطاغ ',
                    slug: 'إسم الطاغ في الرابط',
                    country: 'بلد الفريق',
                    is_trending: 'في الواجهة؟',
                    url: 'موقع الفريق',
                    city: 'مدينة الفريق',
                    founded: 'تاريخ التأسيس',
                    color1: 'لون الفريق الرئيسي',
                    color2: 'لون الفريق الثانوي',
                    "created_by.username":'إسم الناشر',
                }
            },
            [process.env.REACT_APP_TEAM_RESOURCE]: {
                name: 'الفرق',
                fields:{
                    contestant_id: 'رقم الفريق',
                    name: 'إسم الفريق',
                    name_fr: 'الاسم بالفرنسية',
                    name_en: 'الاسم بالإنجليزية',
                    country: 'بلد الفريق',
                    'country.name': 'بلد الفريق',
                    custom_name: 'إسم الفريق المعدل',
                    official_name: 'الاسم الرسمي',
                    official_name_fr: 'الاسم الرسمي بالفرنسية',
                    official_name_en: 'الاسم الرسمي بالإنجليزية',
                    short_name: 'الاسم المختصر',
                    short_name_fr: 'الاسم المختصر بالفرنسية',
                    short_name_en: 'الاسم المختصر بالإنجليزية',
                    code: 'الرمز',
                    type_name: 'Type',
                    team_type: 'نوع الفريق',
                    status: 'الحالة',
                    address_zip: 'عنوان الرمز البريدي',
                    details: 'تفاصيل',
                    postal_address: 'العنوان البريدي',
                    postal_number: 'الرقم البريدي',
                    postal_extra: 'الإضافية البريدية',
                    url: 'موقع الفريق',
                    city: 'مدينة الفريق',
                    founded: 'تاريخ التأسيس',
                    color1: 'لون الفريق الرئيسي',
                    color2: 'لون الفريق الثانوي',
                }
            },
            [process.env.REACT_APP_PLAYER_RESOURCE]: {
                name: 'اللاعبون',
                fields:{
                    image_50: 'صورة اللاعب',
                    first_name: 'الاسم الاول',
                    last_name: 'اسم العائلة',
                    match_name: 'اسم المباراة',

                    first_name_fr: 'الاسم الاول بالفرنسية',
                    last_name_fr: 'اسم العائلة بالفرنسية',
                    match_name_fr: 'اسم المباراة بالفرنسية',

                    first_name_en: ' الاسم الاول بالإنجليزية',
                    last_name_en: 'اسم العائلة بالإنجليزية',
                    match_name_en: 'اسم المباراة بالإنجليزية',

                    player_id: 'ID',
                    "active_membership.club_name": 'الفريق',
                    "nationality.name": 'الجنسية',
                    height: 'طول القامة',
                    weight: 'الوزن',
                    position: 'الموقع',
                    date_of_birth: 'تاريخ الولادة',
                    foot: 'قدم',
                    gender: 'جنس',
                    status: 'الحالة',
                    country_of_birth: 'بلد الميلاد',
                    'activeMembership.contestant_name': 'الفريق الحالي'
                }
            },
            [process.env.REACT_APP_NOTIFICATION_RESOURCE]: {
                name: 'الاشعارات',
                fields: {
                    title: 'عنوان الاشعار',
                    'author.username': 'كاتب المقال',
                    'country.name_ar': 'دولة الاشعار',
                    category: 'نوع المادة',
                    pub_date: 'تاريخ النشر',
                    url: 'رابط المادة',
                    description:'وصف الاشعار',
                    image:'صورة الاشعار',
                    tags: 'طاغات المادة',
                    test_only: 'تجربة فقط',
                    platform: 'المنصة',
                    custom_token: 'الرمز المخصص',
                }
            },
            [process.env.REACT_APP_COMMENT_RESOURCE]: {
                name: 'التعليقات',
                fields: {
                    comment: "التعليق",
                    is_public: "منشور",
                    absolute_url: "الرابط",
                    "user.username": 'صاحب التعليق',
                }
            },
            [process.env.REACT_APP_NEWSFEED_RESOURCE]: {
                name: 'المستجدات',
                fields: {
                    title: 'العنوان',
                    content_type: 'نوع المادة',
                    pub_date: 'تاريخ النشر',
                    is_important:'مهم',
                    absolute_url: 'رابط المادة',
                    url: "الرابط",
                    has_copyright: "حقوق الملكية",
                    "image.thumb_path": "رابط الصورة",
                    w_image: 'صورة المقال',
                    sport: 'الرياضة',
                    country: 'الدولة',
                    tags: 'طاغات المادة'
                }
            },
            [process.env.REACT_APP_FLATPAGE_RESOURCE]: {
                name: 'صفحات',
                fields:{
                    title: 'العنوان',
                    content:"المحتوى",
                    absolute_url: 'رابط المادة',
                }
            },
            [process.env.REACT_APP_ROOM_RESOURCE]: {
                name: 'صالات الدردشة',
                fields:{
                    title: 'العنوان',
                    ordering:"ترتيب",
                    type:"نوع الدردشة",
                    match_id:"رقم المباراة",
                    absolute_url: 'رابط المادة',
                    opening_message : "الرسالة الافتتاحية",
                    "image.thumb_path": "رابط الصورة",
                    team_A_name: "اسم الفريق المستقبل",
                    team_B_name: "اسم الفريق الزائر",
                    team_A_color: "لون الفريق المستقبل",
                    team_B_color: "لون الفريق الزائر",
                    team_A_id:'رقم الفريق المستقبل',
                    team_B_id:'رقم الفريق الزائر',
                    w_image: 'الصورة',
                    w_image_url:'رابط الصورة',
                }
            },
            [process.env.REACT_APP_SQUAD_RESOURCE]: {
                name: 'تشكيلة الفرق',
                fields:{
                    contestant_name: 'إسم الفريق',
                    logo: 'شعار الفريق',
                }
            },
        },
        login: {
            error5xx: "المعذرة، لدينا عطب في الخادم.",
            incorrectUsernameOrPassowrd: "إسم المستخدم أو كلمة السر غير صحيحة",
            notStaff: "You dont have the right to be here",
            noPermissions: "You dont have enough permissions to access this platform"
        },
    }
}