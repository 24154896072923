import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import EventList from './EventList';
import groupByDay from './groupByDay';
import _ from 'lodash'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Mutation, withDataProvider, showNotification, CREATE, push } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import classNames from 'classnames';

const styles = {
    root: {
        // width: 600,
        marginTop: 10,
        padding: 0
    },
    day: {
        marginBottom: '1em',
    },
    pullLeft: {
        marginLeft: 'auto'
    },
    header:{
       display:"flex",
       padding: "10px 16px",
    },
    competitionFanion:{
        verticalAlign:"middle",
        marginLeft:"16px",
        width:25,
    },
    orderInput:{
        flexDirection: "row-reverse" as 'row-reverse',
        '& input' :{
            textAlign: 'left' as 'left',
            width: "60px",
        }
    },
    orderInputLabel:{
        '& p':{
            fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
            fontSize:".74rem",
        }
    },
    link:{
        textDecoration: "none",
        color: "#697c8b",
    },
    title:{
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontWeight:700,
        color: "#4d5c69",
        fontSize:".94rem",
        padding: 0,
        marginRight:13
    },
    button:{
        width:32,
        height:32
    }
};

class _ReorderInput extends React.Component<any, any> {
    constructor(props:any){
        super(props)
        this.state = {
            newOrder: props.value,
        }
    }
    getOrderOperator(displayOrder:any){
        throw new Error("getOrderOperator didn't get implemented")
    }
    getPayload(){
        const { newOrder } = this.state
        const { records, value, date } = this.props
        const ids = records.map((i:any) => i.match_id)
        
        if(newOrder === value || ! newOrder) return;
        let displayOrder = parseInt(newOrder)
        if(displayOrder < 1) return; 

        const payload = { 
            data: {
                display_order: displayOrder, 
                competition_id: records[0].competition.id,
                date: date
            }
        };
        return payload
    }
    handleBlur = () => {
        const { dataProvider } = this.props;
        dataProvider(CREATE, `${process.env.REACT_APP_MATCHES_RESOURCE}/display_order`, this.getPayload(), this.getOptions());
    }
    getOptions(){
        return {
            undoable: true,
            onSuccess: {
                notification: { body: `resources.${process.env.REACT_APP_MATCHES_RESOURCE}.form.competitionOrder`, level: 'info' },
            },
            onError: {
                notification: { body: 'Error: competition did not change', level: 'warning' }
            }
        };
    }
    getIcon(){
        throw new Error("getIcon didn't get implemented")
        return <></>
    }

    render(){
        const classes = this.props.classes
        return (
                // <TextField
                //     id="filled-number"
                //     label="Order"
                //     value={this.state.newOrder}
                //     type="number"
                //     // InputLabelProps={{
                //     //     shrink: true,
                //     // }}
                //     margin="normal"
                //     onChange={(e:any) => {
                //         this.setState({ newOrder: e.target.value })
                //     }} onBlur={this.handleBlur}
                // />
                <Input
                    id="filled-number"
                    value={this.state.newOrder}
                    className={classes.orderInput}
                    type="number"
                    onChange={(e:any) => {
                        this.setState({ newOrder: e.target.value })
                    }} onBlur={this.handleBlur}
                    endAdornment={<InputAdornment position="start" className={classes.orderInputLabel}>ترتيب</InputAdornment>}
                    inputProps={{
                    'aria-label': 'ترتيب',
                    }}
                />
        )
    }
}

const ReorderInput = withDataProvider(withStyles(styles)(_ReorderInput))

const sortByDate = (a:any, b:any) => new Date(b).valueOf() - new Date(a).valueOf();

const mapMatchesToCompetitions = function mapMatchesToCompetitions(matches: any){
    return Array.from(matches.keys()).map( k =>  {
      const firstMatch : any = _.first(matches.get(k))
      if(! firstMatch){
        return null
      }  
      return firstMatch.competition
    } ).filter(Boolean)
  }

const groupByCompetition = (matches:any) => {
    const sortBy = [ 'competition.display_order']
    const sortedMatches = _.sortBy(matches, sortBy);
    const groupByManually = sortedMatches.reduce((p, n) => {
      p.set(n.competition.id, (p.get(n.competition.id) || []).concat([n]))
      return p
    }, new Map())
    const competitions = mapMatchesToCompetitions(groupByManually)
    return {
        comps: competitions,
        matchesByComp: groupByManually,
    };
}

class TimelineLoadedViews extends React.Component<any, any> {
    constructor(props:any){
        super(props)
        this.state = {
            collapsedComps : props.collapsedComps || []
        }
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.collapsedComps.length !== this.props.collapsedComps.length) {
            this.setState({ collapsedComps: this.props.collapsedComps })
        }
    }
    handleCollapse = (compId:string) => {

        const results = this.state.collapsedComps.filter((comp:string) => {
            
            if(comp === compId){
                return true
            } 
            return false
        })

        if(results.length > 0){
            this.setState({ collapsedComps: this.state.collapsedComps.filter((comp:string) => { return comp !== compId }) })
        } else {
            this.state.collapsedComps.push(compId) 
            this.setState({ collapsedComps: this.state.collapsedComps})
        }
    }
    render(){
        const {events= [], handleLoadMore, total, classes}= this.props;

        const { comps, matchesByComp } = groupByCompetition(events);
        
        if (events.length === 0) {
            return (
                <Typography color="error">
                    Error: This list should not be empty.
                </Typography>
            );
        }

        return (
        <>
        {
            Array.from(matchesByComp.keys()).map((compId:any, compIndex:any) => {
                return <>
                    <Paper className={classes.root} elevation={3}>
                        <div className={classes.header}>
                            <IconButton color="primary" className={classes.button} onClick={this.handleCollapse.bind(this, compId)}>
                                <SvgIcon><path xmlns="http://www.w3.org/2000/svg" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></SvgIcon>
                            </IconButton>
                            <Typography variant="subheading" component="h2" className={classNames(classes.header, classes.title)}>
                                <a href={`#/${process.env.REACT_APP_COMPETITION_RESOURCE}/${matchesByComp.get(compId)[0].competition.competition_id}`} className={classes.link}>
                                    <img className={classes.competitionFanion} src={`//images.elbotola.com/stats/competitions/25/${matchesByComp.get(compId)[0].competition.competition_id}_25.png`} />
                                    {matchesByComp.get(compId)[0].competition.name} 
                                </a>
                                <div style={{ float: "left" }}>
                                    <ReorderInput 
                                        records={matchesByComp.get(compId)} 
                                        value={matchesByComp.get(compId)[0].competition.display_order}
                                        date={this.props.date}
                                        />
                                </div>
                            </Typography>
                        </div>
                            {
                                this.state.collapsedComps.filter((comp:string) => comp === compId).length === 0 && 
                                    <EventList events={matchesByComp.get(compId)} />
                            }
                    </Paper>
                </>
        
            })
        }
        </>
        )
    }
}








const TimelineLoadedView = ({
    events = [],
    handleLoadMore,
    total,
    classes,
}: { [x: string]: any }) => {
    const { comps, matchesByComp } = groupByCompetition(events);
    if (events.length === 0) {
        return (
            <Typography color="error">
                Error: This list should not be empty.
            </Typography>
        );
    }
    const moveCompetitionUp = (comp:any) => {

    }
    return (
        <>
        {
            Array.from(matchesByComp.keys()).map((compId:any, compIndex:any) => {
                return <>
                    <Paper className={classes.root} elevation={3}>
                        <div className={classes.header}>
                            <IconButton color="primary" className={classes.button}>
                                <SvgIcon><path xmlns="http://www.w3.org/2000/svg" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></SvgIcon>
                            </IconButton>
                            <Typography variant="subheading" component="h2" className={classNames(classes.header, classes.title)}>
                                <a href={`#/${process.env.REACT_APP_COMPETITION_RESOURCE}/${matchesByComp.get(compId)[0].competition.competition_id}`} className={classes.link}>
                                    <img className={classes.competitionFanion} src={`//images.elbotola.com/stats/competitions/25/${matchesByComp.get(compId)[0].competition.competition_id}_25.png`} />
                                    {matchesByComp.get(compId)[0].competition.name} 
                                </a>
                                <div style={{ float: "left" }}>
                                    <ReorderInput records={matchesByComp.get(compId)} value={matchesByComp.get(compId)[0].competition.display_order}/>
                                </div>
                            </Typography>
                        </div>
                            {
                            <EventList events={matchesByComp.get(compId)} />
                            }
                    </Paper>
                </>
        
            })
        }
        </>
    )            
};

const TimelineLoaded = withStyles(styles)(TimelineLoadedViews);

export default TimelineLoaded;