import React from 'react';
import { connect } from 'react-redux';
import { Edit, SimpleForm, DisabledInput, ImageField } from 'react-admin';
import SquadInput from '../../inputs/SquadInput';


const CustomTitle = (props:any) => {
  return (
    <span style={{ fontSize: "1.2rem", lineHeight: "250%" }}>تعديل معلومات مسابقة {props.record.name}</span>
  )
}

const SquadEdit = (props: any) => {
  const { classes } = props
  
  return <Edit  {...props} title={<CustomTitle/>}>
      <SimpleForm redirect={false} handleSubmit={(Submit: any) => {
        if (props.dirtyFields && props.form) {
          const dirtyValues = Object.keys(props.dirtyFields).reduce((p: any, n: any) => {
            let key = n
            let value = props.form["record-form"].values[n]
            return { ...p, [key]: value }
          }, {})
          return Submit(dirtyValues)
        }
        return Submit
      }}>
          <ImageField source='logo' customLabel='شعار الفريق'/>
          <DisabledInput source="contestant_name" />
          <DisabledInput label="Squad ID" source="squad_id" />
          <DisabledInput source="contestant_id" label="Team ID" />

          <SquadInput />
      </SimpleForm>
  </Edit>
};

export default connect(
  (state: any, props: any) => {
    

    if (state && state.form && state.form['record-form']) {
      const recordForm = state.form['record-form']
      const { initial: { person: initialPerson = [] } = {} } = recordForm
      const { values: { person: valuesPerson = [] } = {} } = recordForm
      let dirtyFields = {}
      if(initialPerson != valuesPerson) {
        dirtyFields = { person: {visited: true, touched: true} }
      }
      
      if(recordForm.anyTouched) {
        dirtyFields = { ...dirtyFields, ...recordForm.fields }
      }
      return { ...state, dirtyFields }
      
    }
    
    return state
  }, {} )(SquadEdit)