import _ from 'lodash'


class ChatRoom {
  private model : { [x:string]: any }
  constructor(chatroom : { [x:string]: any }){
    this.model = chatroom
  }
  public getInstance(){
    return {
      w_image: this.model['image']
    }
  }

  public transformFields() {
    const w_fields:{ [x:string]: string } = {'tags': 'w_tags'}
  
    const formData = new FormData()
    Object.keys(this.model).map(field => {
      let value = this.model[field]
      if(field == 'tags'){
        value = value.map((item:any) => ( _.pick(item, ["object_id", "content_type", "name"])))
      }
      if (field == "w_image"){
        value = value['rawFile']
      }
      else{
        value = (typeof value == "string") ? value : JSON.stringify(value)
      }
      if(w_fields[field]){
        formData.append(w_fields[field], value)
      }
      else {
        formData.append(field, value)
      }
      
    })
    return formData
  }
}

export default ChatRoom
