import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, BooleanInput, required} from 'react-admin';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TagsInput from '../../inputs/TagsInputs';
import CustomAutoComplete from '../../inputs/CustomAutoComplete';
import { NotificationPreviewComponent } from './Edit';
import { Field } from 'redux-form';
import CountrySearchInput from '../../inputs/CountrySearchInput';


  const styles = {
    tabLabel:{
      background:"blue",
    },
    inputLabel: {
      width:'50%',
      '& label':{
        transformOrigin: "top right",
        right: 0,
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".74rem",
      },
      '& input':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
        fontSize:".84rem",
      }
    },
    coloredLabel: {
      '& label':{
        fontWeight:700,
      }
    },
    boldText:{
      fontWeight: 700,
      color: "#687c8c",
      '& input':{
        color: "#687c8c",
      }
    },
    txtColor:{
      color:"red",
    },
    radioBtn:{
      '& label':{
        flexDirection: "row-reverse" as 'row-reverse',
        justifyContent: "flex-end",
        margin: 0,
        '&>span:first-child':{
          direction:"ltr" as 'ltr',
        },
        '&>span:last-child':{
          width: 100,
          display: "inline-block",
        }
      },
      '& span':{
        fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
      }
    },
  
    tagsList:{
      '& p':{
        display:"none",
      },
      '& li':{
        marginBottom: 30,
        borderBottom: "none",
      }
    },
    isLTR:{
      direction: 'ltr' as 'ltr',
    }
  }
  
const NotificationCreate = (props: any) => {
  const [defaultValues, setDefaultValues] = React.useState({  lang: "ar" });
  const [previewValues, setPreviewValues] = React.useState({});
  const classes = props.classes;
  return(
    <Create {...props} title="إنشاء إشعار ">
      <SimpleForm defaultValue={defaultValues} onChange={(e:any) => {
          if(e.target && e.target.name == "url"){
              const formData = new FormData()
              formData.append("url", e.target.value)
              const request = new Request(`${process.env.REACT_APP_AUTH_ENDPOINT}/notifications/get_data/`, {
                  method: 'POST',
                  body: formData
              })

              fetch(request).then(response => (
                response.ok ? response.json() : null
              )).then(response => {
                if(!response) { return }
                  setDefaultValues({ ...response })
                  setPreviewValues({ title: response.title, description: response.description})
              })
          }
          if(e.target && e.target.name == "title"){
            setPreviewValues({ ...previewValues, title: e.target.value })
          }
          if(e.target && e.target.name == "description"){
            setPreviewValues({ ...previewValues, description: e.target.value })
          }
      }}>
        <SelectInput source="lang" choices={[{ id: 'fr', name: 'Français' }, { id: 'ar', name: 'عربية' }]} />
        <TextInput source="url" className={ classNames(classes.inputLabel, classes.isLTR) } />
        <SelectInput source="category" className={ classes.inputLabel }
            choices={[
              { id: "article", name: 'مقال' },
              { id: "match", name: 'مباراة' },
              { id: "video", name: 'فيديو' },
              { id: "betting", name: 'توقعات' },
              { id: "chat", name: 'شات' },
              { id: "promotion", name: 'ترويج' },
            ]} 
          />
        <TextInput source="description"className={ classes.inputLabel } multiline rows={2} validate={required()}/>
        <TextInput source="title" className={ classes.inputLabel } validate={required()}/>
        <TextInput source="image" className={ classes.inputLabel } validate={required()}/>

        
        <ImagePreview  name="image" required/>
        
        <TagsInput label="طاغات المادة"/>
        <CountrySearchInput
                  className={ classes.inputLabel } 
                  required
                />
        <BooleanInput source="test_only" label="Test Only"/>

       <NotificationPreviewComponent {...previewValues}/>
      </SimpleForm>
    </Create>
  )
};

const NotificationCreateForm = withStyles(styles)(NotificationCreate)

export default connect(
  (state: any, props: any) => {
    return state
  },
  {}
)(NotificationCreateForm);


const isRequired = (value:any) => {
  return value && value.length > 0 ? undefined : `مطلوب`
}

const ImagePreview = (props:any) => {
  const { required } = props
  return (
    <Field
      name={props.name}
      component={(componentProps:any) => {
        return (<img src={componentProps.input.value} style={{ width: '50%' }}/>)
      }}
      validate={required ? [isRequired] : []}
    />
  )
};