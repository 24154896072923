import React from 'react';
import {
    Create, SimpleForm, translate, BooleanInput,
    SelectInput, NumberInput
} from 'react-admin';
import { connect } from "react-redux";
import TagsInput from '../../inputs/TagsInputs';
import { DateTimeInput } from "react-admin-date-inputs";
import _ from 'lodash'
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';


const styles = (theme: any) => ({
    control: {
        padding: theme.spacing.unit,
        marginBottom: 24,
        minWidth: '350px',
    }


});


const CustomPaper = (props: any) => {
    return (
        <Paper elevation={1} style={{ width: "100%", padding: "4px 12px", marginBottom: 12, boxSizing: "border-box" }}>
            {props.children}
        </Paper>
    )
}
const TransferCreate = (props: any) => {
    const classes = props.classes;

    return (
        <Create {...props}>
            <SimpleForm redirect={'list'} >
                <CustomPaper>
                    <TagsInput
                        name="inPerson"
                        source="inPerson"
                        multiple={false}
                        searchType={"player"}
                        label="اللاعب"
                        placeholder="اللاعب"
                    />
                </CustomPaper>
                <CustomPaper>
                    <TagsInput
                        name="from"
                        source="from"
                        multiple={false}
                        searchType={"contestant"}
                        label="انتقل من"
                        placeholder="انتقل من"
                    />
                </CustomPaper>
                <CustomPaper>
                    <TagsInput
                        name="to"
                        source="to"
                        multiple={false}
                        searchType={"contestant"}
                        label="انتقل الى"
                        placeholder="انتقل الى"
                    />
                </CustomPaper>

                <CustomPaper>
                    <NumberInput
                        source="value"
                        label="القيمة (€)"
                    />

                </CustomPaper>
                <CustomPaper>
                    <SelectInput
                        label="نوع الانتقال"
                        source="transfer_type"
                        choices={[
                            { id: "rental", name: "Rental" },
                            { id: "rental end", name: "Rental End" },
                            { id: "transfer", name: "Transfer" },
                            { id: "retirement", name: "Retirement" },
                            { id: "draft", name: "Draft" },
                            { id: "released", name: "Released" },
                            { id: "signed", name: "Signed" },
                            { id: "unknown", name: "Unknown" },
                        ]}
                    />
                </CustomPaper>
                <CustomPaper>
                    <DateTimeInput
                        source="announced_date"
                        label="التاريخ المعلن"
                        options={{ format: 'dd/MM/YYYY HH:mm', ampm: false}}

                    />
                </CustomPaper>

                <CustomPaper>
                    <BooleanInput
                        source="is_important"
                        label="مهم"
                    />
                </CustomPaper>

            </SimpleForm>
        </Create>
    )
}

const TransferCreateView = translate(TransferCreate)

const TransferCreateForm = withStyles(styles)(TransferCreateView)

export default connect(
    (state: any, props: any) => {
        return state
    },
    {}
)(TransferCreateForm);