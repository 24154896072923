import _ from 'lodash';


class Notification {
  private model : { [x:string]: any }
  constructor(notification : { [x:string]: any }){
    this.model = notification
  }

  transformFields() {
    const w_fields:{ [x:string]: string } = {'tags': 'w_tags', 'country': 'w_country'}
    Object.keys(w_fields).map(field => {
        let value = this.model[field]
        if(!value) { return }
        if(field == 'tags'){
            value = value.map((item:any) => ( _.pick(item, ["object_id", "content_type", "name"])))
        }
        value = (typeof value == "string" ) ? value : JSON.stringify(value)
        this.model[w_fields[field]] = value
        delete this.model[field]
    })
    return { ...this.model }
  }
}

export default Notification
