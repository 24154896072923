import React from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import EventItem from './EventItem';

const styles = {
    root: {
        width: 600,
    },
    list:{
        padding: 0,
    }
};

const EventListView = ({ events = [], classes } : {[ x:string]: any }) => (
    // <Card className={classes.root}>
        <List className={classes.list}>
            {events.map((event: {[ x:string]: any }) => (
                <EventItem event={event} key={event.id} />
            ))}
        </List>
    // </Card>
);


const EventList = withStyles(styles)(EventListView);

export default EventList;