import React, { useState } from 'react';
import { Avatar, Input, InputLabel, withStyles, SvgIcon, CircularProgress } from "@material-ui/core";
import Cookies from 'js-cookie';


const styles = {
  label: {
    display: "block",
    fontSize: ".70rem",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: "10px",
    marginBottom: 5
  },
  input: {
    height: 0,
    width: 0,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 0,
    '& img ': {
      width: "100%",
    }
  },
  uploadIcon: {
    width: 30,
    height: 30,
    fill: "#fff",
    background: "#25a8fe",
    padding: 2,
    borderRadius: "50%",
  }
}


const AnalyticsLogoInput = (props: any) => {
  const altProps = props.getAltProps(props)
  const [imgSrc, setImgSrc] = useState(altProps.logo)
  const [error, setError] = useState<null | String>(null)
  const [loading, setLoading] = useState(false)

  const onImageChange = async (e: any) => {
    setError(null)
    setLoading(true)
    const { target: { files } } = e
    const { content_type, object_id } = altProps
    if (!files || !files[0]) { return }

    var form_data = new FormData();
    form_data.append('image', files[0])
    form_data.append('content_type', content_type)
    form_data.append('object_id', object_id)

    const url = `${process.env.REACT_APP_API_ENDPOINT}/cdn/`

    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken') || ""
      },
      body: form_data
    }).then(async (response) => {
      const responseContent = await response.json()
      if (response.status != 201) {
        let err: Array<String> = []
        Object.keys(responseContent).map(k => (err.push(responseContent[k][0])))
        setError(err.join("  "))
      }
      else {
        setImgSrc(responseContent["image"])
      }
      setLoading(false)
    }).catch(err => {
      setError(err)
      setLoading(false)
    })
  }
  const { customLabel, classes } = props;
  return (
    <div>
      <span className={classes.label}>{customLabel}</span>
      <Input inputProps={{ type: 'file' }} onChange={onImageChange} id="logo-input" className={classes.input} />
      <InputLabel htmlFor="logo-input">
        {
          (error && typeof (error) == "string") && (<p style={{ color: "red" }}>{error}</p>)
        }
        { 
          loading && <p><CircularProgress /> جاري التحميل</p>
        }
        <Avatar
          component={loading ? undefined: 'div'}
          key={4}
          src={imgSrc}
          className={classes.avatar}
        />
        <SvgIcon className={classes.uploadIcon}>
          <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
        </SvgIcon>
      </InputLabel>
    </div>
  );
};

export default withStyles(styles)(AnalyticsLogoInput)