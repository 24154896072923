import React, { ReactNode, ReactElement, ComponentType } from 'react';
import logo from './logo.svg';
import './App.css';
import CustomMenu from './CustomMenu';
// import '../node_modules/medium-draft/dist/medium-draft.css';
// import '../node_modules/medium-draft/dist/medium-draft.js';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import drfProvider, { AnalyticsDataProvider } from './services/DataProvider';
import AuthProvider from './services/AuthProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import Public from '@material-ui/icons/Public';
import Notifications from '@material-ui/icons/Notifications';
import Assignment from '@material-ui/icons/Assignment';
import Whatshot from '@material-ui/icons/Whatshot';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import SettingsIcon from '@material-ui/icons/Settings';
import Comment from '@material-ui/icons/Comment';
import Chat from '@material-ui/icons/Chat';
import Event from '@material-ui/icons/Event';
import Label from '@material-ui/icons/Label';
import StarsIcon from '@material-ui/icons/Stars';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import GrainIcon from '@material-ui/icons/Grain';
import SecurityIcon from '@material-ui/icons/Security';
import FormatListNumberedIcon from '@material-ui/icons//FormatListNumbered';

import ArticlesList from './views/articles/List';
import ArticleCreate from './views/articles/Create';
import ArticleEdit from './views/articles/Edit';

import TeamsList from './views/teams/List';
import TeamsEdit from './views/teams/Edit';

import CompetitionsList from './views/competitions/List';
import CompetitionsEdit from './views/competitions/Edit';

import MatchesTimeline from './views/matches/Timeline';
import MatchesEdit from './views/matches/Edit';

import TagList from './views/tags/List';
import TagCreate from './views/tags/Create';
import TagEdit from './views/tags/Edit';

import NotificationList from './views/notifications/List';
import NotificationCreate from './views/notifications/Create';
import NotificationEdit from './views/notifications/Edit';

import NewsfeedComponent from './views/newsfeed/List';
import NewsfeedEdit from './views/newsfeed/Edit';
import NewsfeedCreate from './views/newsfeed/Create';

import FlatpageListForm from './views/flatpages/List';
import FlatpageCreateForm from './views/flatpages/Create';
import FlatpageEditForm from './views/flatpages/Edit';

import ChatroomsListForm from './views/chatrooms/List';
import ChatroomsCreateForm from './views/chatrooms/Create';
import ChatroomsEditForm from './views/chatrooms/Edit';

import CommentsListForm from './views/comments/List';

import { errorSagas } from './services/Sagas';
import domainMessages from './i18n'

import VideoList from './views/videos/List';
import VideoEdit from './views/videos/Edit';
import VideoCreate from './views/videos/Create';

import PlayersList from './views/players/List';
import PlayersEdit from './views/players/Edit';
import PlayersCreate from './views/players/Create';


import SquadsList from './views/squads/List';
import SquadEdit from './views/squads/Edit';

import StandingsList from './views/standings/List';
import StandingsEdit from './views/standings/Edit';

import ProjectList from './views/scheduler/projects/List';
import ProjectEdit from './views/scheduler/projects/Edit';
import ProjectCreate from './views/scheduler/projects/Create';

import JobList from './views/scheduler/jobs/List';
import JobEdit from './views/scheduler/jobs/Edit';
import JobCreate from './views/scheduler/jobs/Create';

import JobExecutionList from './views/scheduler/jobExecutions/List';
import JobExecutionLog from './views/scheduler/jobExecutions/Log';

import FeedList from './views/aitools/FeedList';

import TransferList from './views/transfers/List';
import TransferEdit from './views/transfers/Edit';
import TransferCreate from './views/transfers/Create';


import CustomLayout from './CustomLayout';


import { Route } from 'react-router-dom';


const cutsomRoutes = [
  <Route exact path={`/${process.env.REACT_APP_MATCHES_RESOURCE}/:journee`} component={(MatchesTimeline)} />,
  <Route
    exact
    path={`/${process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE}/:id/log`}
    component={(JobExecutionLog)}
  />,
]

export const dataProvider = AnalyticsDataProvider(drfProvider())

const messages : {[x:string]: any} = {
  'ar': {...domainMessages.ar}
};

const i18nProvider = (locale:any) => messages[locale]

const ELBOTOLA_THEME = {
  main: "#25A9FE",
  dark: "#25A9FE",
  light: "#25A9FE",
  contrastText: "#FFFFFF"
}

const theme = createMuiTheme({
  direction: 'rtl',
  palette: {
    primary: ELBOTOLA_THEME,
    secondary: ELBOTOLA_THEME
},
  typography: {
    fontFamily: [
      'Roboto', 'Droid Arabic Kufi', 'Helvetica', 'sans-serif'
    ].join(','),
  },
});

// ARTICLE_MANAGE = "manage_article"
// ARTICLE_EDIT = "edit_article"
// VIDEO_MANAGE = "manage_video"
// GALLERY_MANAGE = "manage_gallery"
// ANALYTICS_MANAGE = "manage_match"
// CHAT_MANAGE = "manage_room"
// COMMENTS_MANAGE = "manage_comment"
// NEWSFEED_MANAGE = "manage_newsfeed"
// NOTIFICATIONS_EDIT = "edit_notification"
// SWEARWORDS_EDIT = "edit_swearword"
// USER_EDIT = "edit_user"
// FLATPAGE_MANAGE = "manage_flatpage"
const PERMISSIONS : {
  [x:string]: any[]
} = {
  'edit_notification':[
    <Resource name={`${process.env.REACT_APP_NOTIFICATION_RESOURCE}`} edit={NotificationEdit} create={NotificationCreate} list={NotificationList} options={{ label:"الاشعارات", section: 'Social', Icon: <Notifications /> }}/>
  ],
  'manage_article': [
    <Resource name={`${process.env.REACT_APP_ARTICLE_RESOURCE}`} list={ArticlesList} create={ArticleCreate} edit={ArticleEdit} options={{ label: 'مقالات', section: 'Content', Icon: <Assignment /> }} />,
    <Resource name={`${process.env.REACT_APP_AI_FEEDS_RESOURCE}`} list={FeedList} options={{ label: 'Feeds', section: 'Content', Icon: <SettingsIcon /> }} />,
  ],
  'manage_tag': [
    <Resource name={`${process.env.REACT_APP_TAG_RESOURCE}`} edit={TagEdit} create={TagCreate} list={TagList} options={{ label: 'الطاغات', section: 'Content', Icon: <Label /> }}/>,
  ],
  'manage_comment':[
    <Resource name={`${process.env.REACT_APP_COMMENT_RESOURCE}`} list={CommentsListForm} options={{ label: 'التعليقات', section: 'Social', Icon: <Comment />  }}/>
  ],
  'manage_newsfeed':[
    <Resource name={`${process.env.REACT_APP_NEWSFEED_RESOURCE}`} list={NewsfeedComponent} edit={NewsfeedEdit} create={NewsfeedCreate} options={{ label: 'المستجدات', section: 'Content', Icon: <Whatshot /> }} />
  ],
  'manage_flatpage': [
    <Resource name={`${process.env.REACT_APP_FLATPAGE_RESOURCE}`} edit={FlatpageEditForm} list={FlatpageListForm} create={FlatpageCreateForm} options={{ label: 'صفحات', section: 'Content', Icon: <Public /> }}/>
  ],
  'manage_match': [
    <Resource name={`${process.env.REACT_APP_MATCHES_RESOURCE}`} list={MatchesTimeline} edit={MatchesEdit} options={{ label: 'المباريات', section: 'Analytics', Icon: <Event />}} />,
    <Resource name={`${process.env.REACT_APP_MATCH_RESOURCE}`} edit={MatchesEdit} />,
    <Resource name={`${process.env.REACT_APP_STANDINGS_RESOURCE}`} list={StandingsList} edit={StandingsEdit} options={{ label: 'جداول الترتيب',  section: 'Analytics', Icon: <FormatListNumberedIcon />}}/>
  ],
  'manage_competition': [
    <Resource name={`${process.env.REACT_APP_COMPETITION_RESOURCE}`} edit={CompetitionsEdit} list={CompetitionsList} options={{ label: 'البطولات',  section: 'Analytics', Icon: <StarsIcon />}} />,
  ],
  'manage_player': [
    <Resource name={`${process.env.REACT_APP_PLAYER_RESOURCE}`} list={PlayersList} edit={PlayersEdit} create={PlayersCreate} options={{ label: 'اللاعبون',  section: 'Analytics', Icon: <DirectionsRunIcon />}}/>,
    <Resource name={`${process.env.REACT_APP_TRANSFER_RESOURCE}`} list={TransferList} edit={TransferEdit} create={TransferCreate} options={{ label: 'انتقالات',  section: 'Analytics', Icon: <TransferWithinAStationIcon />}}/>,
  ],
  'manage_contestant': [
    <Resource name={`${process.env.REACT_APP_TEAM_RESOURCE}`} edit={TeamsEdit} list={TeamsList} options={{ label: 'الفرق',  section: 'Analytics', Icon: <SecurityIcon />}} />,
  ],
  'manage_squad': [
    <Resource name={`${process.env.REACT_APP_SQUAD_RESOURCE}`} list={SquadsList} edit={SquadEdit} options={{ label: 'التشكيلات',  section: 'Analytics', Icon: <GrainIcon />}}/>
  ],
  'manage_room': [
    <Resource name={`${process.env.REACT_APP_ROOM_RESOURCE}`} edit={ChatroomsEditForm} list={ChatroomsListForm} create={ChatroomsCreateForm} options={{ label: 'صالات الدردشة', section: 'Social', Icon: <Chat /> }} />
  ],
  'manage_video': [
    <Resource name={`${process.env.REACT_APP_VIDEO_RESOURCE}`} list={VideoList} edit={VideoEdit} create={VideoCreate} options={{ label: 'فيديوهات', section: 'Content', Icon: <PlayCircleFilled /> }} />

  ],
  'manage_scheduler': [
    <Resource name={`${process.env.REACT_APP_SCHEDULER_PROJECT_RESOURCE}`} list={ProjectList} edit={ProjectEdit} create={ProjectCreate} options={{ label: 'Projects', section: 'Scheduler', Icon: <SettingsIcon /> }} />,
    <Resource name={`${process.env.REACT_APP_SCHEDULER_JOB_RESOURCE}`} list={JobList} create={JobCreate} edit={JobEdit} options={{ label: 'Jobs', section: 'Scheduler', Icon: <SettingsIcon /> }} />,
    <Resource name={`${process.env.REACT_APP_SCHEDULER_SPIDER_RESOURCE}`}  options={{ label: 'Spiders', section: 'Scheduler', Icon: <SettingsIcon /> }} />,
    <Resource name={`${process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE}`} list={JobExecutionList}  options={{ label: 'Job Executions', section: 'Scheduler', Icon: <SettingsIcon /> }} />
  ],
}
const App = () => (

    <Admin 
      locale="ar"
      i18nProvider={i18nProvider}
      customSagas={[ errorSagas ]}
      dataProvider={dataProvider}
      authProvider={AuthProvider.checkAuth}
      theme={theme}
      appLayout={CustomLayout}
      customRoutes={cutsomRoutes}
    >
        {
          (permissions:any) => {
            return permissions.map((per:string) => PERMISSIONS[per])
          }
        }
        
    </Admin>
);

export default App;
