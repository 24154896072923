/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Menu from "@material-ui/core/Menu";

class BlockTypeSelect extends React.Component {
  state = {
    modalChildren: null,
    buttonsPopupOpen: false,
    modalOpen: false,
    style: {
      transform: 'translate(-50%) scale(0)',
    },
  };

  onMouseEnter = () => {
    this.setState({
      style: {
        transform: 'translate(-50%) scale(1)',
        transition: 'transform 0.15s cubic-bezier(.3,1.2,.2,1)',
      },
    });
  };

  onMouseLeave = () => {
    this.setState({
      style: {
        transform: 'translate(-50%) scale(0)',
      },
    });
  };

  onMouseDown = clickEvent => {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  };

  openModal = (modalChildren) => {
    this.setState({
      modalOpen: true,
      modalChildren: modalChildren
    })
  }
  closeModal = () => {
    this.setState({
      modalOpen: false
    })
  }
  render() {
    const { modalOpen, buttonsPopupOpen, modalChildren } = this.state
    const { theme, getEditorState, setEditorState } = this.props;
    return (
      <div
        onClick={() => {
          this.setState({ buttonsPopupOpen: !buttonsPopupOpen }, () => {
            if(this.state.buttonsPopupOpen){
              return this.onMouseEnter()
            }
            this.onMouseLeave()
          })
        }}
        // onMouseEnter={this.onMouseEnter}
        // onMouseLeave={this.onMouseLeave}
        onMouseDown={this.onMouseDown}
      >
        <div className={theme.blockTypeSelectStyles.blockType}>
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </div>
        {/*
          The spacer is needed so the popup doesn't go away when moving from the
          blockType div to the popup.
        */}
        <div className={theme.blockTypeSelectStyles.spacer} />
        <div
          className={theme.blockTypeSelectStyles.popup}
          style={this.state.style}
        >
          {this.props.children({
            getEditorState,
            setEditorState,
            theme: theme.buttonStyles,
            openModal: this.openModal,
            closeModal: this.closeModal,
          })}
          <Menu
            id="simple-menu"
            open={modalOpen}
            onClose={this.closeModal}
          >
            {modalChildren}
          </Menu>
        </div>
      </div>
    );
  }
}

BlockTypeSelect.propTypes = {
  children: PropTypes.func,
};

export default BlockTypeSelect;
