import React from 'react';
import { List, Datagrid, UrlField, SingleFieldList, TextField, ArrayField, ChipField, SelectField, NumberField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Avatar } from "@material-ui/core";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import PreviewButtonComponent from '../../components/PreviewButtonComponent';

const styles = {
  tabField: {
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".74rem", 
  },
  alignRight:{
    textAlign: 'right' as 'right'
  },
  tableHeader:{
    fontFamily: "'Roboto', 'Droid Arabic Kufi', 'Helvetica', sans-serif",
    fontSize:".8rem",
    fontWeight: 700,
  },
  capLength:{
    maxWidth:"50%",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  title:{
    whiteSpace: 'nowrap' as 'nowrap',
    textOverflow: 'ellipsis',
  }
}



const FlatpageList = (props: any) => {
  const classes = props.classes;
  return (
  <List {...props} exporter={false} title="قائمة الصفحات">
    <Datagrid rowClick="edit">
      <TextField 
        source="title" 
        className={classNames(classes.tabField,classes.alignRight,classes.title)} 
        headerClassName={classNames(classes.tableHeader,classes.alignRight)}
      />
      <TextField 
        source="content" 
        className={classNames(classes.tabField,classes.alignRight,classes.capLength)} 
        headerClassName={classNames(classes.tableHeader,classes.alignRight)} 
      />
      <PreviewButtonComponent />
    </Datagrid>
</List>
)};

const FlatpageListComponent = withStyles(styles)(FlatpageList)

export default FlatpageListComponent