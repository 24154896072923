
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import Cookies from 'js-cookie';


const StopJobExecution = (props:any) => {
    const handleClick = (e:any) => {
        const {  record } = props;
        fetch(
            `${process.env.REACT_APP_SCHEDULER_API_ENDPOINT}/${process.env.REACT_APP_SCHEDULER_JOB_EXECUTION_RESOURCE}/${record.id}/stop/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken') || ""
                }
            }
        )
        .then(() => {
            props.showNotification('Job execution stopped');
        })
        .catch((e) => {
            props.showNotification('Error: Job execution not stopped', 'warning')
        });
        
    }
    return <Button onClick={handleClick} variant="contained" color="primary">Stop</Button>;
}


const RunJob  = (props:any) => {
    const handleClick = (e:any) => {
        e.preventDefault()
        const {  record } = props;
        fetch(
            `${process.env.REACT_APP_SCHEDULER_API_ENDPOINT}/jobs/${record.id}/run/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken') || ""
                }
            }
        )
        .then(() => {
            props.showNotification('Job ran');
        })
        .catch((e) => {
            props.showNotification('Error: job not ran', 'warning')
        });
        e.stopPropagation()
        
    }
    return <Button onClick={handleClick} variant="contained" color="primary">Run</Button>;
}

const SwitchJobEnable = (props:any) => {
    const {  record } = props;
    const handleClick = (e:any) => {
        const updatedRecord = { enabled: !record.enabled };
        fetch(
            `${process.env.REACT_APP_SCHEDULER_API_ENDPOINT}/jobs/${record.id}/`,
            {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken') || "",
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedRecord)
            }
        )
        .then(() => {
            window.location.reload();
        })
        .catch((e) => {
            props.showNotification('Error: job not updated', 'warning')
        });
        
        e.stopPropagation()
        
    }
    const getLabel = () => (record.enabled ? 'Disable': 'Enable');
    return <Button onClick={handleClick} variant="contained" color="primary">{getLabel()}</Button>;
}

const RunJobBotton = connect(null, {
    showNotification,
})(RunJob)

const SwitchJobEnableBotton = connect(null, {
    showNotification,
})(SwitchJobEnable)

const StopJobExecutionBotton = connect(null, {
    showNotification,
})(StopJobExecution)

export { RunJobBotton, SwitchJobEnableBotton, StopJobExecutionBotton }